import { Component, DestroyRef, Input } from '@angular/core';

import { ColumnDefinition, ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Observable, of } from 'rxjs';

import { AppnameRendererComponent } from '../appname-renderer/appname-renderer.component';
import { DatetimeRendererComponent } from '../datetime-renderer/datetime-renderer.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslationApiService, TranslationDeployment } from '../../services';

@Component({
  selector: 'gea-id-workspace-latest-deployments',
  templateUrl: './latest-deployments.component.html',
})
export class LatestDeploymentsComponent {
  @Input() loading = false;
  @Input() highlightRow = false;
  columns: ColumnDefinition[] = [
    {
      key: 'projectName',
      displayName: 'ADMINISTRATION_TASKS.COL_APP',
      renderer: { component: AppnameRendererComponent as ColumnRendererComponent<unknown> },
    },
    {
      key: 'lastDeploymentDate',
      displayName: 'ADMINISTRATION_TASKS.COL_DATETIME',
      renderer: { component: DatetimeRendererComponent as ColumnRendererComponent<unknown> },
    },
  ];
  data = [{ app: 'Euro', datetime: '01.01.2001 10:00 Uhr' }];
  deployments$: Observable<TranslationDeployment[]> = of([]);
  highlightedRow?: string;
  constructor(
    private api: TranslationApiService,
    private destroy$: DestroyRef
  ) {
    this.deployments$ = this.api.fetchDeployments();
  }
  refresh(prjectName: string): void {
    this.highlightRow = true;
    this.deployments$ = this.api.fetchDeployments();
    this.deployments$.pipe(takeUntilDestroyed(this.destroy$)).subscribe(() => {
      this.highlightedRow = prjectName;
    });
  }
}
