import {
  appendVisibilitySettings,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  TextFilterComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { MultiLinesRendererComponent } from '../multi-lines-render/multi-lines-renderer.component';
import { CustomTriggerByRendererComponent } from '../custom-trigger-by-render/custom-trigger-by.component';

export const columnDefinitions: ColumnDefinition[] = appendVisibilitySettings([
  createTranslatedColumn('USER.HISTORY_EVENT_TYPE', 'eventType'),
  createTextColumn('USER.HISTORY_VERSION', 'version'),
  createMultiLinesColumn('USER.HISTORY_CHANGES', 'changes'),
  createComplexTriggerByColumn('USER.HISTORY_TRIGGERED_BY', 'triggeredBy'),
  createDateColumn('USER.HISTORY.EVENT_DATE', 'eventDate'),
]);

function createTextColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    filter: {
      component: TextFilterComponent,
    },
  };
}

function createDateColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    filter: {
      component: TextFilterComponent,
    },
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'short',
        locale: 'en-US',
      },
    },
  };
}

function createTranslatedColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    filter: {
      component: TextFilterComponent,
    },
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
    },
  };
}
function createMultiLinesColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    filter: {
      component: TextFilterComponent,
    },
    renderer: {
      component: MultiLinesRendererComponent as ColumnRendererComponent<unknown>,
    },
  };
}

function createComplexTriggerByColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    filter: {
      component: TextFilterComponent,
    },
    renderer: {
      component: CustomTriggerByRendererComponent as ColumnRendererComponent<unknown>,
    },
  };
}
