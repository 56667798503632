import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  SelectOption,
  SortDirection,
} from '@gea/digital-ui-lib';
import { FormBuilder, Validators } from '@angular/forms';
import { first, Observable, of, tap } from 'rxjs';
import { InviteMembership, OrgaData, OrganizationService, UserInviteService } from '@gea-id/shared';
import { CultureService, OWNER_ROLE_ID } from '@gea-id/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gea-id-user-invite-membership-dialog',
  templateUrl: './user-invite-membership-dialog.component.html',
  styleUrl: './user-invite-membership-dialog.component.scss',
})
export class UserInviteMembershipDialogComponent implements ComplexDialogEmbeddedView, OnInit {
  isAcceptDisabled = true;
  isOwnerRoleNull = false;
  organisations: OrgaData[] = [];
  organisationOptions: SelectOption<string>[] = [];
  loading = false;
  protected roleOptions: SelectOption<string>[] = [];

  get ownerRoleReassigned(): boolean {
    return this.form.controls.role.value === OWNER_ROLE_ID && !this.isOwnerRoleNull;
  }

  form = this.formBuilder.group({
    organisation: ['', [Validators.required]],
    role: ['', [Validators.required]],
  });

  constructor(
    private complexDialogService: ComplexDialogV2Service,
    private formBuilder: FormBuilder,
    private userInviteService: UserInviteService,
    private dialogV2Service: DialogV2Service,
    private cultureService: CultureService,
    private orgaService: OrganizationService,
    private destroyRef: DestroyRef,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) public membershipData?: InviteMembership
  ) {}

  ngOnInit(): void {
    this.isAcceptDisabled = !this.membershipData;
    this.fillFormFields();

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isAcceptDisabled = this.form.invalid;
      this.setRoleOptions();
    });
  }

  onAcceptClick() {
    this.complexDialogService.emitDataOutputForComponent({
      inviteMemberShipId: this.membershipData?.inviteMemberShipId,
      organisation: this.organisations.find((orga) => orga.orgaId === this.form.getRawValue().organisation),
      role: this.cultureService.roles.find((role) => role.id === this.form.getRawValue().role),
    });
    this.complexDialogService.close();
  }

  onDeleteMemberShip() {
    if (!this.membershipData) return;
    this.userInviteService.emitDeleteMembership(this.membershipData.inviteMemberShipId);
    this.complexDialogService.close();
    this.dialogV2Service.close();
  }

  private setRoleOptions(): void {
    const orga = this.organisations.find((o) => o.orgaId === this.form.value.organisation);

    this.cultureService.roles$
      .pipe(
        map((roles) =>
          roles
            .filter((role) => role.enabledOrgaTypes.includes(orga?.type ?? ''))
            .filter(
              (role) =>
                !this.userInviteService.membershipsData
                  .filter((membership) => orga?.orgaId === membership.organizationId)
                  .map((membership) => membership.roleId)
                  .includes(role.id) || role.id === this.membershipData?.roleId
            )
            .map((role) => {
              return {
                nameKey: 'X.ROLE.' + (role.name ?? '').toUpperCase(),
                value: role.id,
              } as SelectOption<string>;
            })
        )
      )
      .subscribe((roles) => {
        this.roleOptions = roles;
      });
  }

  private fillFormFields() {
    if (this.membershipData) {
      this.fetchOrgas(this.membershipData.organizationName ?? '', this.organisationOptions)
        .pipe(first())
        .subscribe(() => {
          this.form.patchValue({
            organisation: this.membershipData?.organizationId,
            role: this.membershipData?.roleId,
          });
        });
    }
  }

  public emptyMessageKey?: string;
  public fetchOrgas = (query: string, options: SelectOption<string>[]): Observable<SelectOption<string>[]> => {
    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return this.orgaService
      .getOrganizationsV2({
        page: 0,
        pageSize: 20,
        columns: {
          name: {
            filter: [query],
            sort: SortDirection.ASCENDING,
          },
        },
      })
      .pipe(
        map((response) => response.pageEntries),
        tap((orgas) => {
          this.organisations = orgas;
          this.emptyMessageKey = undefined;
        }),
        map((orgas) =>
          orgas.map(
            (orga) =>
              ({
                name: orga.name,
                value: orga.orgaId,
              }) as SelectOption<string>
          )
        ),
        tap((options) => (this.organisationOptions = options))
      );
  };

  onSelectOrga($event: string) {
    this.form.controls.role.enable();
    this.isOwnerRoleNull = false;
    const selectedOrga = this.organisations.find((o) => o.orgaId === $event);
    const ownerRoleNull = selectedOrga !== undefined && selectedOrga?.owner === null;
    if (ownerRoleNull) {
      this.isOwnerRoleNull = true;
      this.form.controls.role.patchValue(OWNER_ROLE_ID as string);
      this.form.controls.role.disable();
    }
  }
}
