<geaui-page-content-layout [useDefaultPadding]="true">
  <ng-container page-content>
    <div class="title gea-font-heading-20">
      {{ 'APPLICATION.DETAIL.PATHS.INFO' | translate }}
    </div>
    <form class="form" [formGroup]="form">
      <section class="geaui-grid">
        <div class="geaui-sub-grid geaui-col-desktop-md-9 geaui-col-desktop-lg-9">
          <div class="geaui-row-desktop-md-1 geaui-col-desktop-md-10 geaui-col-desktop-lg-10 success flex">
            <div>
              <div class="gea-font-heading-16">App ID</div>
              <div class="gea-font-body-16">
                {{ currentApplication.id }}
              </div>
            </div>
            <div>
              <div class="gea-font-heading-16">App Client ID</div>
              <div class="gea-font-body-16">
                {{ currentApplication.clientId }}
              </div>
            </div>
            <div>
              <div class="gea-font-heading-16">Type</div>
              <div class="gea-font-body-16">
                {{ currentApplication.appType }}
              </div>
            </div>
          </div>

          <geaui-input-v2
            class="geaui-row-desktop-md-1 geaui-col-desktop-md-5 geaui-col-desktop-lg-5"
            [required]="true"
            [group]="form"
            formName="appKey"
            label="APPLICATION.APPKEY.COLUMN.NAME">
          </geaui-input-v2>

          <geaui-input-v2
            class="geaui-row-desktop-md-1 geaui-col-desktop-md-5 geaui-col-desktop-lg-5"
            [required]="true"
            [group]="form"
            formName="name"
            label="APPLICATION.MODAL.FORM.NAME">
          </geaui-input-v2>

          <geaui-input-v2
            class="app-input geaui-row-desktop-md-2 geaui-col-desktop-md-10 geaui-col-desktop-lg-10"
            [type]="'url'"
            [group]="form"
            formName="imagePath"
            label="X.LABEL.ICON">
          </geaui-input-v2>

          <geaui-radio-group-v2
            class="geaui-row-desktop-md-3 geaui-col-desktop-md-5 geaui-col-desktop-lg-5"
            [flexDirection]="'row'"
            [group]="form"
            [options]="verificationOptions"
            label="APPLICATION.VERIFICATION_REQUIRED.COLUMN.NAME"
            formName="verificationNeeded"></geaui-radio-group-v2>

          <geaui-radio-group-v2
            class="geaui-row-desktop-md-3 geaui-col-desktop-md-5 geaui-col-desktop-lg-5"
            [flexDirection]="'row'"
            [group]="form"
            [options]="verificationOptions"
            [selected]="{ value: form.get('verificationNeeded')?.value }"
            label="APPLICATION.MODAL.FORM.HAS_TRANSLATION.LABEL"
            formName="hasTranslation"></geaui-radio-group-v2>
        </div>
      </section>
      <div>
        <div class="divider"></div>
        <geaui-link-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-7 delete-button"
          (click)="deleteApp()"
          preIcon="trash-can"
          type="cancel-red">
          {{ 'X.LABEL.DELETE' | translate }}
        </geaui-link-v2>
      </div>
    </form>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 [disabled]="form.invalid || !form.dirty" [loading]="loading" (click)="save()">
          {{ 'X.BUTTON.SAVE' | translate }}
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
