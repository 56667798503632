/**
 * Digital Platform - GEA ID
 * The GEA ID API manages users, memberships, organizations, roles, and more within the Digital Platform.  It provides endpoints for user authentication, role assignments, organization management, and system health checks. 
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';


export interface SiteResponse { 
    id: string;
    name: string;
    address: Address;
    phoneNumber: string;
}

