import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipModule, ColumnRendererComponent } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'gea-id-chip-list-renderer',
  standalone: true,
  imports: [CommonModule, ChipModule, TranslateModule, TooltipModule],
  templateUrl: './chip-list-renderer.component.html',
  styleUrl: './chip-list-renderer.component.scss',
})
export class ChipListRendererComponent<T extends { [key: string]: unknown }> implements ColumnRendererComponent<T>, OnInit {
  rowData?: T;
  columnKey?: string;
  chipList = signal<string[]>([]);
  chipsTranslated = computed(() => this.translateChips());
  chipsAsTooltip = computed(() => this.chipsTranslated().join('\n'));
  translateService = inject(TranslateService);

  ngOnInit(): void {
    if (this.rowData && this.columnKey) {
      this.chipList.set(this.rowData[this.columnKey] as string[]);
    }
  }

  private translateChips(): string[] {
    return this.chipList().map((chip) => this.translateService.instant(chip) as string);
  }
}
