import { Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ErrorHandlerV2Service,
  ResourcesSelectOptionsModel,
  ResourceKeys,
  ResourcesService,
  SortDirection,
  UserState,
  Membership,
} from '@gea/digital-ui-lib';
import { BehaviorSubject, catchError, concatMap, filter, first, Observable, tap, throwError } from 'rxjs';
import { FormService } from './form.service';
import { ProfileService } from './profile.service';
import { OrganizationService } from './organization.service';
import { DashboardService } from './dashboard.service';
import { DashboardInfo, OrgaData, RoleWithOrgaType } from '../model';
import { MembershipService } from './membership.service';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CultureService {
  private _organizations$ = new BehaviorSubject<OrgaData[]>([]);
  private _roles$ = new BehaviorSubject<RoleWithOrgaType[]>([]);
  private _memberships$ = new BehaviorSubject<Membership[] | null>(null);
  private _dashBoardInfos$ = new BehaviorSubject<DashboardInfo[] | null>(null);
  private _isOrganizationLoaded$ = new BehaviorSubject(false);
  private _resourceData$ = new BehaviorSubject<ResourcesSelectOptionsModel | null>(null);

  constructor(
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private membershipService: MembershipService,
    private errorHandlerService: ErrorHandlerV2Service,
    private resourcesService: ResourcesService,
    private formService: FormService,
    private dashboardService: DashboardService,
    private store: Store
  ) {}

  fetchData(): void {
    this.loadRoles();
    this.loadResourcesData();
    this.loadDashboardInfos();
    this.loadMemberships();
  }

  get organizations$(): Observable<OrgaData[]> {
    return this._organizations$.asObservable();
  }

  get organizations(): OrgaData[] {
    return this._organizations$.getValue();
  }

  get roles$(): Observable<RoleWithOrgaType[]> {
    return this._roles$.asObservable();
  }

  get roles(): RoleWithOrgaType[] {
    return this._roles$.getValue();
  }

  get isOrganizationLoaded$(): Observable<boolean> {
    return this._isOrganizationLoaded$.asObservable();
  }

  get resourceData$(): Observable<ResourcesSelectOptionsModel | null> {
    return this._resourceData$.asObservable();
  }

  get dashboardInfos$() {
    return this._dashBoardInfos$.asObservable();
  }

  get memberships(): Membership[] | null {
    return this._memberships$.getValue();
  }

  loadOrganisations(): void {
    this._isOrganizationLoaded$.next(false);
    this.organizationService
      .getOrganizationsV2({
        page: 0,
        pageSize: 10000,
        columns: {
          name: {
            sort: SortDirection.ASCENDING,
          },
        },
      })
      .pipe(
        tap((organizations) => this._organizations$.next(organizations.pageEntries)),
        tap(() => this._isOrganizationLoaded$.next(true))
      )
      .subscribe();
  }

  loadMemberships(): void {
    this.store
      .select(UserState.user)
      .pipe(
        map((user) => user.userId),
        filter((userId) => !!userId),
        first(),
        concatMap((userId) => this.membershipService.getMemberships(userId ?? ''))
      )
      .subscribe({
        next: (memberships) => this._memberships$.next(memberships),
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
      });
  }

  loadRoles(): void {
    this.profileService.getRoles().subscribe({
      next: (roleWithOrgaTypeList) => this._roles$.next(roleWithOrgaTypeList),
      error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
    });
  }

  loadResourcesData(): void {
    this.resourcesService
      .fetchMultipleResourcesAndGetOptionsModel(
        ResourceKeys.LOCALES,
        ResourceKeys.TITLES,
        ResourceKeys.TIMEZONES,
        ResourceKeys.COUNTRIES
      )
      .pipe(
        tap((resourceData: ResourcesSelectOptionsModel) => this.formService.cacheResourceData(resourceData)),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          return throwError(() => error);
        }),
        first()
      )
      .subscribe((resourceData: ResourcesSelectOptionsModel) => {
        this._resourceData$.next(resourceData);
      });
  }

  loadDashboardInfos(): void {
    this.dashboardService
      .getDefaultDashboardInfos()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          return throwError(() => error);
        }),
        first()
      )
      .subscribe((dashboardInfos) => {
        this._dashBoardInfos$.next(dashboardInfos);
      });
  }
}
