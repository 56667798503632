import {
  ApiService,
  Customer,
  CustomerDto,
  Keymappings,
  FilterTableSettings,
  ListResponse,
  getParams,
} from '@gea/digital-ui-lib';

import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerAdminApiService {
  url = 'customer-service/v1';

  constructor(private api: ApiService) {
    this.setUrl(this.url);
  }

  getCustomer(customerNumber: string): Observable<Customer | undefined> {
    const params = {
      page: '0',
      pageSize: '10',
      'customerId[equals]': customerNumber,
    };
    return this.api.get<ListResponse<CustomerDto[]>>(`${this.url}/customers`, { params }).pipe(
      map((list) => {
        if (list.entryCount === 1) {
          return this.mapToCustomer(list.pageEntries[0]);
        }
        return undefined;
      })
    );
  }

  getAllCustomers(tableFilter: FilterTableSettings): Observable<ListResponse<CustomerDto[]>> {
    const params = getParams(tableFilter);
    return this.api.get<ListResponse<CustomerDto[]>>(`${this.url}/customers`, { params });
  }

  getKeymappings(customerId: string): Observable<Keymappings[]> {
    return this.api.get<Keymappings[]>(`${this.url}/keymappings?id.customerId[contains]=${customerId}`);
  }

  setUrl(url?: string) {
    this.url = url ?? this.url;
    return this;
  }

  private mapToCustomer = (dto: CustomerDto): Customer | undefined => {
    if (!dto) {
      return undefined;
    }
    return {
      id: dto.customerId,
      name: dto.customerName1 ?? '',
      address: {
        city: dto.customerCity ?? '',
        country: dto.customerCountry ?? '',
        street: `${dto.customerStreet ?? ''} ${dto.customerStreetNumber ?? ''}`,
        zipCode: dto.customerZip ?? '',
      },
      linkedOrganization: dto.linkedOrganization?.name ?? undefined,
    } as Customer;
  };
}
