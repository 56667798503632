import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ComplexDialogV2Service,
  DialogV2Service,
  ErrorHandlerV2Service,
  PermissionsState,
  SnackbarService,
  TableServiceV2,
  Membership,
  MembershipState,
  PermissionKey,
} from '@gea/digital-ui-lib';
import { filter, Observable, of, startWith, tap } from 'rxjs';
import { MEMBERSHIP_EDIT_ACTION, membershipsColumnDefinitions } from '../../models/memberships-column-definitions.config';
import { finalize, map } from 'rxjs/operators';
import { MembershipDialogComponent } from './membership-dialog/membership-dialog.component';
import { UserDetailService, MembershipService, OWNER_ROLE_ID, OrgaData } from '@gea-id/shared';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'gea-id-memberships-list-page',
  styleUrl: './memberships-list-page.component.scss',
  templateUrl: './memberships-list-page.component.html',
})
export class MembershipsListPageComponent implements OnInit {
  public userId = '';
  public membershipTableData: TreeNode<Membership>[] = [];
  public columnDefinitions: ColumnDefinition[] = [];
  public totalRecords = 0;
  public loading = true;
  public loadingSubLevel = false;

  constructor(
    private membershipService: MembershipService,
    private tableService: TableServiceV2,
    private complexDialogService: ComplexDialogV2Service,
    private dialogService: DialogV2Service,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    private store: Store,
    protected userDetailService: UserDetailService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.userId = this.userDetailService.currentUserId;
    this.loading = !this.userDetailService.isMembershipsLoaded();

    this.userDetailService.memberships$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((membershipListResponse) => (this.loading = membershipListResponse === null)),
        filter((membershipListResponse) => !!membershipListResponse),
        tap((membershipListResponse) => (this.totalRecords = membershipListResponse?.entryCount ?? 0)),
        map((membershipListResponse) => membershipListResponse?.pageEntries ?? []),
        map((memberships) => memberships.map((membership) => this.mapInheritedMembershipState(membership)))
      )
      .subscribe((memberships) => {
        if (!memberships) return;
        this.membershipTableData = memberships.map((membership) => ({
          data: membership,
          leaf: !membership.createsInherited,
        }));
      });

    this.tableService.actions
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(
          (action) => action.tableId === this.userDetailService.tableIdMemberships && action.action === MEMBERSHIP_EDIT_ACTION
        ),
        map(({ rowData }) => rowData as Membership)
      )
      .subscribe((rowData) => this.openEditMemberShipDialog(rowData));

    this.hasNoUpdateOrganisationPermission()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.removeEditRow());

    this.initUpdatePermission();
  }

  private initUpdatePermission() {
    this.columnDefinitions = membershipsColumnDefinitions(!this.hasNoUpdatePermission);
  }

  get hasNoUpdatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return !permissions.includes(PermissionKey.UPDATE_USER);
      })
    );
  }

  onNodeExpand(node: TreeNode<Membership>) {
    this.loadingSubLevel = true;
    if (!node.data?.id) return;
    this.membershipService.getInheritedMemberships(this.userId, node.data.id).subscribe((memberships) => {
      node.children = memberships
        .map((membership) => this.mapInheritedMembershipState(membership))
        .map((membership) => ({
          data: {
            ...membership,
            roleName: membership.roleName?.toUpperCase() ?? '',
            createdAt: undefined,
            updatedAt: undefined,
          },
        }));
      this.membershipTableData = [...this.membershipTableData];
      this.loadingSubLevel = false;
    });
  }

  openAddMemberShipDialog() {
    this.complexDialogService.open(
      {
        title: 'X.LABEL.ADD',
        yes: 'X.BUTTON.SAVE',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: (data: unknown) => {
          const d = data as {
            organisation: string;
            role: string;
            orga?: OrgaData;
          };
          if (d.role === OWNER_ROLE_ID && d.orga?.owner) {
            return of(void 0).pipe(
              // finalize is needed because the ComplexDialog closes when the Observable subscription is completed.
              // The new Dialog has to be opened after the ComplexDialog is closed.
              finalize(() => {
                this.dialogService.open({
                  title: 'MEMBERSHIPS.LIST.DIALOG.OWNER_ROLE.EXISTS_WARNING.HEADER',
                  message: this.translateService.instant('MEMBERSHIPS.LIST.DIALOG.OWNER_ROLE.EXISTS_WARNING.CONTENT', {
                    name: d.orga?.name,
                    owner: d.orga?.owner ?? '',
                  }) as string,
                  yes: 'X.BUTTON.CONFIRM',
                  no: 'X.BUTTON.CANCEL',
                  buttonTypeYes: 'cancel-red',
                  confirmCallback: () => this.addMembership(d) as unknown as Observable<void>,
                });
              })
            );
          } else {
            return this.addMembership(d) as unknown as Observable<void>;
          }
        },
      },
      MembershipDialogComponent
    );
  }

  openEditMemberShipDialog = (membershipRowData: Membership) => {
    if (membershipRowData.inherited) return;

    this.complexDialogService.open(
      {
        title: 'X.LABEL.EDIT',
        yes: 'X.BUTTON.SAVE',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        data: membershipRowData,
      },
      MembershipDialogComponent
    );
  };

  private hasNoUpdateOrganisationPermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      map((permissions) => !permissions.includes(PermissionKey.UPDATE_ORGANIZATION)),
      filter((hasNoUpdateOrganisationPermission) => hasNoUpdateOrganisationPermission)
    );
  }

  private addMembership(data: { organisation: string; role: string }) {
    return this.membershipService
      .createMembership(this.userId ?? '', {
        roleId: data.role,
        organizationId: data.organisation,
      })
      .pipe(
        tap({
          next: (createdMembership) => {
            const membership: Membership = {
              ...createdMembership,
              state: createdMembership.state as unknown as MembershipState,
              roleName: createdMembership.roleName?.toUpperCase() ?? '',
            };
            this.membershipTableData = [...this.membershipTableData, { data: membership }];
            this.handleSuccessfulMembershipCreation();
          },
          error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
        })
      );
  }

  private handleSuccessfulMembershipCreation() {
    this.userDetailService.memberships = this.membershipTableData.map((membership) => membership.data as Membership);
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private mapInheritedMembershipState(membership: Membership): Membership {
    if (membership.inherited) {
      return {
        ...membership,
        state: MembershipState.INHERITED,
      };
    }
    return membership;
  }

  private removeEditRow() {
    this.columnDefinitions = this.columnDefinitions.filter((column) => {
      return column.key !== 'edit';
    });
  }
}
