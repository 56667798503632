import { Inject, Injectable, Optional } from '@angular/core';

import { ApiService, ApiServiceInterface, AppEnvironmentState, Environment } from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class AdminScopeService implements ApiServiceInterface {
  url = '/user/sync-adgroups'; // e.g. https://api.apps.dev.gea.com/portal-admin/user/sync-adgroups
  apiVersion?: string;

  constructor(
    @Optional() @Inject('portalPath') private portalPath: string,
    private api: ApiService,
    private store: Store
  ) {
    this.setUrl(this.url);
    this.apiVersion = '4';
  }

  syncAdGroups() {
    return this.api.get(this.url, undefined, this.apiVersion);
  }

  setUrl(url?: string) {
    if (url) {
      // This is the official syntax of ngxs
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.store.select(AppEnvironmentState.environment).subscribe((env) => {
        const context = env === Environment.LOCAL ? 'api/' : '';
        this.url = context + (this.portalPath || 'portal') + url;
      });
    }
    return this;
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }
}
