export interface Role {
  roleId: string;
  roleName: string;
}

export const OWNER_ROLE_ID = 'faba9ff4-d761-4919-a2f2-a3033390c917';

export interface RoleWithOrgaType {
  name: string;
  id: string;
  enabledOrgaTypes: string[];
}
