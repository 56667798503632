import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  PermissionKey,
  PermissionsState,
  ResourcesService,
  SelectFilterOption,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { first, Observable, startWith, Subscription, tap } from 'rxjs';
import { Router } from '@angular/router';
import { NEW_ORGA_ID, orgaRoutes } from '../models/organizations.routing';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import {
  OrgaData,
  OrganizationAdminListItemResponse,
  OrganizationService,
  OrganizationsList200ResponseOneOf1
} from '@gea-id/shared';
import { OrganizationsColumnDefinitionsService } from '../services/organizations-column-definitions.service';

@Component({
  selector: 'gea-id-organizations-list-page',
  styleUrl: './organizations-list-page.component.scss',
  templateUrl: './organizations-list-page.component.html',
})
export class OrganizationsListPageComponent implements OnInit, OnDestroy {
  public TABLE_ID = 'admin-organization-table';
  public data: OrgaData[] = [];
  public columnDefinitions: ColumnDefinition[] = [];
  public totalRecords = 0;

  public loading = true;
  public subs: Subscription[] = [];

  constructor(
    private organizationService: OrganizationService,
    private store: Store,
    private tableService: TableServiceV2,
    private errorHandler: ErrorHandlerV2Service,
    private router: Router,
    private resourcesService: ResourcesService,
    private organizationsColumnDefinitionsService: OrganizationsColumnDefinitionsService
  ) {}

  ngOnInit(): void {
    this.subs.push(this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter) => this.fetchData(filter)));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  get hasNoCreatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return !permissions.includes(PermissionKey.CREATE_ORGANIZATION);
      })
    );
  }

  fetchData(filter: FilterTableSettings) {
    this.loading = true;
    this.organizationService
      .getOrganizationsV2({ ...filter })
      .pipe(
        first(),
        tap((data) => this.initializeColumnDefinitions(data.pageEntries as OrganizationAdminListItemResponse[])),
        map((data) => this.transformEnabledAppNames(data as OrganizationsList200ResponseOneOf1))
      )
      .subscribe({
        next: (data: OrganizationsList200ResponseOneOf1) => {
          this.data = data.pageEntries;
          this.totalRecords = data.entryCount;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
  }

  public edit(rowData: OrgaData) {
    void this.router.navigate(['organization', rowData.orgaId, orgaRoutes.BASIC_INFO]);
  }

  public add() {
    void this.router.navigate(['organization', NEW_ORGA_ID, orgaRoutes.BASIC_INFO]);
  }

  private initializeColumnDefinitions(data: OrganizationAdminListItemResponse[]) {
    this.resourcesService
      .getCountries()
      .pipe(first())
      .subscribe((countries) => {
        const allEnabledApps = this.getAllEnabledApps(data);
        this.columnDefinitions = this.organizationsColumnDefinitionsService.createColumnDefinitionsOrganizationList(
          countries,
          this.mapToSelectFilterOption(allEnabledApps)
        );
      });
  }

  private getAllEnabledApps(data: OrganizationAdminListItemResponse[]): string[] {
    const uniqueApps = new Set<string>();
    data.forEach((item) => {
      item.enabledApps?.forEach((app) => {
        uniqueApps.add(app);
      });
    });
    return Array.from(uniqueApps);
  }

  private transformEnabledAppNames(data: OrganizationsList200ResponseOneOf1): OrganizationsList200ResponseOneOf1 {
    return {
      ...data,
      pageEntries: data.pageEntries.map((organization) => {
        return {
          ...organization,
          enabledApps: this.formatWithTranslateKey(organization.enabledApps),
        };
      }),
    } as OrganizationsList200ResponseOneOf1;
  }

  private mapToSelectFilterOption(allEnabledApps: string[]): SelectFilterOption[] {
    return allEnabledApps.map((app) => ({ value: app, name: app, nameKey: 'APPS.' + app.toUpperCase() + '.NAME' }));
  }

  private formatWithTranslateKey(enabledApps?: string[]) {
    return enabledApps?.map((app) => {
      return 'APPS.' + app.toUpperCase() + '.NAME';
    });
  }
}
