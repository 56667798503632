export * from './actuator-api.service';
import { ActuatorApiService } from './actuator-api.service';
export * from './administrators-api.service';
import { AdministratorsApiService } from './administrators-api.service';
export * from './backoffice-api.service';
import { BackofficeApiService } from './backoffice-api.service';
export * from './ft-api.service';
import { FtApiService } from './ft-api.service';
export * from './memberships-api.service';
import { MembershipsApiService } from './memberships-api.service';
export * from './organizations-api.service';
import { OrganizationsApiService } from './organizations-api.service';
export * from './permissions-api.service';
import { PermissionsApiService } from './permissions-api.service';
export * from './relations-api.service';
import { RelationsApiService } from './relations-api.service';
export * from './roles-api.service';
import { RolesApiService } from './roles-api.service';
export * from './sites-api.service';
import { SitesApiService } from './sites-api.service';
export * from './users-api.service';
import { UsersApiService } from './users-api.service';
export const APIS = [ActuatorApiService, AdministratorsApiService, BackofficeApiService, FtApiService, MembershipsApiService, OrganizationsApiService, PermissionsApiService, RelationsApiService, RolesApiService, SitesApiService, UsersApiService];
