import { Component } from '@angular/core';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { getNestedValue } from '../utils/utlis';

@Component({
  selector: 'gea-id-multi-line-renderer',
  templateUrl: './multi-lines-renderer.component.html',
  styleUrls: ['./multi-lines.component.scss'],
})
export class MultiLinesRendererComponent<T extends { [key: string]: unknown }> implements ColumnRendererComponent<T> {
  rowData?: T;
  columnKey = '';

  protected readonly getNestedValue = getNestedValue<string>;
}
