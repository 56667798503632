export enum Division {
  HUB = 'HUB',
  FT = 'FT',
  LPT = 'LPT',
  SFT = 'SFT',
  HRT = 'HRT',
  FHT = 'FHT',
}

export enum AppType {
  HOSTED_APP = 'HOSTED_APP',
  ON_PREMISE_APP = 'ON_PREMISE_APP',
  EXTERNAL_APP = 'EXTERNAL_APP',
  MOBILE_APP = 'MOBILE_APP',
  SERVICE = 'SERVICE',
}
