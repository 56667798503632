<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-content>
    <form class="form" [formGroup]="form">
      <section class="geaui-grid geaui-sidebar-grid">
        <div class="geaui-sub-grid geaui-col-desktop-md-5 geaui-col-desktop-xl-12">
          <div
            class="gea-font-heading-20 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-1">
            {{ 'ORGANIZATION.DETAIL.INFO.BASIC.TITLE.LABEL' | translate }}
          </div>
          <geaui-input-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-2"
            [id]="'orgaName'"
            [required]="true"
            [loading]="loading"
            [group]="form"
            [floatLabelWhileDisabled]="true"
            [disabled]="hasNoUpdatePermission"
            label="X.LABEL.NAME"
            formName="orgaName">
          </geaui-input-v2>
          <geaui-autocomplete-select-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-3"
            [id]="'type'"
            [formControl]="form.controls.type"
            [label]="'X.LABEL.TYPE' | translate"
            [disabled]="hasNoUpdatePermission"
            [loading]="loading"
            [options]="orgaTypes"
            [submitted]="form.controls.type.touched && this.form.controls.type.hasError('required')"
            formControlName="type">
          </geaui-autocomplete-select-v2>

          <gea-id-customer-link
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-4"
            [customerNumber]="this.form.value.customerNumber ?? ''"
            [loading]="loading"
            [disabled]="hasNoUpdatePermission"
            (customerNumberChanged)="linkCustomer($event)">
          </gea-id-customer-link>
          @if (!isNewOrga) {
            <gea-id-app-enabled
              class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-5"
              [organizationId]="orgaId"
              [organizationName]="this.form.controls.orgaName.value ?? ''"
              [loading]="loading">
            </gea-id-app-enabled>
          }
          <geaui-input-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-6"
            [id]="'customerPortalIds'"
            [loading]="loading"
            [group]="form.controls.sharepointSites"
            [floatLabelWhileDisabled]="true"
            [disabled]="hasNoUpdatePermission || isNewOrga"
            label="X.LABEL.SHAREPOINT_SITES.CUSTOMER_PORTAL_IDS"
            formName="customerPortalIds">
          </geaui-input-v2>
          <geaui-input-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-7"
            [id]="'projectSiteIds'"
            [loading]="loading"
            [group]="form.controls.sharepointSites"
            [floatLabelWhileDisabled]="true"
            [disabled]="hasNoUpdatePermission || isNewOrga"
            label="X.LABEL.SHAREPOINT_SITES.PROJECT_SITE_IDS"
            formName="projectSiteIds">
          </geaui-input-v2>

          @if (!isNewOrga && (hasDeletePermission | async)) {
            <geaui-link-v2
              class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-11 geaui-row-desktop-md-start-8"
              [loading]="loading"
              (click)="deleteOrga()"
              preIcon="trash-can"
              type="cancel-red">
              {{ 'X.LABEL.DELETE' | translate }}
            </geaui-link-v2>
          }
        </div>

        <!--  Address-->
        <div class="geaui-sub-grid geaui-col-desktop-md-6 geaui-col-desktop-xl-12 height-200">
          <div class="gea-font-heading-20 geaui-col-desktop-md-start-1 geaui-col-desktop-md-10 geaui-row-desktop-md-start-1">
            {{ 'ORGANIZATION.DETAIL.INFO.ADDRESS.SHIPPING.TITLE.LABEL' | translate }}
          </div>
          <geaui-input-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-2"
            [id]="'street'"
            [required]="true"
            [loading]="loading"
            [group]="form.controls.shippingAddress"
            [floatLabelWhileDisabled]="true"
            [disabled]="hasNoUpdatePermission"
            label="X.LABEL.STREET"
            formName="street">
          </geaui-input-v2>
          <geaui-input-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-2"
            [id]="'city'"
            [required]="true"
            [loading]="loading"
            [group]="form.controls.shippingAddress"
            [floatLabelWhileDisabled]="true"
            [disabled]="hasNoUpdatePermission"
            label="X.LABEL.CITY"
            formName="city">
          </geaui-input-v2>
          <geaui-input-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-3"
            [id]="'zipCode'"
            [required]="true"
            [loading]="loading"
            [group]="form.controls.shippingAddress"
            [floatLabelWhileDisabled]="true"
            [disabled]="hasNoUpdatePermission"
            label="X.LABEL.ZIP"
            formName="zipCode">
          </geaui-input-v2>
          <geaui-autocomplete-select-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-3"
            [id]="'address.country'"
            [formControl]="form.controls.shippingAddress.controls.country"
            [required]="true"
            [loading]="loading"
            [options]="countries"
            [disabled]="hasNoUpdatePermission"
            label="X.LABEL.COUNTRY">
          </geaui-autocomplete-select-v2>
          <div class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-4">
            <geaui-checkbox-v2
              [id]="'billingAddressSame'"
              [loading]="loading"
              [form]="form"
              [disabled]="hasNoUpdatePermission"
              formName="billingAddressSame"
              label="ORGANIZATION.DETAIL.INFO.ADDRESS.BILLING.SAME.LABEL">
            </geaui-checkbox-v2>
          </div>

          <!--  Invoice -->
          <div class="gea-font-heading-20 invoice-title geaui-col-desktop-md-6 geaui-row-desktop-md-start-5">
            {{ 'ORGANIZATION.DETAIL.INFO.ADDRESS.BILLING.TITLE.LABEL' | translate }}
          </div>
          <geaui-input-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-6"
            [id]="'invoice.street'"
            [floatLabelWhileDisabled]="isInvoiceDisabled || hasNoUpdatePermission"
            [loading]="loading"
            [group]="form.controls.billingAddress"
            [disabled]="isInvoiceDisabled || hasNoUpdatePermission"
            label="X.LABEL.STREET"
            formName="street"></geaui-input-v2>
          <geaui-input-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-6"
            [id]="'invoice.city'"
            [floatLabelWhileDisabled]="isInvoiceDisabled || hasNoUpdatePermission"
            [loading]="loading"
            [group]="form.controls.billingAddress"
            [disabled]="isInvoiceDisabled || hasNoUpdatePermission"
            label="X.LABEL.CITY"
            formName="city"></geaui-input-v2>
          <geaui-input-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-7"
            [id]="'invoice.zipCode'"
            [loading]="loading"
            [floatLabelWhileDisabled]="isInvoiceDisabled || hasNoUpdatePermission"
            [group]="form.controls.billingAddress"
            [disabled]="isInvoiceDisabled || hasNoUpdatePermission"
            label="X.LABEL.ZIP"
            formName="zipCode"></geaui-input-v2>
          <geaui-autocomplete-select-v2
            class="geaui-col-desktop-md-6 geaui-row-desktop-md-start-7"
            [id]="'invoice.country'"
            [loading]="loading"
            [options]="countries"
            [disabled]="isInvoiceDisabled || hasNoUpdatePermission"
            [formControl]="form.controls.billingAddress.controls.country"
            label="X.LABEL.COUNTRY"></geaui-autocomplete-select-v2>
        </div>
      </section>
    </form>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 [sending]="loading" [disabled]="form.invalid || loading || hasNoUpdatePermission" (click)="save()">
          {{ 'X.BUTTON.SAVE' | translate }}
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
