import { Component, computed, DestroyRef, Input, OnInit, signal, ViewChild } from '@angular/core';
import { ApiErrorResponse, ErrorHandlerV2Service, MultiSelectV2Component, SelectOption } from '@gea/digital-ui-lib';
import { AssignmentStatus, IFeatureFlag } from '../../../../feature-flags/models/feature-flag.model';
import { FeatureFlagService } from '../../../../feature-flags/services/feature-flag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concatMap, map, tap } from 'rxjs/operators';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { FeatureFlagApiService } from '../../../../feature-flags/services/feature-flag-api-service';
import { NewFeatureFlagAssignment } from '../../../../feature-flags/models/feature-flag-assignment.model';
import { of } from 'rxjs';
import { AssignmentTypeModel } from '../../../../feature-flags/models/assignment-type.model';

@Component({
  selector: 'gea-id-app-enabled',
  templateUrl: './app-enabled.component.html',
  styleUrl: './app-enabled.component.scss',
})
export class AppEnabledComponent implements OnInit {
  @Input() organizationId = '';
  @Input() organizationName = '';
  @Input() loading = false;
  @ViewChild('multiSelect') multiSelect!: MultiSelectV2Component<string>;
  options = signal<SelectOption<IFeatureFlag>[]>([]);
  selectedOptions = computed(() => this.filterUnassignedOptions());
  updateLoading = computed(() => this.handleFeatureFlagUpdateLoading());
  protected multiSelectConfig = {
    buttonsConfig: {
      selectButtonLabel: 'X.BUTTON.CLOSE',
      hideClearButton: true,
    },
    itemSelectedDescription: 'APP_ENABLED.ITEM.LABEL',
  };

  constructor(
    private featureFlagService: FeatureFlagService,
    private featureFlagApiService: FeatureFlagApiService,
    private errorService: ErrorHandlerV2Service,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.fillAppEnabledOptions();
  }

  fillAppEnabledOptions() {
    this.featureFlagService.loadByOrganizationId(this.organizationId);
    this.featureFlagService.featureFlagsByOrganization$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        map((featureFlags) => this.filterAppEnabledFeatureFlags(featureFlags)),
        map((featureFlags) => this.mapToSelectOptions(featureFlags)),
        map((options) => this.sortOptions(options)),
        tap((options) => this.options.set(options))
      )
      .subscribe();
  }

  onSelectionChanged(event: MultiSelectChangeEvent): void {
    const selectedOption = event.itemValue as SelectOption<IFeatureFlag>;
    this.multiSelect.resetSelectionSaveState();
    this.updateOptions(selectedOption);
  }

  private updateOptions(selectedOption: SelectOption<IFeatureFlag>) {
    const newAssigmentStatus: AssignmentStatus = this.toggleAssignmentStatus(selectedOption);
    if (selectedOption.value.assignmentType === null) selectedOption.value.assignmentType = AssignmentTypeModel.ORGANIZATION;
    const featureFlag = selectedOption.value;
    if (featureFlag.assignmentTo !== this.organizationId) return;

    this.callFeatureFlagApi(featureFlag, newAssigmentStatus).subscribe({
      next: () => {
        const featureFlagId = selectedOption.value.featureFlagId ?? '';
        const updatedOptions = this.createUpdatedOptionsWithNewAssigmentStatus(featureFlagId, newAssigmentStatus);
        this.options.set(updatedOptions);
      },
      error: (error: ApiErrorResponse) => {
        this.setDisabledStateOptions(false);
        this.errorService.handleError(error);
      },
    });
  }

  private callFeatureFlagApi(featureFlag: IFeatureFlag, newAssigmentStatus: AssignmentStatus) {
    return of({}).pipe(
      tap(() => this.setDisabledStateOptions(true)),
      concatMap(() => {
        if (newAssigmentStatus === 'ASSIGNED') {
          return this.callFeatureFlagAssignmentServiceCreate(this.mapNewFeatureFlagAssignment(featureFlag));
        }
        const featFlagId = featureFlag.featureFlagId ?? '';
        const assigmentId = this.findOptionByFeatureFlagId(featFlagId)?.value.id ?? '';
        return this.callFeatureFlagAssignmentServiceDelete(assigmentId);
      }),
      tap(() => this.setDisabledStateOptions(false))
    );
  }

  private filterAppEnabledFeatureFlags(featureFlags: IFeatureFlag[]): IFeatureFlag[] {
    return featureFlags.filter((flag) => {
      const { assignmentStatus, assignmentType } = flag;
      const isGlobal = assignmentStatus === 'GLOBAL';
      const isUnAssigned = assignmentStatus === 'UNASSIGNED';
      const isAssignedToCountry = assignmentStatus === 'ASSIGNED' && assignmentType === AssignmentTypeModel.COUNTRY;
      const isAssignedToOrganizationType = assignmentStatus === 'ASSIGNED' && assignmentType === AssignmentTypeModel.ORGANIZATION_TYPE;
      const isAssignedToOrganization = assignmentStatus === 'ASSIGNED' && assignmentType === AssignmentTypeModel.ORGANIZATION;
      return isGlobal || isAssignedToCountry || isAssignedToOrganization || isAssignedToOrganizationType || isUnAssigned;
    });
  }

  private mapToSelectOptions(featureFlags: IFeatureFlag[]): SelectOption<IFeatureFlag>[] {
    return featureFlags.map((flag) => {
      const { name, assignmentStatus, assignmentType, accessAllowed } = flag;
      const appName = name?.split('.')[0] ?? '';
      const isDisabled = this.isDisabledOption(assignmentStatus, assignmentType, accessAllowed);
      return {
        value: flag,
        name: appName,
        nameKey: 'APPS.' + appName.toUpperCase() + '.NAME',
        disabled: isDisabled
      };
    }) as unknown as SelectOption<IFeatureFlag>[];
  }

  private callFeatureFlagAssignmentServiceCreate(newFeatureFlagAssignment: NewFeatureFlagAssignment) {
    return this.featureFlagApiService.create(newFeatureFlagAssignment).pipe(
      tap((createdFeatureFlagAssignment) => {
        const updatedOptions = this.createUpdatedOptionsWithNewId(
          createdFeatureFlagAssignment.id,
          newFeatureFlagAssignment.featureFlag?.id
        );
        this.options.set(updatedOptions);
      })
    );
  }

  private callFeatureFlagAssignmentServiceDelete(assigmentId: string) {
    return this.featureFlagApiService.delete(assigmentId);
  }

  private mapNewFeatureFlagAssignment(featureFlag: IFeatureFlag): NewFeatureFlagAssignment {
    return {
      featureFlag: { id: featureFlag.featureFlagId, displayName: featureFlag.displayName },
      assignmentType: featureFlag.assignmentType,
      assignedTo: featureFlag.assignmentTo ?? '',
      assignedToLabel: this.organizationName,
    } as NewFeatureFlagAssignment;
  }

  private createUpdatedOptionsWithNewId(assigmentId: string, featureFlagId: string | undefined) {
    return this.options().map((option) =>
      option.value.featureFlagId === featureFlagId ? { ...option, value: { ...option.value, id: assigmentId } } : option
    );
  }

  private createUpdatedOptionsWithNewAssigmentStatus(
    featureFlagId: string,
    newAssignmentStatus: AssignmentStatus
  ): SelectOption<IFeatureFlag>[] {
    return this.options().map((option) =>
      option.value.featureFlagId === featureFlagId
        ? { ...option, value: { ...option.value, assignmentStatus: newAssignmentStatus } }
        : option
    );
  }

  private toggleAssignmentStatus(selectedOption: SelectOption<IFeatureFlag>): AssignmentStatus {
    return selectedOption.value.assignmentStatus === 'ASSIGNED' ? 'UNASSIGNED' : 'ASSIGNED';
  }

  private filterUnassignedOptions() {
    return this.options().filter((option) => option.value.assignmentStatus !== 'UNASSIGNED');
  }

  private findOptionByFeatureFlagId(featureFlagId: string): SelectOption<IFeatureFlag> | undefined {
    return this.options().find((option) => option.value.featureFlagId === featureFlagId);
  }

  private setDisabledStateOptions(disabled: boolean) {
    const updatedOptions = this.options().map((option) => {
      const { assignmentStatus, assignmentType, accessAllowed } = option.value;
      return !this.isDisabledOption(assignmentStatus, assignmentType, accessAllowed) ? { ...option, disabled } : option;
    });
    this.options.set(updatedOptions);
  }

  private handleFeatureFlagUpdateLoading() {
    return this.options().some((option) => {
      const { assignmentStatus, assignmentType, accessAllowed } = option.value;
      return this.isDisabledOption(assignmentStatus, assignmentType,accessAllowed) ? false : option.disabled;
    });
  }

  private isDisabledOption(assignmentStatus?: AssignmentStatus, assignmentType?: AssignmentTypeModel, accessAllowed?: boolean): boolean {
    const isGlobal = assignmentStatus === 'GLOBAL';
    const isAssignedToOrganizationType = assignmentStatus === 'ASSIGNED' && assignmentType === AssignmentTypeModel.ORGANIZATION_TYPE;
    const isAssignedToCountry = assignmentStatus === 'ASSIGNED' && assignmentType === AssignmentTypeModel.COUNTRY;
    return isGlobal || isAssignedToCountry || isAssignedToOrganizationType || !accessAllowed;
  }

  private sortOptions(options: SelectOption<IFeatureFlag>[]): SelectOption<IFeatureFlag>[] {
    return [...options].sort((a, b) => {
      return a.name?.localeCompare(b.name ?? '') ?? 0;
    });
  }
}
