<form [formGroup]="form">
  <div class="geaui-grid">
    <geaui-select-v2
      class="geaui-col-desktop-md-start-2 geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5"
      [options]="divisionTypes"
      [required]="true"
      [group]="form"
      formName="division"
      label="APPLICATION.DIVISION">
    </geaui-select-v2>

    <geaui-select-v2
      class="geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5"
      [required]="true"
      [group]="form"
      [options]="appTypesOptions"
      formName="appType"
      label="X.LABEL.TYPE">
    </geaui-select-v2>
    <div
      class="icon-wrapper geaui-row-desktop-md-start-2 geaui-col-desktop-md-1 geaui-col-desktop-sm-1 geaui-col-desktop-lg-1 geaui-col-desktop-xl-1">
      <geaui-icon-v2
        file="16px_c-info"
        colorProp="stroke"
        color="blue"
        tooltipPosition="bottom"
        pTooltip="{{ 'APPLICATION.APPNAME.INFO.LABEL' | translate }}">
      </geaui-icon-v2>
    </div>
    <geaui-input-v2
      class="name-style geaui-row-desktop-md-start-2 geaui-col-desktop-md-10 geaui-col-desktop-sm-10 geaui-col-desktop-lg-10 geaui-col-desktop-xl-10"
      [required]="true"
      [disabled]="!hasTypeAndDivision"
      [group]="form"
      formName="name"
      label="APPLICATION.MODAL.FORM.NAME">
    </geaui-input-v2>

    <div class="geaui-col-desktop-md-start-2 geaui-col-desktop-md-10">
      <p [innerHTML]="helperText"></p>
    </div>
    <geaui-input-v2
      class="geaui-col-desktop-md-start-2 geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5"
      [required]="true"
      [type]="'url'"
      [group]="form"
      formName="url"
      label="X.LABEL.URL">
    </geaui-input-v2>

    <geaui-input-v2
      class="geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5"
      [required]="true"
      [type]="'url'"
      [group]="form"
      formName="imagePath"
      label="X.LABEL.ICON">
    </geaui-input-v2>

    <geaui-radio-group-v2
      class="geaui-col-desktop-md-start-2 geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5"
      [flexDirection]="'row'"
      [group]="form"
      [options]="verificationOptions"
      selected="false"
      label="APPLICATION.VERIFICATION_REQUIRED.COLUMN.NAME"
      formName="verificationNeeded"></geaui-radio-group-v2>

    <geaui-radio-group-v2
      class="geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5"
      [flexDirection]="'row'"
      [group]="form"
      [options]="verificationOptions"
      selected="false"
      label="APPLICATION.MODAL.FORM.HAS_TRANSLATION.LABEL"
      formName="hasTranslation"></geaui-radio-group-v2>
  </div>
</form>
