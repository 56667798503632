/**
 * Digital Platform - GEA ID
 * The GEA ID API manages users, memberships, organizations, roles, and more within the Digital Platform.  It provides endpoints for user authentication, role assignments, organization management, and system health checks. 
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationStatus } from './verificationStatus';
import { OrganizationAdminResponseAllOfLastVerificationRequester } from './organizationAdminResponseAllOfLastVerificationRequester';
import { Address } from './address';
import { OrganizationAdminResponse } from './organizationAdminResponse';
import { SiteResponse } from './siteResponse';
import { OrganizationType } from './organizationType';
import { OrganizationPortalResponse } from './organizationPortalResponse';


/**
 * @type OrganizationResponse
 * @export
 */
export type OrganizationResponse = OrganizationAdminResponse | OrganizationPortalResponse;

