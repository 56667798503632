import { Membership } from '@gea/digital-ui-lib';

export interface TimeZone {
  standard: string;
  offset: string;
  zoneId: string;
}

export interface ProfileData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  title: string;
  settings: {
    country: string;
    language: string;
    timeZone: TimeZone;
  };
  memberships?: Membership[];
}
