<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [hasBackArrow]="false"
      pageTitle="GEA-USER.HEADER.TITLE"
      help="HELP.GEA_USER.LIST.PAGE">
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="gea-user-list-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
      <geaui-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [columnDefinitions]="columnDefinitions"
        [data]="geaUsers"
        [totalRecords]="totalRecords"
        [loading]="loading"
        [sortable]="true"
        tableId="gea-user-list">
      </geaui-table-v2>
    </div>
  </ng-container>
</geaui-page-content-layout>
