import { Injectable } from '@angular/core';
import { BehaviorSubject, first, map, switchMap, tap } from 'rxjs';
import { ApplicationApiService } from './application-api-service';
import { FilterTableSettings, ListResponse, UserState } from '@gea/digital-ui-lib';
import { IApplication, IAsset, NewApplication } from '../models/application.model';
import { appRoutes } from '../models/applications.routing';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  private _applications$ = new BehaviorSubject<ListResponse<IApplication[]>>({
    entryCount: 0,
    pageEntries: [],
  });
  private _assets$ = new BehaviorSubject<IAsset[]>([]);
  private _appId$ = new BehaviorSubject([]);
  public readonly tabMenu$ = this._appId$.pipe(map(() => this._tabMenu()));

  private currentApplication = new BehaviorSubject<IApplication | null>(null);

  getCurrentApplication() {
    return this.currentApplication.asObservable();
  }

  getCurrentApplicationValue() {
    return this.currentApplication.getValue();
  }
  setCurrentApplication(app: IApplication) {
    this.currentApplication.next(app);
  }
  get applications$() {
    return this._applications$.asObservable();
  }

  get applications() {
    return this._applications$.getValue();
  }
  get assets$() {
    return this._assets$.asObservable();
  }
  isFeatureFlagDisabled = false ; 
  constructor(private applicationApiService: ApplicationApiService, private store: Store) {
    this.store.select(UserState.tokenObject).subscribe(token => {

      const scopes = token?.extension_admin_scopes;
     this.isFeatureFlagDisabled = scopes ? !Object.values(scopes).some((entry) => entry.name === 'Admin_Manage_FeatureFlags') : true;
  });
  }

  init(tableFilter: FilterTableSettings) {
    return this.applicationApiService
      .getAllApps(tableFilter)
      .pipe(first())
      .pipe(
        tap((data) => {
          this._applications$.next(data);
        })
      );
  }

  getAssets(id: string) {
    return this.applicationApiService
      .getAllAppAssets(id)
      .pipe(first())
      .pipe(
        tap((data) => {
          this._assets$.next(data);
        })
      );
  }

  addAsset(id: string, asset: FormData) {
    return this.applicationApiService.createAsset(id, asset).pipe(
      switchMap(() => this.getAssets(id)), 
      tap((updatedAssets: IAsset[]) => {
        this._assets$.next(updatedAssets); 
      })
    );
  }

  deleteAsset(id: string, assetId: string) {
    return this.applicationApiService.deleteAsset(id, assetId).pipe(
      tap({
        next: () => {
          const assets = this._assets$.getValue();
          const index = assets.findIndex((asset) => asset.id === assetId);
          assets.splice(index, 1);
          this._assets$.next(assets);
        },
      })
    );
  }

  add(application: NewApplication) {
    return this.applicationApiService.create(application).pipe(
      tap({
        next: (result) => {
          this._applications$.next({
            pageEntries: [
              {
                ...result,
              },
              ...this.applications.pageEntries,
            ],
            entryCount: this.applications.entryCount + 1,
          });
        },
      })
    );
  }

  find(id: string) {
    return this.applicationApiService.find(id);
  }

  delete(id: string) {
    return this.applicationApiService.delete(id).pipe(
      tap({
        next: () => {
          const apps = this.applications.pageEntries;
          const index = apps.findIndex((app) => app.id === id);
          apps.splice(index, 1);
          this._applications$.next({
            entryCount: this.applications.entryCount - 1,
            pageEntries: apps,
          });
        },
      })
    );
  }

  update(id: string, application: Partial<IApplication>) {
    if (!id) throw Error('Tried editing Application without an id');
    return this.applicationApiService.update(id, application).pipe(
      tap({
        next: () => {
          const apps = this.applications.pageEntries;
          this._applications$.next({ pageEntries: apps, entryCount: this.applications.entryCount });
        },
      })
    );
  }

  getSecrets(id: string) {
    return this.applicationApiService.getAppSecrets(id);
  }

  updateSecret(id: string, name: string) {
    return this.applicationApiService.updateAppSecret(id, name);
  }
  private _tabMenu() {
    return [
      {
        id: 'info',
        label: `APPLICATION.DETAIL.PATHS.INFO`,
        routerLink: appRoutes.BASIC_INFO,
      },
      {
        id: 'assets',
        label: `APPLICATION.DETAIL.PATHS.ASSETS`,
        routerLink: appRoutes.ASSETS,
      },
      {
        id: 'secrets',
        label: `APPLICATION.DETAIL.PATHS.SECRETS`,
        routerLink: appRoutes.SECRETS,
      },
      {
        id: 'feature-flags',
        label: `FEATURE_FLAGS_.TAB_TITLE`,
        routerLink: appRoutes.FEATURES_FLAGS,
        disabled:this.isFeatureFlagDisabled
      }, 
    ];
  }
}
