import { Injectable } from '@angular/core';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomParamService {
  getParams(tableFilter: FilterTableSettings): HttpParams {
    let params: HttpParams = new HttpParams();

    if (!tableFilter) {
      return params;
    }

    params = params.append('page', tableFilter.page ?? 0);
    if (tableFilter.pageSize) {
      params = params.append('size', tableFilter.pageSize);
    }

    for (const key in tableFilter.columns) {
      const sortDir = tableFilter.columns[key].sort;
      if (sortDir) {
        const sortKey: string = key != 'featureFlagDisplayName' ? key : 'featureFlag.displayName';
        params = params.append('sort', sortKey.concat(',').concat(sortDir));
      }

      const filter = tableFilter.columns[key].filter ?? [];
      filter.forEach((f) => {
        params = params.append(`${key}`, f);
      });
    }

    return params;
  }
}
