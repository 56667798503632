@if (!loading) {
  <div class="secret-card">
    <p class="gea-font-heading-16">{{ secret.displayName }}</p>
    <p class="gea-font-body-16">{{ 'APPLICATION.SECRET.EXPIRE-ON' | translate }}: {{ formatDate(secret.endDateTime) }}</p>
    <geaui-chip [severity]="secretStatus.severity" [preIcon]="secretStatus.icon" size="small">
      {{ secretStatus.message | translate }}
    </geaui-chip>
    <div class="button-style">
      <geaui-button-v2
        class="button-style"
        [sending]="isSending"
        [disabled]="secretStatus.severity === 'success'"
        (click)="onRenew()"
        size="small"
        preIcon="arrow-right">
        {{ 'APPLICATION.SECRET.BUTTON.RENEW.LABEL' | translate }}
      </geaui-button-v2>
    </div>
  </div>
} @else {
  <ngx-skeleton-loader [theme]="{ height: '100%', width: '100%' }" count="1" appearance="line"></ngx-skeleton-loader>
}
