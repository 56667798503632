import { InjectionToken } from '@angular/core';

export const DOCUMENT_SERVICE_BASE_URL = new InjectionToken<string>('documentServiceBaseUrl');

export interface SharepointWrapperDto<T extends SharepointSitesDto | SharepointSiteDto> {
  mdgCustomerNumber?: string;
  sites: T[];
}

export interface SharepointSitesDto {
  organizationId: string;
  sites: SharepointSiteDto[];
}

export interface SharepointSiteDto {
  siteId: string;
  siteType: SharepointSiteType;
}

export type SharepointSiteType = 'project' | 'customer';

export interface SharepointSites {
  [organisationId: string]: {
    projectSitesId: string[];
    customerPortalIds: string[];
  };
}

export interface SharepointSite {
  projectSitesId: string[];
  customerPortalIds: string[];
}
