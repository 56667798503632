import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatestWith, filter, Observable, of, switchMap, tap } from 'rxjs';
import {
  ApiErrorResponse,
  DigitalPlatformOrganizationAdministrationService,
  ErrorHandlerV2Service,
  extractRequestParams,
  FilterTableSettings,
  OrganizationType,
  OrganizationTypeInfo,
  OrganizationTypePermissions,
  ResourcesService,
  SelectOption,
  SiteResponse,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { NEW_ORGA_ID, orgaRoutes } from '../../models/organizations.routing';
import { map } from 'rxjs/operators';
import {
  OrganizationDetail,
  OrganizationDetailResponse,
  OrganizationsCreateRelationRequestParams,
  OrganizationsDeleteRelationRequestParams,
  OrganizationService,
  OrganizationsUpdateRelationRequestParams,
  RelationsApiService,
  SitesApiService,
  BusinessRelationResponseV1,
  SiteRequest,
  OrganizationsGetRelationsPaginated200Response,
  OrganizationsGetRelationsPaginatedRequestParams,
} from '@gea-id/shared';

@Injectable({
  providedIn: 'root',
})
export class OrganizationDetailService {
  private _selectedOrgaType$ = new BehaviorSubject(OrganizationType.UNKNOWN);
  private _orgaId$ = new BehaviorSubject(NEW_ORGA_ID);
  private _initialOrgaData = new BehaviorSubject<OrganizationDetailResponse | undefined>(undefined);
  private _relations$ = new BehaviorSubject<OrganizationsGetRelationsPaginated200Response>({
    entryCount: 0,
    pageEntries: [],
  });
  private _sites$ = new BehaviorSubject<SiteResponse[]>([]);

  public readonly countriesOptions$: Observable<SelectOption<string>[]> = this.resourceService.getCountries();
  public readonly tabMenu$ = this._orgaId$.pipe(
    combineLatestWith(this._selectedOrgaType$),
    map(([orgaId, orgaType]) => this._tabMenu(orgaId, orgaType))
  );

  private _orgaTypes$ = new BehaviorSubject<OrganizationTypeInfo[]>([]);

  get orgaTypes$(): Observable<OrganizationTypeInfo[]> {
    return this._orgaTypes$.asObservable();
  }

  get orgaType$(): Observable<OrganizationTypeInfo | undefined> {
    return this._selectedOrgaType$.pipe(
      switchMap((selectedType) => this._orgaTypes$.pipe(map((types) => types.find((type) => type.type === selectedType))))
    );
  }

  get sites$(): Observable<SiteResponse[]> {
    return this._sites$.asObservable();
  }

  get relations$(): Observable<OrganizationsGetRelationsPaginated200Response> {
    return this._relations$.asObservable();
  }

  get relatedOrganisationsIds(): string[] {
    return this._relations$.getValue().pageEntries.map((relation) => relation.relatedOrganizationId);
  }

  get relationTypes$(): Observable<string[]> {
    return this._initialOrgaData.pipe(
      filter((x): x is OrganizationDetailResponse => !!x),
      map((orgaData) => orgaData.type),
      switchMap((type) => this.relationTypesService.getRelationTypes(type)),
      map((relationTypes) => relationTypes.map((relationType) => relationType.name))
    );
  }

  constructor(
    private resourceService: ResourcesService,
    private organizationService: OrganizationService,
    private relationTypesService: DigitalPlatformOrganizationAdministrationService,
    private relationsService: RelationsApiService,
    private sitesService: SitesApiService,
    private errorHandler: ErrorHandlerV2Service,
    private snackbar: SnackbarService
  ) {
    this.resourceService.getOrgaTypesV2Admin().subscribe((orgaTypes) => this._orgaTypes$.next(orgaTypes));
  }

  get initialOrgaData$(): Observable<OrganizationDetailResponse | undefined> {
    return this._initialOrgaData.asObservable();
  }

  init(orgaId: string) {
    this._orgaId$.next(orgaId);
    this._initialOrgaData.next(undefined);
    this._selectedOrgaType$.next(OrganizationType.UNKNOWN);
    if (orgaId !== NEW_ORGA_ID) {
      this.organizationService
        .getOrgaDetails(orgaId)
        .pipe()
        .subscribe((orgaData) => {
          this._initialOrgaData.next(orgaData);
          this._selectedOrgaType$.next(orgaData.type);
        });
    }
  }

  initRelations(filter: FilterTableSettings, orgaId?: string, options?: { preTap?: () => void; postTap?: () => void }) {
    const mappings: { [key: string]: keyof OrganizationsGetRelationsPaginatedRequestParams } = {
      ownerName: 'ownerNameContains',
      relatedName: 'relatedNameContains',
    };

    const params: OrganizationsGetRelationsPaginatedRequestParams = extractRequestParams(filter, mappings, []);

    this._relations$.next({ entryCount: 0, pageEntries: [] });
    if (orgaId)
      of(null)
        .pipe(
          options?.preTap ? tap(options.preTap) : tap(),
          switchMap(() => this.relationsService.organizationsGetRelationsPaginated({ ...params, orgaId: orgaId })),
          options?.postTap ? tap(options.postTap) : tap()
        )
        .subscribe((relations) => this._relations$.next(relations));
  }

  addRelation(request: OrganizationsCreateRelationRequestParams) {
    return this.relationsService.organizationsCreateRelation(request).pipe(
      tap({
        next: (response: BusinessRelationResponseV1) => {
          const relations = this._relations$.getValue();
          this._relations$.next({
            entryCount: relations.entryCount + 1,
            pageEntries: [response, ...relations.pageEntries],
          });
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.CREATE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
        },
        error: (error: ApiErrorResponse) => this.errorHandler.handleError(error),
      })
    );
  }

  editRelation(request: OrganizationsUpdateRelationRequestParams) {
    return this.relationsService.organizationsUpdateRelation(request).pipe(
      tap({
        next: (response: BusinessRelationResponseV1) => {
          const relations = this._relations$.getValue();
          const index = relations.pageEntries.findIndex((relation) => relation.id === response.id);
          relations.pageEntries[index] = response;
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.UPDATE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
        },
        error: (error: ApiErrorResponse) => this.errorHandler.handleError(error),
      })
    );
  }

  deleteRelation(request: OrganizationsDeleteRelationRequestParams) {
    return this.relationsService.organizationsDeleteRelation(request).pipe(
      tap({
        next: () => {
          const relations = this._relations$.getValue();
          const index = relations.pageEntries.findIndex((relation) => relation.id === request.relationId);
          relations.pageEntries.splice(index, 1);
          relations.entryCount--;
          this._relations$.next(relations);
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
        },
        error: (error: ApiErrorResponse) => this.errorHandler.handleError(error),
      })
    );
  }

  initSites(orgaId?: string, options?: { preTap?: () => void; postTap?: () => void }) {
    this._sites$.next([]);
    if (orgaId)
      of(null)
        .pipe(
          options?.preTap ? tap(options.preTap) : tap(),
          switchMap(() => this.sitesService.organizationsGetSites({ orgaId })),
          options?.postTap ? tap(options.postTap) : tap()
        )
        .subscribe((sites) => this._sites$.next(sites));
  }

  addSite(orgaId: string, data: SiteRequest) {
    return this.sitesService
      .organizationsCreateSite({
        orgaId: orgaId,
        siteRequest: data,
      })
      .pipe(
        tap({
          next: (response: SiteResponse) => {
            const sites = this._sites$.getValue();
            sites.push(response);
            this._sites$.next(sites);
            this.snackbar.add({
              severity: 'success',
              summary: 'X.MESSAGE.SUCCESS.SUMMARY',
              detail: 'X.MESSAGE.SUCCESS.DETAIL.UPDATE',
            });
          },
          error: (error: ApiErrorResponse) => this.errorHandler.handleError(error),
        })
      );
  }

  editSite(orgaId: string, siteId: string, data: SiteRequest) {
    return this.sitesService
      .organizationsUpdateSite({
        orgaId: orgaId,
        siteId: siteId,
        siteRequest: data,
      })
      .pipe(
        tap({
          next: (response: SiteResponse) => {
            const sites = this._sites$.getValue();
            const index = sites.findIndex((row) => row.id === data.id);
            sites[index] = response;
            this._sites$.next(sites);
            this.snackbar.add({
              severity: 'success',
              summary: 'X.MESSAGE.SUCCESS.SUMMARY',
              detail: 'X.MESSAGE.SUCCESS.DETAIL.UPDATE',
            });
          },
          error: (error: ApiErrorResponse) => this.errorHandler.handleError(error),
        })
      );
  }

  deleteSite(orgaId: string, siteId: string) {
    return this.sitesService.organizationsDeleteSite({ orgaId: orgaId, siteId: siteId }).pipe(
      tap({
        next: () => {
          const sites = this._sites$.getValue();
          const index = sites.findIndex((site) => site.id === siteId);
          sites.splice(index, 1);
          this._sites$.next(sites);

          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
        },
        error: (error: ApiErrorResponse) => this.errorHandler.handleError(error),
      })
    );
  }

  public update({ orgaType, orgaId }: { orgaId?: string; orgaType?: OrganizationType | null }) {
    if (orgaType) this._selectedOrgaType$.next(orgaType);
    if (orgaId) this._orgaId$.next(orgaId);
  }

  public save(orga: Partial<OrganizationDetail>) {
    const initialData = this._initialOrgaData.getValue();
    const request: OrganizationDetail & { ignoreSites: true; ignoreRelations: true } = {
      type: orga.type ?? initialData?.type ?? this._selectedOrgaType$.getValue(),
      name: orga.name ?? initialData?.name ?? '',
      isBillingAddressSame: !!initialData?.isBillingAddressSame,
      shippingAddress: orga.shippingAddress ??
        initialData?.shippingAddress ?? {
          city: '',
          street: '',
          country: '',
          zipCode: '',
        },
      billingAddress: orga.billingAddress ?? initialData?.billingAddress,
      customerNumber: orga.customerNumber ?? initialData?.customerNumber,
      lastVerificationRequest: orga.lastVerificationRequest ?? initialData?.lastVerificationRequest,
      verificationStatus: orga.verificationStatus ?? initialData?.verificationStatus,
      ignoreSites: true,
      ignoreRelations: true,
    };
    return this.organizationService.updateOrga(this._orgaId$.getValue(), request);
  }

  private _tabMenu(orgaId: string, orgaType: OrganizationType) {
    return [
      {
        id: 'info',
        label: `ORGANIZATION.DETAIL.PATHS.INFO`,
        routerLink: orgaRoutes.BASIC_INFO,
        help: 'HELP.ORGANIZATION.BASIC_INFO.PAGE',
        helpTitle: 'HELP.ORGANIZATION.BASIC_INFO.TITLE',
      },
      {
        id: 'users',
        label: `ORGANIZATION.DETAIL.PATHS.USERS`,
        routerLink: orgaRoutes.USERS,
        disabled: orgaId === NEW_ORGA_ID,
        help: 'HELP.ORGANIZATION.USERS.PAGE',
        helpTitle: 'HELP.ORGANIZATION.USERS.TITLE',
      },
      {
        id: 'relations',
        label: `ORGANIZATION.DETAIL.PATHS.RELATIONS`,
        routerLink: orgaRoutes.BUSINESS_RELATIONS,
        disabled: orgaId === NEW_ORGA_ID || !this.hasOrgaTypeFlag(orgaType, 'READ_RELATIONS'),
        help: 'HELP.ORGANIZATION.RELATIONS.PAGE',
        helpTitle: 'HELP.ORGANIZATION.RELATIONS.TITLE',
      },
      {
        id: 'sites',
        label: `ORGANIZATION.DETAIL.PATHS.SITES`,
        routerLink: orgaRoutes.SITES,
        disabled: orgaId === NEW_ORGA_ID || !this.hasOrgaTypeFlag(orgaType, 'EDIT_SITES'),
        help: 'HELP.ORGANIZATION.SITES.PAGE',
        helpTitle: 'HELP.ORGANIZATION.SITES.TITLE',
      },
      {
        id: 'history',
        label: `ORGANIZATION.DETAIL.PATHS.HISTORY`,
        routerLink: orgaRoutes.HISTORY,
        disabled: orgaId === NEW_ORGA_ID,
        help: 'HELP.ORGANIZATION.HISTORY.PAGE',
        helpTitle: 'HELP.ORGANIZATION.HISTORY.TITLE',
      },
    ];
  }

  private hasOrgaTypeFlag(orgaType: OrganizationType, permission: OrganizationTypePermissions): boolean {
    return !!this._orgaTypes$
      .getValue()
      .find((type) => type.type === orgaType)
      ?.orgaFlags.includes(permission);
  }
}
