import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, first, switchMap, tap } from 'rxjs';
import { IFeatureFlagAssignment, NewFeatureFlagAssignment } from '../models/feature-flag-assignment.model';
import { FeatureFlagApiService } from './feature-flag-api-service';
import { FilterTableSettings, ListResponse } from '@gea/digital-ui-lib';
import { IFeatureFlag } from '../models/feature-flag.model';
import { CustomParamService } from './param.service';
import { ApplicationService } from '../../applications/services/application.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  private _featureFlagsAssignements$ = new BehaviorSubject<ListResponse<IFeatureFlagAssignment[]>>({
    entryCount: 0,
    pageEntries: [],
  });
  private _featureFlagsByOrganizationId$ = new BehaviorSubject<IFeatureFlag[]>([]);

  private _featureFlags$ = new BehaviorSubject<ListResponse<IFeatureFlag[]>>({
    entryCount: 0,
    pageEntries: [],
  });

  get featureFlags() {
    return this._featureFlags$.getValue();
  }
  get featureFlags$() {
    return this._featureFlags$.asObservable();
  }
  constructor(
    private featureFlagApiService: FeatureFlagApiService,
    private paramService: CustomParamService,
    private applicationService: ApplicationService
  ) {}

  get featureFlagsAssignements$() {
    return this._featureFlagsAssignements$.asObservable();
  }

  get featureFlagsAssignements() {
    return this._featureFlagsAssignements$.getValue();
  }

  get featureFlagsByOrganization$(): Observable<IFeatureFlag[]> {
    return this._featureFlagsByOrganizationId$.asObservable();
  }

  init(tableFilter: FilterTableSettings) {
    return this.featureFlagApiService
      .getAllFeatureFlagAssignments(tableFilter)
      .pipe(first())
      .pipe(
        tap((data) => {
          this._featureFlagsAssignements$.next(data);
        })
      );
  }

  add(flag: NewFeatureFlagAssignment) {
    return this.featureFlagApiService.create(flag).pipe(
      tap({
        next: (result) => {
          this._featureFlagsAssignements$.next({
            pageEntries: [
              {
                ...result,
                featureFlagDisplayName: flag.featureFlag?.displayName ?? '', // FIXME Should not be necessary, fix in backend
              },
              ...this.featureFlagsAssignements.pageEntries,
            ],
            entryCount: this.featureFlagsAssignements.entryCount + 1,
          });
        },
      })
    );
  }

  update(id: string, flag: NewFeatureFlagAssignment) {
    if (!id) throw Error('Tried editing Feature Flag without an id');

    return this.featureFlagApiService.update(id, flag).pipe(
      tap({
        next: (response) => {
          const flags = this.featureFlagsAssignements.pageEntries;
          const index = flags.findIndex((assignment) => assignment.id === id);

          // TODO this should not be necessary. response should contain this data
          flags[index] = {
            ...response,
            featureFlagDisplayName: flag.featureFlag?.displayName ?? '',
            createdAt: flags[index].createdAt,
            createdBy: flags[index].createdBy,
          };
          this._featureFlagsAssignements$.next({ pageEntries: flags, entryCount: this.featureFlagsAssignements.entryCount });
        },
      })
    );
  }

  delete(id: string) {
    return this.featureFlagApiService.delete(id).pipe(
      tap({
        next: () => {
          const flags = this.featureFlagsAssignements.pageEntries;
          const index = flags.findIndex((assignment) => assignment.id === id);
          flags.splice(index, 1);
          this._featureFlagsAssignements$.next({
            entryCount: this.featureFlagsAssignements.entryCount - 1,
            pageEntries: flags,
          });
        },
      })
    );
  }

  loadByOrganizationId(organizationId: string) {
    this.featureFlagApiService
      .getFeatureFlagByOrganizationId(organizationId)
      .pipe(first())
      .subscribe((featureFlags) => this._featureFlagsByOrganizationId$.next(featureFlags));
  }

  getAllFeatureFlags(tableFilter: FilterTableSettings) {
    const params = this.paramService.getParams(tableFilter);
    return this.featureFlagApiService
      .getAllFeatureFlags(params)
      .pipe(first())
      .pipe(
        tap((data) => {
          this._featureFlags$.next(data);
        })
      );
  }

  addFeatureFlag(featureFlag: IFeatureFlag) {
    return this.featureFlagApiService.addFeatureFlag(featureFlag).pipe(
      switchMap(() => {
        const filter = {
          columns: {
            name: {
              filter: [this.applicationService.getCurrentApplicationValue()?.name ?? ''],
            },
          },
        };
        return this.getAllFeatureFlags(filter);
      }),
      tap((updatedFlags: ListResponse<IFeatureFlag[]>) => {
        this._featureFlags$.next(updatedFlags);
      })
    );
  }

  updateFeatureFlag(id: string, featureFlag: IFeatureFlag) {
    if (!id) throw Error('Tried editing Feature Flag without an id');
    return this.featureFlagApiService.updateFeatureFlag(id, featureFlag).pipe(
      tap({
        next: (response) => {
          const flags = this.featureFlags.pageEntries;
          const index = flags.findIndex((assignment) => assignment.id === id);
          flags[index] = {
            ...response,
          };
          this._featureFlags$.next({ pageEntries: flags, entryCount: this.featureFlags.entryCount });
        },
      })
    );
  }

  deleteFeatureFlag(id: string) {
    return this.featureFlagApiService.deleteFeatureFlag(id).pipe(
      tap({
        next: () => {
          const flags = this.featureFlags.pageEntries;
          const index = flags.findIndex((feature) => feature.id === id);
          flags.splice(index, 1);
          this._featureFlags$.next({
            entryCount: this.featureFlags.entryCount - 1,
            pageEntries: flags,
          });
        },
      })
    );
  }
}
