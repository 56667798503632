/**
 * Digital Platform - GEA ID
 * The GEA ID API manages users, memberships, organizations, roles, and more within the Digital Platform.  It provides endpoints for user authentication, role assignments, organization management, and system health checks. 
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApiError } from '../model/apiError';
// @ts-ignore
import { BusinessRelationResponseV1 } from '../model/businessRelationResponseV1';
// @ts-ignore
import { BusinessRelationUpdateCreateRequest } from '../model/businessRelationUpdateCreateRequest';
// @ts-ignore
import { OrganizationsGetRelationsPaginated200Response } from '../model/organizationsGetRelationsPaginated200Response';
// @ts-ignore
import { RelationTypesGetRelatableOrganizations200ResponseInner } from '../model/relationTypesGetRelatableOrganizations200ResponseInner';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { GeaIdConfiguration }                                     from '../configuration';


export interface OrganizationsCreateRelationRequestParams {
    /** Organization UUID */
    orgaId: string;
    /** New relation data */
    businessRelationUpdateCreateRequest?: BusinessRelationUpdateCreateRequest;
}

export interface OrganizationsDeleteRelationRequestParams {
    /** Organization UUID */
    orgaId: string;
    /** RelationId as UUID */
    relationId: string;
}

export interface OrganizationsGetRelationsRequestParams {
    /** Organization UUID */
    orgaId: string;
}

export interface OrganizationsGetRelationsPaginatedRequestParams {
    /** Organization UUID */
    orgaId: string;
    /** Sets an equals filter on the column ownerOrganizationId */
    ownerOrganizationIdEquals?: string;
    /** Sets an equals filter on the column ownerName */
    ownerNameEquals?: string;
    /** Sets an contains filter on the column ownerName */
    ownerNameContains?: string;
    /** Sets an equals filter on the column relatedOrganizationId */
    relatedOrganizationIdEquals?: string;
    /** Sets an equals filter on the column relatedName */
    relatedNameEquals?: string;
    /** Sets an contains filter on the column relatedName */
    relatedNameContains?: string;
    /** Searches with an contains filter over multiple columns */
    search?: string;
    /** Sorts the response according to the specified field */
    sortBy?: string;
    /** Specifies the sorting direction */
    sortDir?: 'asc' | 'desc';
    /** Specifies which page should be loaded */
    page?: string;
    /** Specifies how large a page should be */
    pageSize?: string;
}

export interface OrganizationsUpdateRelationRequestParams {
    /** Organization UUID */
    orgaId: string;
    /** RelationId as UUID */
    relationId: string;
    /** New relation data */
    businessRelationUpdateCreateRequest?: BusinessRelationUpdateCreateRequest;
}

export interface RelationTypesGetRelatableOrganizationsRequestParams {
    /** The name of the relation type */
    typeName: string;
    /** Sets an contains filter on the column name */
    nameContains?: string;
    /** Filter by name equals */
    nameEquals?: string;
    /** Sorts the response according to the specified field */
    sortBy?: string;
    /** Specifies the sorting direction */
    sortDir?: 'asc' | 'desc';
    /** Specifies which page should be loaded */
    page?: string;
    /** Specifies how large a page should be */
    pageSize?: string;
}


@Injectable({
  providedIn: 'root'
})
export class RelationsApiService {

    protected basePath = 'https://api.apps.dev.gea.com/geaid';
    public defaultHeaders = new HttpHeaders();
    public configuration = new GeaIdConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: GeaIdConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create Business Relation
     * Creates a new relation for the organization with the given id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsCreateRelation(requestParameters: OrganizationsCreateRelationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BusinessRelationResponseV1>;
    public organizationsCreateRelation(requestParameters: OrganizationsCreateRelationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BusinessRelationResponseV1>>;
    public organizationsCreateRelation(requestParameters: OrganizationsCreateRelationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BusinessRelationResponseV1>>;
    public organizationsCreateRelation(requestParameters: OrganizationsCreateRelationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const orgaId = requestParameters.orgaId;
        if (orgaId === null || orgaId === undefined) {
            throw new Error('Required parameter orgaId was null or undefined when calling organizationsCreateRelation.');
        }
        const businessRelationUpdateCreateRequest = requestParameters.businessRelationUpdateCreateRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/organizations/${this.configuration.encodeParam({name: "orgaId", value: orgaId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/relations`;
        return this.httpClient.request<BusinessRelationResponseV1>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: businessRelationUpdateCreateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes relation with given id
     * Deletes relation with given id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsDeleteRelation(requestParameters: OrganizationsDeleteRelationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public organizationsDeleteRelation(requestParameters: OrganizationsDeleteRelationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public organizationsDeleteRelation(requestParameters: OrganizationsDeleteRelationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public organizationsDeleteRelation(requestParameters: OrganizationsDeleteRelationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const orgaId = requestParameters.orgaId;
        if (orgaId === null || orgaId === undefined) {
            throw new Error('Required parameter orgaId was null or undefined when calling organizationsDeleteRelation.');
        }
        const relationId = requestParameters.relationId;
        if (relationId === null || relationId === undefined) {
            throw new Error('Required parameter relationId was null or undefined when calling organizationsDeleteRelation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/organizations/${this.configuration.encodeParam({name: "orgaId", value: orgaId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/relations/${this.configuration.encodeParam({name: "relationId", value: relationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Business Relations
     * Returns all relations of the organization with the given id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsGetRelations(requestParameters: OrganizationsGetRelationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<BusinessRelationResponseV1>>;
    public organizationsGetRelations(requestParameters: OrganizationsGetRelationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<BusinessRelationResponseV1>>>;
    public organizationsGetRelations(requestParameters: OrganizationsGetRelationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<BusinessRelationResponseV1>>>;
    public organizationsGetRelations(requestParameters: OrganizationsGetRelationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const orgaId = requestParameters.orgaId;
        if (orgaId === null || orgaId === undefined) {
            throw new Error('Required parameter orgaId was null or undefined when calling organizationsGetRelations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/organizations/${this.configuration.encodeParam({name: "orgaId", value: orgaId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/relations`;
        return this.httpClient.request<Array<BusinessRelationResponseV1>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Business Relations
     * Returns all relations of the organization with the given id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsGetRelationsPaginated(requestParameters: OrganizationsGetRelationsPaginatedRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrganizationsGetRelationsPaginated200Response>;
    public organizationsGetRelationsPaginated(requestParameters: OrganizationsGetRelationsPaginatedRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrganizationsGetRelationsPaginated200Response>>;
    public organizationsGetRelationsPaginated(requestParameters: OrganizationsGetRelationsPaginatedRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrganizationsGetRelationsPaginated200Response>>;
    public organizationsGetRelationsPaginated(requestParameters: OrganizationsGetRelationsPaginatedRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const orgaId = requestParameters.orgaId;
        if (orgaId === null || orgaId === undefined) {
            throw new Error('Required parameter orgaId was null or undefined when calling organizationsGetRelationsPaginated.');
        }
        const ownerOrganizationIdEquals = requestParameters.ownerOrganizationIdEquals;
        const ownerNameEquals = requestParameters.ownerNameEquals;
        const ownerNameContains = requestParameters.ownerNameContains;
        const relatedOrganizationIdEquals = requestParameters.relatedOrganizationIdEquals;
        const relatedNameEquals = requestParameters.relatedNameEquals;
        const relatedNameContains = requestParameters.relatedNameContains;
        const search = requestParameters.search;
        const sortBy = requestParameters.sortBy;
        const sortDir = requestParameters.sortDir;
        const page = requestParameters.page;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (ownerOrganizationIdEquals !== undefined && ownerOrganizationIdEquals !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ownerOrganizationIdEquals, 'ownerOrganizationId[equals]');
        }
        if (ownerNameEquals !== undefined && ownerNameEquals !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ownerNameEquals, 'ownerName[equals]');
        }
        if (ownerNameContains !== undefined && ownerNameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ownerNameContains, 'ownerName[contains]');
        }
        if (relatedOrganizationIdEquals !== undefined && relatedOrganizationIdEquals !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relatedOrganizationIdEquals, 'relatedOrganizationId[equals]');
        }
        if (relatedNameEquals !== undefined && relatedNameEquals !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relatedNameEquals, 'relatedName[equals]');
        }
        if (relatedNameContains !== undefined && relatedNameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relatedNameContains, 'relatedName[contains]');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/organizations/${this.configuration.encodeParam({name: "orgaId", value: orgaId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/relations/paginated`;
        return this.httpClient.request<OrganizationsGetRelationsPaginated200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Business Relation
     * Updates the relation of the given id with the given data.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsUpdateRelation(requestParameters: OrganizationsUpdateRelationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public organizationsUpdateRelation(requestParameters: OrganizationsUpdateRelationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public organizationsUpdateRelation(requestParameters: OrganizationsUpdateRelationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public organizationsUpdateRelation(requestParameters: OrganizationsUpdateRelationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const orgaId = requestParameters.orgaId;
        if (orgaId === null || orgaId === undefined) {
            throw new Error('Required parameter orgaId was null or undefined when calling organizationsUpdateRelation.');
        }
        const relationId = requestParameters.relationId;
        if (relationId === null || relationId === undefined) {
            throw new Error('Required parameter relationId was null or undefined when calling organizationsUpdateRelation.');
        }
        const businessRelationUpdateCreateRequest = requestParameters.businessRelationUpdateCreateRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/organizations/${this.configuration.encodeParam({name: "orgaId", value: orgaId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/relations/${this.configuration.encodeParam({name: "relationId", value: relationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: businessRelationUpdateCreateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Organizations that can be related to the given relation type
     * Returns all organizations that can be related to the given relation type
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public relationTypesGetRelatableOrganizations(requestParameters: RelationTypesGetRelatableOrganizationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<RelationTypesGetRelatableOrganizations200ResponseInner>>;
    public relationTypesGetRelatableOrganizations(requestParameters: RelationTypesGetRelatableOrganizationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<RelationTypesGetRelatableOrganizations200ResponseInner>>>;
    public relationTypesGetRelatableOrganizations(requestParameters: RelationTypesGetRelatableOrganizationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<RelationTypesGetRelatableOrganizations200ResponseInner>>>;
    public relationTypesGetRelatableOrganizations(requestParameters: RelationTypesGetRelatableOrganizationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const typeName = requestParameters.typeName;
        if (typeName === null || typeName === undefined) {
            throw new Error('Required parameter typeName was null or undefined when calling relationTypesGetRelatableOrganizations.');
        }
        const nameContains = requestParameters.nameContains;
        const nameEquals = requestParameters.nameEquals;
        const sortBy = requestParameters.sortBy;
        const sortDir = requestParameters.sortDir;
        const page = requestParameters.page;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'name[contains]');
        }
        if (nameEquals !== undefined && nameEquals !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameEquals, 'name[equals]');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/relation-types/${this.configuration.encodeParam({name: "typeName", value: typeName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/relatable-organizations`;
        return this.httpClient.request<Array<RelationTypesGetRelatableOrganizations200ResponseInner>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
