<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [hasBackArrow]="false"
      pageTitle="NOTIFICATIONS.SEND.TITLE.LABEL"
      help="HELP.NOTIFICATION.SEND.PAGE"></geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="system-notification-container geaui-grid geaui-sidebar-grid geaui-scrollable-table" [formGroup]="form">
      <div class="gea-font-heading-20 title geaui-col-desktop-xl-start-7 geaui-col-desktop-md-4">
        {{ 'NOTIFICATIONS.SEND.FORM.TITLE.LABEL' | translate }}
        <geaui-icon-v2
          file="16px_c-info"
          colorProp="stroke"
          color="blue"
          pTooltip="{{ 'NOTIFICATIONS.SEND.FORM.INFO.LABEL' | translate }}">
        </geaui-icon-v2>
      </div>
      <div
        class="message-container geaui-col-desktop-xl-start-7 geaui-col-desktop-md-4 geaui-row-desktop-md-start-2 geaui-row-desktop-md-start-2">
        <geaui-input-v2
          id="subject"
          [group]="form"
          [required]="true"
          [loading]="loading"
          [label]="'NOTIFICATIONS.SEND.FORM.SUBJECT.LABEL' | translate">
        </geaui-input-v2>
        <geaui-textfield-v2
          id="message"
          [group]="form"
          [required]="true"
          [loading]="loading"
          [label]="'NOTIFICATIONS.SEND.FORM.MESSAGE.LABEL' | translate">
        </geaui-textfield-v2>
      </div>

      <div class="gea-font-heading-20 title geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-6">
        {{ 'NOTIFICATIONS.SEND.LIST.TITLE.LABEL' | translate }}
      </div>

      <div class="geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-7">
        <geaui-table-v2
          [tableId]="TABLE_ID"
          [data]="data"
          [columnDefinitions]="columns"
          [totalRecords]="totalRecords"
          [loading]="loading"
          [selection]="this.form.value.organizations ?? []"
          (selectionChange)="changeSelection($event)"
          selectionMode="multiple">
        </geaui-table-v2>
      </div>
    </div>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2
          class="button geaui-col-desktop-md-start-right-3 geaui-col-desktop-md-3"
          [disabled]="this.form.invalid"
          [sending]="sending"
          (click)="sendNotification()">
          {{ 'NOTIFICATIONS.SEND.BUTTON.LABEL' | translate }}
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
