import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { extractRequestParams, FilterTableSettings, InviteableResponse, MembershipState } from '@gea/digital-ui-lib';
import { ListResponse, User } from '@gea/digital-ui-lib';
import {
  UserCreateRequest,
  UserPatchRequest,
  UserPortalListItem,
  UserResponse,
  UsersApiService,
  UsersList200Response,
  UsersListRequestParams,
} from '../../generated/api/v1';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  MULTI_SELECT_PARAMS: (keyof UsersListRequestParams)[] = ['userListItemStateContains', 'customerUserTypeContains'];

  constructor(private usersApiService: UsersApiService) {}

  getUser(userId: string, noMemberships = true): Observable<User> {
    return this.usersApiService.usersGet({ userId, noMemberships }).pipe(
      map((user: UserResponse) => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        customerUserType: user.customerUserType,
        phoneNumber: user.phoneNumber,
        title: user.title,
        language: user.language,
        country: user.country,
        contactId: user.contactId,
        timeZone: user.timeZone ?? { offset: '', zoneId: '' },
        optInMFA: user.optInMFA,
        enforcedMFA: user.enforcedMFA,
        memberships: user.memberships.map((m) => ({
          id: m.id.value,
          userId: user.id,
          inherited: m.inherited,
          customerNumber: m.organization.customerNumber,
          organizationId: m.organization.id,
          roleId: m.role.id,
          organizationName: m.organization.name,
          roleName: m.role.name,
          state: m.state as unknown as MembershipState,
        })),
      }))
    );
  }

  getAllUsersV2(filter: FilterTableSettings): Observable<UsersList200Response> {
    const mappings: { [key: string]: keyof UsersListRequestParams } = {
      email: 'emailContains',
      firstName: 'firstNameContains',
      lastName: 'lastNameContains',
      contactId: 'contactIdContains',
      userListItemState: 'userListItemStateContains',
      customerUserType: 'customerUserTypeContains',
    };

    const requestParams = extractRequestParams(filter, mappings, this.MULTI_SELECT_PARAMS);

    return this.usersApiService.usersList(requestParams);
  }

  mapUserList(response: UsersList200Response): ListResponse<User[]> {
    return {
      entryCount: response.entryCount,
      pageEntries: response.pageEntries.map((entry) => this.mapUserListItem(entry)),
    };
  }

  mapUserListItem(response: UserPortalListItem): User {
    return {
      id: response.id,
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      customerUserType: response.customerUserType,
      phoneNumber: '',
      title: '',
      language: '',
      country: '',
      timeZone: { offset: '', zoneId: '' },
      numberOfUserMemberships: response.numberOfUserMemberships,
      numberOfInvitations: response.numberOfInvitations,
      userListItemState: response.userListItemState,
    };
  }

  updateUser(userId: string, user: UserPatchRequest): Observable<unknown> {
    return this.usersApiService.usersPatch({ userId: userId, userPatchRequest: user });
  }

  adminUpdateUser(userId: string, user: UserPatchRequest): Observable<unknown> {
    return this.usersApiService.usersPatch({
      userId: userId,
      userPatchRequest: user,
    });
  }

  deleteUser(email: string): Observable<unknown> {
    return this.usersApiService.usersDelete({ email });
  }

  createUser(user: UserCreateRequest): Observable<unknown> {
    return this.usersApiService.usersCreate({ userCreateRequest: user });
  }

  isUserInvitable(email: string): Observable<InviteableResponse> {
    return this.usersApiService
      .usersList({ emailEquals: email })
      .pipe(map((userList) => ({ inviteable: !userList.entryCount, userId: '' })));
  }

  deleteSelf() {
    return this.usersApiService.usersDeleteOwn();
  }
}
