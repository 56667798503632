import { ColumnRendererComponent, IconModule, TableServiceV2 } from '@gea/digital-ui-lib';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EditRendererConfig } from '../../../model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'gea-id-edit-renderer',
  templateUrl: './edit-renderer.component.html',
  imports: [IconModule, TranslateModule, CommonModule],
  standalone: true,
})
export class EditRendererComponent<T> implements ColumnRendererComponent<T> {
  rowData?: T;
  columnKey?: string;
  config?: EditRendererConfig;
  tableId?: string;

  constructor(private tableService: TableServiceV2) {}

  onAction(event: Event) {
    if (this.tableId && this.config?.action) {
      this.tableService.publishAction(this.tableId, this.config.action, this.rowData);
      event.stopPropagation();
    }
  }
}
