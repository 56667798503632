import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ButtonV2Module,
  ColumnDefinition,
  ErrorHandlerV2Service, FilterTableSettings,
  PageContentLayoutComponent,
  PageFooterComponent,
  PageHeaderModule, ResourcesService,
  SelectOption,
  SelectV2Module, SnackbarService, TableServiceV2,
  TableV2Module,
  ToggleV2Module,
} from '@gea/digital-ui-lib';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  UserNotificationsSettings,
  UserNotificationSettingsApiService,
  UserNotificationsSettingsApplication,
  OrganizationNotificationType,
  AppIdValue,
  OrganizationService,
  OrganizationsList200Response, OrganizationListItemResponse,
} from '@gea-id/shared';
import { TranslateModule } from '@ngx-translate/core';
import { first, tap } from 'rxjs';
import {
  OrganizationsColumnDefinitionsService
} from '../organizations/services/organizations-column-definitions.service';
import { finalize } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-workspace-portal-notification-settings',
  standalone: true,
  templateUrl: './portal-notification-settings.component.html',
  styleUrl: './portal-notification-settings.component.scss',
  imports: [
    TranslateModule,
    PageContentLayoutComponent,
    PageHeaderModule,
    ToggleV2Module,
    PageFooterComponent,
    ButtonV2Module,
    SelectV2Module,
    TableV2Module,
  ],
})
export class PortalNotificationSettingsComponent implements OnInit {
  TABLE_ID = 'organization-notification-list';
  loading = false;
  loadingOrganizations = false;
  apps: SelectOption[] = [];
  settings: UserNotificationsSettings = {} as UserNotificationsSettings;
  selectedApp?: UserNotificationsSettingsApplication;
  tableData: OrganizationNotificationType[] = [];
  allAppsToggleState = false;
  selectedOrganizations: OrganizationNotificationType[] = [];
  columnDefinitions: ColumnDefinition[] = [];
  totalRecords = 0;

  formGroup = this.formBuilder.group({
    apps: new FormControl<SelectOption<string> | null>(null),
    channelEmail: new FormControl<boolean>(false),
  });

  constructor(
    private formBuilder: FormBuilder,
    private notificationSettingsApi: UserNotificationSettingsApiService,
    private errorhandlerService: ErrorHandlerV2Service,
    private organizationService: OrganizationService,
    private errorHandler: ErrorHandlerV2Service,
    private tableService: TableServiceV2,
    private organizationsColumnDefinitionsService: OrganizationsColumnDefinitionsService,
    private resourcesService: ResourcesService,
    private snackbar: SnackbarService,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.notificationSettingsApi.get().subscribe({
      next: (settings) => {
        this.loading = false;
        this.settings = settings;
        this.apps =
          this.settings.applications?.map((app) => ({
            nameKey: 'UI-LIB.NOTIFICATION-SETTINGS.APP.' + app.name,
            value: app.appId,
            disabled: app.notificationTypes.length === 0,
          })) ?? [];

        this.selectedOrganizations = this.settings.organizations?.map(
          (orgId) => ({
            id: orgId
          } as OrganizationNotificationType)
        ) ?? [];

        this.formGroup.patchValue({
          channelEmail: this.settings.channelEmail,
        });

        this.tableService.getFilterTableSettings(this.TABLE_ID)
          .pipe(takeUntilDestroyed(this._destroyRef))
          .subscribe((filter) => this.fetchOrganizations(filter, this.selectedOrganizations));
      },
      error: (error: ApiErrorResponse) => {
        this.errorhandlerService.handleError(error);
        this.loading = false;
      },
    });
  }

  selectApp(appId: SelectOption<AppIdValue>) {
    this.selectedApp = this.settings?.applications?.find((app) => app.appId === appId.value.value);
    const systemMaintenance = this.selectedApp?.notificationTypes?.find(
      (notificationType) => notificationType.name === 'SYSTEM_MAINTENANCE'
    );
    if (systemMaintenance) {
      systemMaintenance.active = true;
      systemMaintenance.disabled = true;
    }
  }

  toggleAllApps() {
    this.settings?.applications?.forEach((app) => {
      app.notificationTypes?.forEach((notificationType) => {
        if (!notificationType.disabled) {
          notificationType.active = this.allAppsToggleState;
        }
      });
    });
  }

  setAllAppsToggleState() {
    if (this.selectedApp) {
      const enabledNotificationTypes = this.selectedApp.notificationTypes.filter((nt) => !nt.disabled);

      this.allAppsToggleState = enabledNotificationTypes.every((nt) => nt.active);
    }
  }

  mapToTableData(organizations: OrganizationListItemResponse[], selectedOrganizations: OrganizationNotificationType[]): OrganizationNotificationType[] {
    return organizations.map((organization) => ({
      selected: selectedOrganizations.some(o => o.id == organization.orgaId),
      id: organization.orgaId,
      name: organization.name,
      type: organization.type,
      country: organization.address.country,
    }));
  }

  fetchOrganizations(filter: FilterTableSettings, organizationIds: OrganizationNotificationType[]) {
    this.loadingOrganizations = true;
    this.organizationService.getOrganizationsV2({ ...filter })
        .pipe(
            first(),
            tap(() => this.initializeColumnDefinitions()),
            finalize(() => this.loadingOrganizations = false),
        )
        .subscribe({
          next: (data: OrganizationsList200Response) => {
            this.tableData = this.mapToTableData(data.pageEntries, organizationIds);
            this.totalRecords = data.entryCount;
          },
          error: (error: ApiErrorResponse) => {
            this.errorHandler.handleError(error);
          }
        });
  }

  private initializeColumnDefinitions() {
    this.resourcesService
        .getCountries()
        .pipe(first())
        .subscribe((countries) => {
          this.columnDefinitions = this.organizationsColumnDefinitionsService.createColumnDefinitionsNotificationList(
              countries,
          );
        });
  }

  save() {
    this.settings.organizations = this.selectedOrganizations.map((org) => org.id);

    this.notificationSettingsApi.put(this.settings).subscribe({
      next: () => {
        this.snackbar.add({ severity: 'success', summary: 'X.MESSAGE.SUCCESS.SUMMARY', detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE' });
        this.formGroup.markAsPristine();
      },
      error: (e: ApiErrorResponse) => {
        this.errorhandlerService.handleError(e);
      },
    });
  }

  toggleChannelEmail($event: boolean) {
    this.settings.channelEmail = $event;
    this.formGroup.markAsDirty();
  }

  toggleChannelInApp($event: boolean) {
    this.settings.channelInApp = $event;
    this.formGroup.markAsDirty();
  }
}
