import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { NgxsModule } from '@ngxs/store';
import {
  ActionRequiredState,
  AppEnvironmentState,
  ButtonV2Module,
  ContentLayoutComponent,
  ComplexDialogV2Module,
  DialogV2Module,
  GeaTranslateHttpLoader,
  HeaderConfig,
  HeaderModule,
  IconModule,
  PageLayoutModule,
  PageHeaderModule,
  SelectV2Module,
  SharedModule,
  SidebarV2Module,
  SnackbarModule,
  TableV2Module,
  UserState,
  InputV2Module,
  ToggleV2Module,
  SearchBarV2Module,
  TabMenuV2Module,
  LinkV2Module,
  AutocompleteSelectV2Module,
  WizardV2Module,
  GeaMissingTranslationHandler,
  PageContentLayoutComponent,
  PageFooterComponent,
  PermissionsState,
  ApiService,
  TooltipV2Module,
  BasicGridModule,
  FeatureFlagConfigurationState,
} from '@gea/digital-ui-lib';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { AboutGeaComponent } from './about-gea/about-gea.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TemporaryMembershipComponent } from './temporary-membership';
import {
  AdministrationTasksComponent,
  AppnameRendererComponent,
  DatetimeRendererComponent,
  LatestDeploymentsComponent,
} from './administration-tasks';
import { FeatureFlagsComponent } from './feature-flags/list/feature-flags.component';
import { ApplicationsComponent } from './applications/list/applications.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgOptimizedImage } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccordionModule } from 'primeng/accordion';
import { UsersListPageComponent } from './users-list/users-list-page.component';
import { MembershipsListPageComponent } from './users-list/user-detail/memberships-list/memberships-list-page.component';
import { OrganizationsModule } from './organizations/organizations.module';
import { UserDetailComponent } from './users-list/user-detail/user-detail.component';
import { EditUserFormComponent } from './users-list/user-detail/edit-user-form/edit-user-form.component';
import { MembershipDialogComponent } from './users-list/user-detail/memberships-list/membership-dialog/membership-dialog.component';
import { UserHistoryListComponent } from './users-list/user-detail/user-history-list/user-history-list.component';
import { MultiLinesRendererComponent } from './users-list/user-detail/user-history-list/multi-lines-render/multi-lines-renderer.component';
import { CustomTriggerByRendererComponent } from './users-list/user-detail/user-history-list/custom-trigger-by-render/custom-trigger-by.component';
import { SendNotificationComponentModule } from './send-notification/send-notification.module';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { TooltipModule } from 'primeng/tooltip';
import { UserInviteComponent } from './users-list/user-invite/user-invite.component';
import { UserInviteFormComponent } from './users-list/user-invite/user-invite-form/user-invite-form.component';
import { UserInviteMembershipListComponent } from './users-list/user-invite/user-invite-membership-list/user-invite-membership-list.component';
import { UserInviteMembershipDialogComponent } from './users-list/user-invite/user-invite-membership-list/user-invite-membership-dialog/user-invite-membership-dialog.component';
import { DiscardChangesGuard, GeaIdConfiguration } from '@gea-id/shared';
import { GeaUserListComponent } from './gea-user-list/gea-user-list.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DefaultWidgetComponent } from './dashboard/default-widget/default-widget.component';
import { LearnMoreComponent } from './dashboard/learn-more/learn-more.component';
import { BaseWidgetComponent } from './dashboard/base-widget/base-widget.component';
import { DOCUMENT_SERVICE_BASE_URL } from './organizations/organization-detail/services/sharepoint-site.model';

export function HttpLoaderFactory(http: HttpClient, environment: EnvironmentConfiguration) {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, ['i18n/1/admin/']);
}

export const HEADER_CONFIG = new InjectionToken<HeaderConfig>('HeaderConfig');

@NgModule({
  declarations: [
    DefaultWidgetComponent,
    LearnMoreComponent,
    AboutGeaComponent,
    AdministrationTasksComponent,
    AppComponent,
    AppnameRendererComponent,
    DashboardComponent,
    DatetimeRendererComponent,
    LatestDeploymentsComponent,
    TemporaryMembershipComponent,
    FeatureFlagsComponent,
    UsersListPageComponent,
    MembershipsListPageComponent,
    UserDetailComponent,
    EditUserFormComponent,
    MembershipDialogComponent,
    UserHistoryListComponent,
    MultiLinesRendererComponent,
    CustomTriggerByRendererComponent,
    UserInviteComponent,
    UserInviteFormComponent,
    UserInviteMembershipListComponent,
    UserInviteMembershipDialogComponent,
    GeaUserListComponent,
    ApplicationsComponent,
    BaseWidgetComponent,
  ],
  imports: [
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState, PermissionsState, FeatureFlagConfigurationState], {
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HeaderModule.forRoot(HEADER_CONFIG),
    SharedModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      useDefaultLang: false,
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: GeaMissingTranslationHandler },
    }),
    TableV2Module,
    SelectV2Module,
    ButtonV2Module,
    DialogV2Module,
    DropdownModule,
    BasicGridModule,
    FormsModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    DividerModule,
    FieldsetModule,
    SnackbarModule,
    PageLayoutModule,
    SidebarV2Module,
    AccordionModule,
    IconModule,
    ContentLayoutComponent,
    OrganizationsModule,
    PageHeaderModule,
    ReactiveFormsModule,
    InputV2Module,
    ToggleV2Module,
    SearchBarV2Module,
    TabMenuV2Module,
    SnackbarModule,
    ComplexDialogV2Module,
    LinkV2Module,
    AutocompleteSelectV2Module,
    TooltipModule,
    TooltipV2Module,
    SendNotificationComponentModule,
    WizardV2Module,
    DialogV2Module,
    PageContentLayoutComponent,
    PageFooterComponent,
    ProgressSpinnerModule,
    TooltipV2Module,
  ],
  providers: [
    {
      provide: 'baseUrl',
      useFactory: (env: EnvironmentConfiguration) => env.portal.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'backendInfoUrl',
      useFactory: (env: EnvironmentConfiguration) => (env.portal.baseURL ?? env.baseURL) + 'geaid/v1/actuator/info',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'registrationBaseURL',
      useFactory: (env: EnvironmentConfiguration) => env.registrationBaseURL ?? env.baseURL + 'registration-service/v1/',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'auditBaseURL',
      useFactory: (env: EnvironmentConfiguration) => env.portal.auditBaseURL ?? env.baseURL + 'audit-service/v1/',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'iconPath',
      useFactory: (env: EnvironmentConfiguration) => env.iconStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'appContentPath',
      useFactory: (env: EnvironmentConfiguration) => env.appContentStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: DOCUMENT_SERVICE_BASE_URL,
      useFactory: (env: EnvironmentConfiguration) => env.documentServiceBaseUrl,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'storageURL',
      useFactory: (env: EnvironmentConfiguration) => env.localizationStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: GeaIdConfiguration,
      useFactory: (auth: ApiService, baseUrl: string) =>
        new GeaIdConfiguration({
          basePath: baseUrl + 'geaid/v1',
          accessToken: auth.getAccessToken.bind(auth),
        }),
      deps: [ApiService, 'baseUrl'],
      multi: false,
    },
    { provide: DialogService },
    { provide: DynamicDialogModule },
    { provide: DynamicDialogConfig },
    { provide: DynamicDialogRef },
    { provide: 'portalPath', useValue: 'portal-admin' },
    provideHttpClient(),
    TranslatePipe,
    DiscardChangesGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [UsersListPageComponent, MembershipsListPageComponent, UserDetailComponent],
})
export class AppModule {}
