import { Component, Input } from '@angular/core';

@Component({
  selector: 'gea-id-workspace-default-widget',
  templateUrl: './default-widget.component.html',
  styleUrls: ['./default-widget.component.scss'],
})
export class DefaultWidgetComponent {
  @Input({ required: true }) editModeActive!: boolean;
  @Input() widgetId!: string;
  // callback for opening learn-more dialog
  @Input() widgetCallback?: () => void;

  openLearnMore() {
    if (this.widgetCallback) {
      this.widgetCallback();
    }
  }
}
