import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { concatMap, filter, tap } from 'rxjs';

import { AdminScopeService } from './services';
import {
  AboutPanelComponent,
  AboutPanelInputs,
  ApiErrorResponse,
  CspData,
  Environment as EnvironmentEnum,
  ErrorHandlerV2Service,
  LayoutService,
  NavigationItem,
  PermissionKey,
  PermissionsState,
  SetEnvironment,
  SidePanel,
  SidepanelTypes,
  UserModel,
  UserState,
} from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { CultureService } from '@gea-id/shared';
import { first, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'gea-id-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private navIcons = {
    dashboard: 'apps',
    user: 'multiple',
    customerUser: 'multiple',
    geaUser: 'pin-4',
    organization: 'filter-organization',
    customer: 'group',
    temporaryMembership: 'enter',
    administrationTasks: 'list',
    featureFlagsAssignment: 'flag',
    contact: 'chat',
    about: 'c-info',
    version: 'new-notification',
    notifications: 'bell',
    applications: 'box-2',
  };

  tokenReady = false;
  userReady = false;
  noAuth?: boolean;
  hasAppRegistrationScope = false;
  loggedOutPageReady = false;

  navItems: NavigationItem[] = [];
  botItems: SidePanel[] = [];

  currentLanguage = '';
  userCountry = 'US';

  constructor(
    private translate: TranslateService,
    private adminScope: AdminScopeService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    public layout: LayoutService,
    public store: Store,
    private errorHandler: ErrorHandlerV2Service,
    private cultureService: CultureService,
    private destroyed: DestroyRef,
    private apiService: HttpClient,
    private meta: Meta,
    private http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.noAuth = event.url === '/about-gea';
    });
  }

  ngOnInit(): void {
    this.setContentSecurityMetaTag();
    this.store.select(UserState.tokenObject).subscribe((token) => {
      const scopes = token?.extension_admin_scopes;
      if (scopes) {
        this.hasAppRegistrationScope = Object.values(scopes).some((entry) => entry.name === 'Admin_Manage_AppRegistration');
      }
    });
    this.store
      .select(UserState.loggedOutPageReady)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((ready) => {
        this.loggedOutPageReady = !!ready;
      });
    this.store
      .select(UserState.token)
      .pipe(
        filter((token) => !!token),
        first(),
        tap(() => {
          this.tokenReady = true;
          // call admin scope service
          this.adminScope.syncAdGroups().subscribe({
            error: (error: ApiErrorResponse) => {
              this.errorHandler.handleError(error);
            },
          });
        }),
        // This is the official syntax of ngxs
        // eslint-disable-next-line @typescript-eslint/unbound-method,@typescript-eslint/no-unsafe-argument
        concatMap(() => this.store.select<UserModel>(UserState.user)),
        filter((user) => !!user.email)
      )
      .subscribe((user) => {
        if (user?.country) {
          this.userCountry = user.country;
        }

        this.translate.get('GENERAL.TITLE').subscribe((appTitle: string) => {
          this.title.setTitle(appTitle);
        });
        this.userReady = true;
        this.cultureService.fetchData();
      });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.store.dispatch(new SetEnvironment(this.environment.name));
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
      this.botItems = [
        {
          key: 'version',
          icon: this.navIcons.version,
          label: 'UI-LIB.NAV-BAR.VERSION',
          type: SidepanelTypes.CARD,
          card: 'Version not yet implemented',
        },
        {
          key: 'contact',
          icon: this.navIcons.contact,
          label: 'UI-LIB.NAV-BAR.CONTACT',
          type: SidepanelTypes.CONTACT,
          supportAppBaseUrl: this.environment.support.redirectURL,
        },
        {
          key: 'about',
          icon: this.navIcons.about,
          label: 'UI-LIB.NAV-BAR.ABOUT',
          type: SidepanelTypes.FOOTER,
          ref: AboutPanelComponent,
          inputs: {
            copyright: 'GEA Group Services GmbH 2023',
            footers: [
              {
                key: 'imprint-header',
                header: 'UI-LIB.FOOTER.IMPRINT',
                content: [
                  {
                    key: 'imprint',
                    icon: 'pdf',
                    label: 'UI-LIB.FOOTER.IMPRINT',
                    action: () => this.openImprint(),
                  },
                ],
              },
              {
                key: 'privacy-header',
                header: 'UI-LIB.FOOTER.DATAPRIVACY',
                content: [
                  {
                    key: 'privacy',
                    icon: 'pdf',
                    label: 'UI-LIB.FOOTER.DATAPRIVACY',
                    action: () => this.openDataPrivacy(),
                  },
                ],
              },
              {
                key: 'terms-header',
                header: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                content: [
                  {
                    key: 'terms',
                    icon: 'pdf',
                    label: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                    action: () => this.openTermsAndConditions(),
                  },
                ],
              },
              {
                key: 'cookie-settings',
                header: 'UI-LIB.FOOTER.COOKIE-SETTINGS',
                content: [
                  {
                    key: 'cookies',
                    label: 'UI-LIB.FOOTER.COOKIES',
                    action: () => this.openCookieSettings(),
                  },
                ],
              },
            ],
          } as AboutPanelInputs,
        },
      ];
    });
    this.translate.setDefaultLang('en-US');
    this.addCookielawScriptTag();

    this.store
      .select(PermissionsState.userPermissions)
      .pipe(map((permissions) => this.createNavItems(permissions)))
      .subscribe((navitems) => (this.navItems = navitems));

    this.setLanguage();
  }

  addCookielawScriptTag() {
    const cookielawId = this.environment.cookielawId;
    if (cookielawId) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', cookielawId);
      const head = document.head;
      const firstScriptTag = head.querySelector('script');
      if (firstScriptTag) {
        head.insertBefore(script, firstScriptTag);
      } else {
        head.appendChild(script);
      }
    }
  }

  isAboutGEARoute() {
    return this.activatedRoute?.firstChild?.snapshot.url[0].path === 'about-gea';
  }

  private getLanguagePath(currentLang: string = this.currentLanguage) {
    const lang = currentLang.includes('-') ? currentLang.split('-')[0] : currentLang;
    return this.environment.storageURL + 'terms/1/' + this.userCountry + '/' + lang.toUpperCase();
  }

  openImprint() {
    this.openWindow(
      this.environment.storageURL + 'imprint/Imprint' + this.currentLanguage + '.pdf',
      this.environment.storageURL + 'imprint/Imprinten-US.pdf'
    );
  }

  openDataPrivacy() {
    this.openWindow(this.getLanguagePath() + '/DataPrivacy.pdf', this.getLanguagePath('EN') + '/DataPrivacy.pdf');
  }

  openTermsAndConditions() {
    this.openWindow(this.getLanguagePath() + '/TermsAndConditions.pdf', this.getLanguagePath('EN') + '/TermsAndConditions.pdf');
  }

  openCookieSettings() {
    // this syntax is necessary here to access onetrust
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.OneTrust.ToggleInfoDisplay();
  }

  private openWindow(url: string, fallback: string) {
    const newWindow = window.open('', '_blank') as Window;
    this.apiService.get(url, { responseType: 'blob' }).subscribe({
      next: () => {
        newWindow.location.href = url;
      },
      error: () => {
        newWindow.location.href = fallback;
      },
    });
  }

  private setLanguage() {
    this.cultureService.resourceData$
      .pipe(
        map((data) => data?.languageOptions),
        filter((languageOptions) => !!languageOptions),
        map((languageOptions) => languageOptions.map((language) => language.value))
      )
      .subscribe((availableLanguages) => {
        const browserLanguage = navigator.languages.find((lang) => availableLanguages.includes(lang));
        this.translate.use(browserLanguage ?? 'en-US');
      });
  }

  private createNavItems(permissions: PermissionKey[]) {
    return [
      {
        label: 'GENERAL.DASHBOARD',
        route: '/dashboard',
        icon: this.navIcons.dashboard,
      },
      {
        label: 'GENERAL.CUSTOMER-USERS',
        route: '/user',
        icon: this.navIcons.customerUser,
      },
      {
        label: 'GENERAL.GEA-USERS',
        route: '/gea-user',
        icon: this.navIcons.geaUser,
      },
      {
        label: 'GENERAL.ORGANIZATIONS',
        route: '/organization',
        icon: this.navIcons.organization,
      },
      {
        label: 'GENERAL.CUSTOMERS',
        route: '/mdg-customer',
        icon: this.navIcons.customer,
      },
      {
        label: 'TEMP_MEMBERSHIP.TAB_TITLE',
        route: '/temporary-membership',
        icon: this.navIcons.temporaryMembership,
      },
      {
        label: 'ADMINISTRATION_TASKS.TAB_TITLE',
        route: '/administration-tasks',
        icon: this.navIcons.administrationTasks,
        disabled: !permissions.includes(PermissionKey.DEPLOY_TRANSLATIONS),
      },
      {
        label: 'FEATURE_FLAGS_.TAB_TITLE',
        route: '/feature-flags-assignment',
        icon: this.navIcons.featureFlagsAssignment,
      },
      {
        label: 'GENERAL.SEND_NOTIFICATION',
        route: '/send-notification',
        icon: this.navIcons.notifications,
        disabled: !permissions.includes(PermissionKey.CONTACT_USER_WITH_NOTIFICATION),
      },
      {
        label: 'PORTAL_NOTIFICATION_SETTINGS.TAB_TITLE',
        route: '/portal-notification-settings',
        icon: this.navIcons.applications,
      },
      {
        label: 'APPLICATIONS_REGISTRATION.TAB_TITLE',
        route: '/applications',
        icon: this.navIcons.applications,
        disabled: !this.hasAppRegistrationScope,
      },
    ];
  }

  private setContentSecurityMetaTag() {
    this.getCspData().subscribe((data) => {
      if (this.environment.name !== EnvironmentEnum.LOCAL) return;
      if (!this.environment.name) return;

      /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
      const clarityHash = data.clarityHash;
      const gtm_hash = data.gtmHash;
      const cdnUrl = data.cdnUrl;
      const gtmUrl = data.gtmUrl;
      const gaUrl = data.gaUrl;
      const pdfWorkerUrl = data.pdfWorkerUrl;
      const clarityUrl = data.clarityUrl;
      /* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
      const storageUrls: { [key: string]: string } = {
        dev: 'https://content.apps.dev.gea.com/',
        test: 'https://content.apps.tst.gea.com/',
        uat: 'https://content.apps.uat.gea.com/',
        prod: 'https://content.apps.gea.com/',
      };
      const stage = this.environment.name === EnvironmentEnum.LOCAL ? 'dev' : this.environment.name;
      const allowedScriptSources = `
    ${clarityHash} ${gtm_hash} 
      ${cdnUrl} ${gtmUrl} ${gaUrl} ${pdfWorkerUrl} 
      ${clarityUrl} ${storageUrls[stage]} https://strgaccassets${stage}.blob.core.windows.net/strg-container-assets-${stage}/clarity-loader.js
    `;
      this.meta.addTag({
        'http-equiv': 'Content-Security-Policy',
        content: `script-src 'self' ${allowedScriptSources}; worker-src blob:;`,
      });
      this.meta.addTag({
        'http-equiv': 'Cache-control',
        content: `private, max-age=1800`,
      });
    });
  }

  private getCspData() {
    return this.http.get<CspData>("../assets/csp-header-data.json");
  }
}
