<section class="status-container" *ngIf="membershipData">
  <div class="status">
    <span>Status</span>
    <div class="status-icon padding-y">
      <geaui-icon-v2 class="icon" [file]="getIconFile()" [color]="'black'"></geaui-icon-v2>
      <span class="status-text">{{ membershipStateTranslateKey | translate }}</span>
    </div>
  </div>
  <div class="status margin-x">
    <span>{{ 'USERS.USER_DETAIL.DIALOG.INVITED_ON' | translate }}</span>
    <span class="padding-y">{{ membershipData.createdAt | date: 'medium' : '' : translateService.currentLang }}</span>
  </div>
</section>
<form class="memberships-dialog-controls" [formGroup]="form">
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="'organisation'"
      [label]="'USERS.USER_DETAIL.DIALOG.ORGANISATION'"
      [loading]="loading"
      [emptyMessageKey]="emptyMessageKey"
      [preFilter]="fetchOrgas"
      [options]="organisationOptions"
      (selectChanged)="onSelectOrga($event)">
    </geaui-autocomplete-select-v2>
  </div>
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="'role'"
      [label]="'USERS.USER_DETAIL.DIALOG.ROLE'"
      [loading]="loading"
      [options]="roleOptions">
    </geaui-autocomplete-select-v2>
  </div>
  @if (membershipData) {
    <div class="invitation-btn-container">
      @if (membershipData.state !== MembershipState.ACCEPTED && updatePermission) {
        <span
          ><geaui-link-v2 [loading]="loading || invitationUpdating" (click)="onResendInvitation()" preIcon="reset-1">{{
            'USERS.USER_DETAIL.DIALOG.RESEND' | translate
          }}</geaui-link-v2></span
        >
        <span
          ><geaui-link-v2 [loading]="loading || invitationUpdating" (click)="onCancelInvitation()" preIcon="cancel-invite">{{
            'USERS.USER_DETAIL.DIALOG.CANCEL' | translate
          }}</geaui-link-v2></span
        >
      } @else {
        <span
          ><geaui-link-v2
            [preIcon]="'trash-can'"
            [type]="'cancel-red'"
            [loading]="loading || invitationUpdating"
            (click)="showRemoveMembershipDialog()"
            >{{ 'X.BUTTON.DELETE' | translate }}</geaui-link-v2
          >
        </span>
      }
    </div>
  }
  @if (hasNoOwnerRole || ownerRoleReassigned) {
    <div class="info-box">
      <geaui-icon-v2 class="icon" file="16px_c-info" color="black"></geaui-icon-v2>
      <span class="info-text">{{
        (ownerRoleReassigned ? 'MEMBERSHIPS.DIALOG.OWNER_ROLE_REASSIGN' : 'MEMBERSHIPS.DIALOG.OWNER_ROLE_MISSING') | translate
      }}</span>
    </div>
  }
</form>
