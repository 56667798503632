<div class="about-gea-content">
  <div class="image-content"></div>
  <div class="text-content">
    <p class="heading">Lorem Ipsum</p>
    <div class="paragraph-content">
      <p>
        Praesent interdum dui non elit dapibus luctus. In at tortor egestas, sollicitudin ligula sit amet, porta leo. Nam bibendum
        cursus justo a euismod. Vestibulum gravida, nunc vel finibus posuere, neque purus viverra nunc, ac eleifend leo ipsum
        euismod lacus. In id lacinia tortor. Cras rhoncus, urna vel consequat porttitor, erat urna placerat augue, ac ornare nisl
        lectus eu magna. Etiam feugiat laoreet neque, ut pellentesque nunc vulputate interdum. Aliquam erat volutpat. Etiam
        sagittis sapien odio, at facilisis massa gravida placerat. Sed fermentum dapibus lacus nec lobortis. Curabitur aliquet
        pulvinar arcu vitae dignissim. Donec gravida est a est lobortis molestie. Ut mollis id felis at interdum. Sed at imperdiet
        diam. Vivamus aliquam aliquam mi at tincidunt.
      </p>
      <p>
        Aenean blandit, orci vitae blandit consectetur, quam augue mollis lacus, sit amet lobortis purus augue et libero. Morbi
        vitae fermentum mi, a facilisis nisi. Duis quis imperdiet ex. Mauris in nisl et felis tempor ornare. Nam fermentum eros
        sit amet varius ornare. Sed egestas, nibh non molestie imperdiet, mi mauris gravida enim, et vehicula orci mi sit amet
        enim. Curabitur vitae viverra purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
        curae; Ut aliquet sollicitudin nisi, nec sodales sapien blandit sed. Morbi ultricies velit eget aliquet ultricies. Nullam
        ligula ipsum, viverra vel sodales nec, condimentum sed odio. Suspendisse pulvinar efficitur enim, at commodo metus
        condimentum quis.
      </p>
    </div>
  </div>
</div>
