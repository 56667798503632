import { AbstractControl, ValidatorFn } from '@angular/forms';

export class GeaEmailValidator {
  public static email(): ValidatorFn {
    const regexEmail = /^(?!.*@.*\..+).*$/;
    const regexEmployee = /@(adesso\.de|gea\.com)$/;

    return (control: AbstractControl<string>) => {
      if (!control.value) {
        return null;
      }

      if (regexEmail.test(control.value)) {
        return { ['email']: true };
      }

      if (regexEmployee.test(control.value)) {
        return { ['employee']: true };
      }

      return null;
    };
  }
}
