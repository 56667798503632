export interface History {
  id: string;
  eventType: string;
  triggeredBy: Author;
  eventDate: string;
  changes: string[];
  entity: string;
  entityId: string;
}

export interface Author {
  authorId: string;
  authorType: string;
  givenName: string;
  familyName: string;
}
