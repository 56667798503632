import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendNotificationComponent } from './send-notification.component';
import {
  ButtonV2Module,
  ContentLayoutComponent,
  FieldErrorModule,
  IconModule,
  InputV2Module,
  PageContentLayoutComponent,
  PageFooterComponent,
  PageHeaderModule,
  TableV2Module,
  TextfieldV2Module,
} from '@gea/digital-ui-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ContentLayoutComponent,
    ButtonV2Module,
    TableV2Module,
    InputV2Module,
    ReactiveFormsModule,
    TextfieldV2Module,
    FieldErrorModule,
    TranslateModule,
    DividerModule,
    IconModule,
    TooltipModule,
    PageContentLayoutComponent,
    PageFooterComponent,
    PageHeaderModule,
  ],
  declarations: [SendNotificationComponent],
  exports: [SendNotificationComponent],
})
export class SendNotificationComponentModule {}
