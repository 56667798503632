type UserNotificationsSettingsActiveDto = {
  id: string;
  name: string;
  active: boolean;
  disabled?: boolean;
};

declare type UserNotificationsSettingsNotificationType = UserNotificationsSettingsActiveDto & {
  notificationTypeId: string;
};

declare type UserNotificationsSettingsProduct = UserNotificationsSettingsActiveDto & {
  productId: string;
};

export interface UserNotificationsSettingsApplication {
  id: string;
  name: string;
  appId: string;
  notificationTypes: UserNotificationsSettingsNotificationType[];
}

export interface UserNotificationsSettings {
  id: string;
  channelEmail: boolean;
  channelInApp: boolean;
  applications?: UserNotificationsSettingsApplication[];
  products?: UserNotificationsSettingsProduct[];
  organizations?: string[];
}

export interface AppIdValue {
  name: string;
  nameKey: string;
  value: string;
  disabled: boolean;
}

export interface OrganizationNotificationType {
  selected: boolean;
  id: string;
  name: string;
  type: string;
  country: string;
}
