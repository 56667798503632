import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { HistoryService, History } from '../../../services';
import { ApiErrorResponse, ErrorHandlerV2Service, FilterTableSettings, TableServiceV2 } from '@gea/digital-ui-lib';
import { finalize } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { organizationsHistoryColumnDefinition } from '../../models/organizations-history-column.config';

@Component({
  selector: 'gea-id-organization-history',
  templateUrl: './organization-history.component.html',
  styleUrl: './organization-history.component.scss',
})
export class OrganizationHistoryComponent implements OnInit {
  public readonly TABLE_ID = 'organization-audit-history-table';
  public historyData: History[] = [];
  public totalRecords = 0;
  public columnDefinitions = organizationsHistoryColumnDefinition;
  public loading = false;
  @Input() private orgaId = '';

  constructor(
    private errorHandlerV2Service: ErrorHandlerV2Service,
    private tableService: TableServiceV2,
    private destroyRef: DestroyRef,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((filter: FilterTableSettings) => {
        this.fetchData(filter);
      });
  }

  fetchData(tableFilter: FilterTableSettings) {
    this.loading = true;
    this.historyService
      .getOrganizationHistory(tableFilter, this.orgaId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (result) => {
          this.historyData = result.pageEntries;
          this.totalRecords = result.entryCount;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerV2Service.handleError(error);
          this.historyData = [];
          this.totalRecords = 0;
        },
      });
  }
}
