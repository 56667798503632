import { Component, Inject, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { first, Observable, of, switchMap, tap } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IFeatureFlagAssignment, NewFeatureFlagAssignment } from '../models/feature-flag-assignment.model';
import { IFeatureFlag } from '../models/feature-flag.model';
import { FeatureFlagApiService } from '../services/feature-flag-api-service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DividerModule } from 'primeng/divider';
import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { AssignmentTypeModel } from '../models/assignment-type.model';
import { TranslateModule } from '@ngx-translate/core';
import {
  AutocompleteSelectV2Module,
  ButtonV2Module,
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  LinkV2Module,
  ListResponse,
  ResourcesService,
  SelectOption,
  SnackbarService,
  SortDirection,
} from '@gea/digital-ui-lib';
import { FeatureFlagService } from '../services/feature-flag.service';
import { AdministratorsApiService, AdministratorsListRequestParams, OrganizationService, UserService } from '@gea-id/shared';

@Component({
  standalone: true,
  selector: 'gea-id-feature-flag-modal',
  templateUrl: './feature-flag-modal.component.html',
  styleUrls: ['./feature-flag-modal.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    DividerModule,
    NgIf,
    NgClass,
    JsonPipe,
    ButtonV2Module,
    TranslateModule,
    LinkV2Module,
    AsyncPipe,
    AutocompleteSelectV2Module,
  ],
})
export class FeatureFlagModalComponent implements OnInit, ComplexDialogEmbeddedView {
  protected loading = true;
  protected emptyMessageKey?: string;
  protected readonly AssignmentTypeModel = AssignmentTypeModel;

  protected featureFlagSharedCollection: SelectOption<string>[] = [];

  protected countries: SelectOption<string>[] = [];
  protected usersOptions: SelectOption<string>[] = [];
  protected adminOptions: SelectOption<string>[] = [];
  protected organizationsOptions: SelectOption<string>[] = [];

  protected form = this.fb.group({
    featureFlag: ['', Validators.required],
    assignmentType: ['', Validators.required],
    assignedTo: ['', Validators.required],
  });

  protected isEditing = !!this.assignment;

  constructor(
    protected featureFlagApiService: FeatureFlagApiService,
    private featureFlagService: FeatureFlagService,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private resourcesService: ResourcesService,
    private userService: UserService,
    private adminService: AdministratorsApiService,
    private confirmDialog: DialogV2Service,
    private complexDialogService: ComplexDialogV2Service,
    private snackbarService: SnackbarService,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) private assignment?: IFeatureFlagAssignment
  ) {}

  filteredAssignmentType: SelectOption[] = Object.keys(AssignmentTypeModel)
    .filter((key) => (key as AssignmentTypeModel) !== AssignmentTypeModel.ORGANIZATION_TYPE)
    .map((key) => ({
      nameKey: `X.ASSIGNMENT_TYPE.${key}`,
      value: key,
    }));

  ngOnInit(): void {
    if (this.assignment) {
      this.form.patchValue({
        featureFlag: this.assignment?.featureFlag?.id,
        assignmentType: this.assignment?.assignmentType,
        assignedTo: this.assignment?.assignedTo,
      });

      switch (this.assignment.assignmentType) {
        case AssignmentTypeModel.ORGANIZATION:
          this.fetchOrganization(this.assignment.assignedToLabel, this.organizationsOptions).pipe(first()).subscribe();
          break;
        case AssignmentTypeModel.USER:
          this.fetchUser(this.assignment.assignedToLabel, this.usersOptions).pipe(first()).subscribe();
          break;
        case AssignmentTypeModel.ADMIN:
          this.fetchAdmin(this.assignment.assignedToLabel, this.adminOptions).pipe(first()).subscribe();
          break;
      }
    }

    this.form.controls.assignmentType.valueChanges.subscribe(() => {
      this.onAssignmentTypeChange();
    });

    this.resourcesService.getCountries().subscribe((countries) => {
      this.countries = countries;
    });
    this.fetchRelationshipsOptions('').subscribe((featureFlags) => {
      this.featureFlagSharedCollection = featureFlags;
    });
  }

  private onAssignmentTypeChange() {
    this.form.controls.assignedTo.patchValue('');
    this.organizationsOptions = [];
    this.usersOptions = [];
    this.adminOptions = [];
  }

  protected fetchRelationshipsOptions(query: string) {
    let params: HttpParams = new HttpParams();
    params = params.append(`displayName`, query);
    params = params.append(`page`, 0);
    params = params.append(`size`, 1000);
    params = params.append('sort', 'displayName'.concat(',').concat('asc')); // TODO align with other sortings style request
    return of(null).pipe(
      tap(() => (this.loading = true)),
      switchMap(() =>
        this.featureFlagApiService
          .getAllFeatureFlags(params)
          .pipe(map((res: ListResponse<IFeatureFlag[]>) => res.pageEntries))
          .pipe(
            map((featureFlags: IFeatureFlag[]) =>
              featureFlags.map(
                (featureFlag) =>
                  ({
                    value: featureFlag.id,
                    name: featureFlag.displayName,
                  }) as SelectOption<string>
              )
            )
          )
      ),
      tap(() => (this.loading = false))
    );
  }

  fetchUser = (query: string, options: SelectOption<string>[]): Observable<SelectOption<string>[]> => {
    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return of(null).pipe(
      switchMap(() =>
        this.userService.getAllUsersV2({
          columns: {
            email: {
              filter: [query],
              sort: SortDirection.ASCENDING,
            },
          },
          page: 0,
          pageSize: 20,
        })
      ),
      map((response) => response.pageEntries),
      map((users) =>
        users.map(
          (user) =>
            ({
              value: user.id,
              name: user.email,
            }) as SelectOption<string>
        )
      ),
      tap((options) => {
        this.emptyMessageKey = undefined;
        this.usersOptions = options;
      })
    );
  };

  fetchAdmin = (query: string, options: SelectOption<string>[]): Observable<SelectOption<string>[]> => {
    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return of(null).pipe(
      switchMap(() =>
        this.adminService.administratorsList({
          emailContains: query,
          sortBy: 'email',
          sortDir: SortDirection.ASCENDING,
          page: '0',
          pageSize: '20',
        } as AdministratorsListRequestParams)
      ),
      map((response) => response.pageEntries),
      map((admins) => admins.filter((value, index, arr) => index === arr.findIndex((t) => t.id === value.id))),
      map((admins) =>
        admins.map(
          (admin) =>
            ({
              value: admin.id,
              name: admin.email,
            }) as SelectOption<string>
        )
      ),
      tap((options) => {
        this.emptyMessageKey = undefined;
        this.adminOptions = options;
      })
    );
  };

  fetchOrganization = (query: string, options: SelectOption<string>[]): Observable<SelectOption<string>[]> => {
    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return of(null).pipe(
      switchMap(() =>
        this.organizationService.getOrganizationsV2({
          columns: {
            name: {
              filter: [query],
              sort: SortDirection.ASCENDING,
            },
          },
          page: 0,
          pageSize: 20,
        })
      ),
      map((response) => response.pageEntries),
      map((orgas) =>
        orgas.map(
          (orga) =>
            ({
              name: orga.name,
              value: orga.orgaId,
            }) as SelectOption<string>
        )
      ),
      tap((options) => {
        this.emptyMessageKey = undefined;
        this.organizationsOptions = options;
      })
    );
  };

  get isAcceptDisabled() {
    return this.form.invalid || this.loading;
  }

  onAcceptClick() {
    const request: NewFeatureFlagAssignment = {
      featureFlag: {
        id: this.form.value.featureFlag ?? '',
        displayName:
          this.featureFlagSharedCollection.find((option) => this.form.value.featureFlag === option.value)?.name ?? 'test',
      },
      assignmentType: this.form.value.assignmentType as AssignmentTypeModel,
      assignedTo: this.form.value.assignedTo ?? '',
      assignedToLabel: this.findAssignedTo().name ?? '',
    };
    this.complexDialogService.emitDataOutputForComponent(request);
  }

  delete() {
    this.complexDialogService.close();
    this.confirmDialog.open({
      title: 'X.BUTTON.DELETE',
      message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => {
        return this.remove();
      },
    });
  }

  private remove(): Observable<void> {
    if (!this.assignment) {
      throw new Error('No assignment provided');
    }

    return this.featureFlagService.delete(this.assignment.id).pipe(
      finalize(() => this.confirmDialog.close()),
      tap({
        next: () => {
          this.snackbarService.add({
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            severity: 'success',
          });
        },
      }),
      map(() => void 0)
    ); // Expects a void
  }

  findAssignedTo() {
    const assignedTo = (() => {
      switch (this.form.value.assignmentType as AssignmentTypeModel) {
        case AssignmentTypeModel.COUNTRY:
          return this.countries.find((country) => country.value === this.form.value.assignedTo);
        case AssignmentTypeModel.ORGANIZATION:
          return this.organizationsOptions.find((organization) => organization.value === this.form.value.assignedTo);
        case AssignmentTypeModel.USER:
          return this.usersOptions.find((user) => user.value === this.form.value.assignedTo);
        case AssignmentTypeModel.ADMIN:
          return this.adminOptions.find((admin) => admin.value === this.form.value.assignedTo);
        default:
          throw new Error(`Unknown assignment type: ${this.form.value.assignmentType}`);
      }
    })();
    if (!assignedTo) throw new Error('AssignedTo not found in options');
    return assignedTo;
  }
}
