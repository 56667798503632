import {
  appendVisibilitySettings,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  DateRendererConfig,
  TextFilterComponent,
} from '@gea/digital-ui-lib';
import { MultiLinesRendererComponent } from '../../users-list/user-detail/user-history-list/multi-lines-render/multi-lines-renderer.component';

export const organizationsHistoryColumnDefinition: ColumnDefinition[] = appendVisibilitySettings([
  {
    displayName: 'ORGANIZATION.DETAILS.HISTORY.LIST.TYPE.LABEL',
    key: 'eventType',
    sortable: true,
    filter: {
      component: TextFilterComponent,
    },
  },
  {
    displayName: 'ORGANIZATION.DETAILS.HISTORY.LIST.NEW_VALUE.LABEL',
    key: 'changes',
    sortable: true,
    renderer: {
      component: MultiLinesRendererComponent as ColumnRendererComponent<unknown>,
    },
  },
  {
    displayName: 'ORGANIZATION.DETAILS.HISTORY.LIST.TRIGGERED_BY.LABEL',
    key: 'triggeredBy.email',
    sortable: true,
    filter: {
      component: TextFilterComponent,
    },
  },
  {
    displayName: 'ORGANIZATION.DETAILS.HISTORY.LIST.EVENT_DATE.LABEL',
    key: 'eventDate',
    sortable: true,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'dd.MM.yyyy HH:mm:ss',
      } as DateRendererConfig,
    },
  },
]);
