import { ColumnDefinition, ColumnRendererComponent, Membership, TranslationRendererComponent } from '@gea/digital-ui-lib';
import { EditRendererComponent } from '@gea-id/shared';

export const MEMBERSHIP_EDIT_ACTION = 'membership-list-edit';

export function membershipsColumnDefinitions(editable: boolean): ColumnDefinition[] {
  return [
    {
      displayName: 'X.LABEL.ORGANISATION',
      key: 'organizationName',
      sortable: false,
      width: 500,
    },
    {
      displayName: 'X.LABEL.ROLE',
      key: 'roleName',
      width: 500,
      sortable: false,
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<Membership>,
        config: {
          prefix: 'X.ROLE.',
        },
      },
    },
    {
      displayName: '',
      key: 'edit',
      sortable: false,
      visible: editable,
      renderer: {
        component: EditRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          icon: '16px_edit',
          action: MEMBERSHIP_EDIT_ACTION,
        },
      },
    },
  ];
}
