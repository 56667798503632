<form class="memberships-dialog-controls" [formGroup]="form">
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="'organisation'"
      [label]="'X.LABEL.ORGANISATION'"
      [emptyMessageKey]="emptyMessageKey"
      [preFilter]="fetchOrgas"
      [loading]="loading"
      [options]="organisationOptions"
      (selectChanged)="onSelectOrga($event)">
    </geaui-autocomplete-select-v2>
  </div>
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="'role'"
      [label]="'X.LABEL.ROLE'"
      [loading]="loading"
      [options]="roleOptions">
    </geaui-autocomplete-select-v2>
  </div>
  <div class="info-box" *ngIf="isOwnerRoleNull || ownerRoleReassigned">
    <geaui-icon-v2 class="icon" file="16px_c-info" color="black"></geaui-icon-v2>
    <span class="info-text">{{
      (ownerRoleReassigned ? 'MEMBERSHIPS.DIALOG.OWNER_ROLE_REASSIGN' : 'MEMBERSHIPS.DIALOG.OWNER_ROLE_MISSING') | translate
    }}</span>
  </div>
  <section class="invitation-btn-container" *ngIf="membershipData">
    <span
      ><geaui-link-v2 [preIcon]="'trash-can'" [type]="'cancel-red'" (click)="onDeleteMemberShip()">{{
        'X.BUTTON.DELETE' | translate
      }}</geaui-link-v2></span
    >
  </section>
</form>
