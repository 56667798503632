import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { PermissionKey, PermissionService, PermissionsState } from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';

export function checkPermissionsGuard(neededPermissions: PermissionKey[]): CanActivateFn {
  return () => {
    const router = inject(Router);
    const store = inject(Store);
    const permissionService = inject(PermissionService);

    permissionService.getPermissionsForStore();

    return store.select(PermissionsState.userPermissions).pipe(
      filter((permissions) => !!permissions.length), // Assumption, we should never have empty Permissions
      map((permissions) => {
        const hasPermission = neededPermissions.some((permission) => permissions.includes(permission));
        if (hasPermission) {
          return true;
        } else {
          void router.navigate(['/dashboard']);
          return false;
        }
      })
    );
  };
}
