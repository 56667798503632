import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonV2Module,
  ContentLayoutComponent,
  PageHeaderModule,
  PageContentLayoutComponent,
  ApiErrorResponse,
  ErrorHandlerV2Service,
} from '@gea/digital-ui-lib';
import { SecretCardComponent } from './secret-card/secret-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { ISecret } from '../../models/application.model';

@Component({
  selector: 'gea-id-workspace-application-secrets',
  standalone: true,
  imports: [
    CommonModule,
    ButtonV2Module,
    ContentLayoutComponent,
    TranslateModule,
    PageHeaderModule,
    ContentLayoutComponent,
    SecretCardComponent,
    PageContentLayoutComponent,
  ],
  templateUrl: './application-secrets.component.html',
  styleUrl: './application-secrets.component.scss',
})
export class ApplicationSecretsComponent implements OnInit {
  @Input({ required: true }) appId = '';
  secrets: ISecret[] = [];
  loading = false;
  isSending = false;
  constructor(
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandlerV2Service,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.applicationService
      .getSecrets(this.appId)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap(() => (this.loading = false))
      )
      .subscribe({
        next: (secrets: ISecret[]) => {
          this.secrets = secrets;
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.errorHandler.handleError(error);
        },
      });
  }

  protected renewSecret(name: string) {
    this.isSending = true;
    this.applicationService
      .updateSecret(this.appId, name)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap(() => (this.isSending = false))
      )
      .subscribe({
        next: (updatedSecret: ISecret) => {
          this.secrets = this.secrets.map((secret) =>
            secret.displayName === updatedSecret.displayName ? updatedSecret : secret
          );
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.isSending = false;
        },
      });
  }
}
