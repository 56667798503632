<ng-container *ngIf="rowData">
  <div class="overflow-hidden" *ngFor="let value of getNestedValue(rowData, columnKey); let indexOfElement = index">
    <span *ngIf="indexOfElement === 0">
      <p>{{ value }}</p>
    </span>
    <span *ngIf="indexOfElement !== 0">
      {{ value }}
    </span>
  </div>
</ng-container>
