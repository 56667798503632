import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ResourcesSelectOptionsModel } from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private _formGroup$ = new BehaviorSubject<FormGroup | null>(null);
  private _resourcesData = new BehaviorSubject<ResourcesSelectOptionsModel | null>(null);
  private _formChanges$ = new BehaviorSubject<boolean>(false);

  get areFormChanges(): boolean {
    return this._formChanges$.value;
  }

  readFormCache(): FormGroup | null {
    return this._formGroup$.value;
  }

  isFormCached(): boolean {
    return !!this._formGroup$.value;
  }

  cacheForm(form: FormGroup): void {
    this._formGroup$.next(form);
  }

  clearFormCache(): void {
    this._formGroup$.next(null);
  }

  cacheResourceData(resources: ResourcesSelectOptionsModel): void {
    this._resourcesData.next(resources);
  }

  readResourceCache(): ResourcesSelectOptionsModel | null {
    return this._resourcesData.value;
  }

  notifyFormChanges(areChanges: boolean) {
    this._formChanges$.next(areChanges);
  }

  clearResourceCache(): void {
    this._resourcesData.next(null);
  }
}
