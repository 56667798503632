import {
  appendVisibilitySettings,
  ColumnDefinition,
  ColumnRendererComponent,
  Membership,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { MembershipStateRendererComponent } from '@gea-id/shared';
import { MembershipStateRendererConfig } from '@gea-id/shared';

export const orgaUsersColumnDefinition: ColumnDefinition[] = appendVisibilitySettings([
  {
    displayName: 'X.LABEL.FIRST_NAME',
    key: 'firstName',
  },
  {
    displayName: 'X.LABEL.LAST_NAME',
    key: 'lastName',
  },
  {
    displayName: 'X.LABEL.EMAIL',
    key: 'email',
  },
  {
    displayName: 'X.LABEL.ROLE',
    key: 'roleName',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<Membership>,
      config: {
        prefix: 'X.ROLE.',
      },
    },
  },
  {
    displayName: 'X.LABEL.ROLE_STATUS',
    key: 'roleStatus',
    renderer: {
      component: MembershipStateRendererComponent as ColumnRendererComponent<Membership>,
      config: {
        mapping: {
          PENDING: '16px_c-info',
          ACCEPTED: '16px_check',
          EXPIRED: '16px_warning-sign',
          DECLINED: '16px_declined',
          INHERITED: '16px_pin-4',
        },
        postfixMapping: {
          PENDING: 'MEMBERSHIPS.LIST.STATE.PENDING',
          ACCEPTED: 'MEMBERSHIPS.LIST.STATE.ACCEPTED',
          EXPIRED: 'MEMBERSHIPS.LIST.STATE.EXPIRED',
          DECLINED: 'MEMBERSHIPS.LIST.STATE.DECLINED',
          INHERITED: 'MEMBERSHIPS.LIST.STATE.INHERITED',
        },
      } as MembershipStateRendererConfig,
    },
  },
]);
