import { Inject, Injectable, Optional } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable } from 'rxjs';

import { MembershipsApiService, RolesApiService } from '../../generated/api/v1';
import { Membership, MembershipState, UserModel, UserState } from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';
import { OrgaWithRoles, OWNER_ROLE_ID, Role, RoleWithOrgaType } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  userId = '';

  constructor(
    @Optional() @Inject('portalPath') private portalPath: string,
    private translate: TranslateService,
    private rolesApiService: RolesApiService,
    private membershipService: MembershipsApiService,
    private store: Store
  ) {
    store.select(UserState.user).subscribe((user: UserModel) => {
      this.userId = user.userId ?? '';
    });
  }

  getOrganizationsAndRoles(): Observable<OrgaWithRoles[]> {
    return forkJoin([this.membershipService.membershipsGet({ userId: this.userId }), this.getRoles()]).pipe(
      map(([memberships, roles]) => {
        return memberships.map((mem) => {
          return {
            organizationId: mem.organizationId,
            organizationName: mem.organizationName,
            roles: roles.map((role) => {
              return {
                roleId: role.id,
                roleName: role.name,
              } as Role;
            }),
          } as OrgaWithRoles;
        });
      })
    );
  }

  getRoles(): Observable<RoleWithOrgaType[]> {
    return this.rolesApiService.rolesList({ typeContains: undefined });
  }

  mapMembershipsForTable(memberships: Membership[]) {
    return (
      memberships.map((membership) => {
        const translatedName = this.translate.instant(`X.ROLE.${membership.roleName?.toUpperCase() ?? ''}`) as string;
        let tableName = translatedName;
        if (translatedName === `X.ROLE.${membership.roleName?.toUpperCase() ?? ''}`) {
          tableName = membership.roleName || '';
        }
        if (membership.inherited) {
          membership.state = MembershipState.INHERITED;
        }
        return {
          ...membership,
          roleName: tableName,
          updatable: !membership.inherited && membership.roleId !== OWNER_ROLE_ID,
        };
      }) ?? []
    );
  }
}
