<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header [withSidebar]="true" [hasBackArrow]="false" pageTitle="X.LABEL.USERS" help="HELP.USER.LIST.PAGE">
      <div class="page-header-content">
        <div
          [pTooltip]="'X.INFO.NO_ACCESS.RIGHT_MISSING' | translate"
          [tooltipDisabled]="!(hasNoCreatePermission | async)!"
          tooltipPosition="bottom"
          tooltipStyleClass="p-tooltip-light-blue">
          <geaui-button-v2 [disabled]="(hasNoCreatePermission | async)!" (click)="addUser()" preIcon="e-add">
            {{ 'X.BUTTON.ADD' | translate }}
          </geaui-button-v2>
        </div>
      </div>
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="user-list-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
      <geaui-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [tableId]="TABLE_ID"
        [columnDefinitions]="columnDefinitions"
        [data]="users"
        [totalRecords]="totalRecords"
        [sortable]="true"
        [loading]="loading"
        [rowsClickable]="true"
        (rowClicked)="editUser($event)">
      </geaui-table-v2>
    </div>
  </ng-container>
</geaui-page-content-layout>
