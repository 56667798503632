<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [pageTitle]="'USERS.USER_INVITE.PAGE_HEADER.TITLE'"
      (backArrowClicked)="pageHeaderBackClick()"
      help="HELP.USER.INVITE.PAGE">
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <geaui-wizard-v2 class="wizard-height" [alignNavigationAbsoluteToBottom]="true">
      <geaui-wizard-step-v2
        [order]="1"
        [nextButtonLabel]="'X.BUTTON.NEXT'"
        [onNext]="isInvitable()"
        [disableNextButtonByDefault]="true">
        <gea-id-workspace-user-invite-form [isLoading]="isLoading"></gea-id-workspace-user-invite-form>
      </geaui-wizard-step-v2>
      <geaui-wizard-step-v2
        [order]="2"
        [prevButtonLabel]="'X.BUTTON.BACK'"
        [nextButtonLabel]="'X.BUTTON.SAVE'"
        [onNext]="onSave()"
        [disableNextButtonByDefault]="true">
        <gea-id-workspace-user-invite-membership-list [disabled]="isSending"></gea-id-workspace-user-invite-membership-list>
      </geaui-wizard-step-v2>
    </geaui-wizard-v2>
  </ng-container>
</geaui-page-content-layout>
