import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { App, LayoutService, PermissionKey, PermissionsState, SelectOption, SnackbarService } from '@gea/digital-ui-lib';

import { ProjectName, TranslationApiService } from '../services';
import { Store } from '@ngxs/store';
import { AppService } from '../services/app.service';
import { LatestDeploymentsComponent } from './latest-deployments';

@Component({
  selector: 'gea-id-workspace-administration-tasks',
  templateUrl: './administration-tasks.component.html',
  styleUrls: ['./administration-tasks.component.scss'],
})
export class AdministrationTasksComponent implements OnInit {
  @ViewChild(LatestDeploymentsComponent)
  latestDeploymentsComponent!: LatestDeploymentsComponent;
  public apps: App[] = [];
  public appOptions?: SelectOption[];
  public formGroup: FormGroup = this.formBuilder.group({
    apps: [null, [Validators.required.bind(this)]],
  });
  public loading = false;
  public disabled = false;
  highlightRow = false;

  constructor(
    public layout: LayoutService,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private translationApi: TranslationApiService,
    private appService: AppService,
    private store: Store
  ) {}

  ngOnInit(): void {
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store.select(PermissionsState.userPermissions).subscribe((permissions) => {
      this.disabled = !permissions.includes(PermissionKey.DEPLOY_TRANSLATIONS);
      if (this.disabled) {
        this.appsControl?.disable();
      } else {
        this.appsControl?.enable();
      }
    });
    this.appService
      .getApps()
      .pipe(first())
      .subscribe((apps: App[]) => {
        this.apps = apps.filter((app) => app.hasTranslation);
        this.createOptions();
      });
  }

  get appsControl() {
    return this.formGroup?.get('apps');
  }

  createOptions(): void {
    this.appOptions = this.apps
      .map((app) => {
        const regex = /(?:CORE|FT|LPT|SFT|HRT|FHT)_([A-z0-9-]+)$/;
        const match = app.appKey.match(regex);
        const appName = match ? match[1] : '';
        return {
          nameKey: 'ADMINISTRATION_TASKS.APP_OPTIONS.' + appName.toUpperCase(),
          value: appName.toLowerCase(),
        };
      })
      .sort((a, b) => a.nameKey.localeCompare(b.nameKey));
    // Add default option
    this.appOptions.unshift(
      {
        nameKey: 'ADMINISTRATION_TASKS.APP_OPTIONS.COMMON',
        value: 'common',
      },
      {
        nameKey: 'ADMINISTRATION_TASKS.APP_OPTIONS.IDP',
        value: 'idp',
      }
    );
  }

  getAppId(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const controlValue: SelectOption<string> | string = this.appsControl?.value;
    if (typeof controlValue === 'string') {
      return controlValue;
    } else {
      return ((controlValue as SelectOption)?.value as string) ?? '';
    }
  }

  getAppName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const controlValue: SelectOption<string> | string = this.appsControl?.value;
    if (typeof controlValue === 'string') {
      return controlValue;
    } else {
      return ((controlValue as SelectOption)?.nameKey as string) ?? '';
    }
  }

  save() {
    const appId = this.getAppId() as ProjectName;
    this.loading = true;
    this.highlightRow = true;
    this.translationApi
      .deploy(appId)
      .pipe(first())
      .subscribe({
        next: () => {
          const title = this.translate.instant('ADMINISTRATION_TASKS.DEPLOY_SUCCESS_TITLE') as string;
          const message = this.translate.instant('ADMINISTRATION_TASKS.DEPLOY_SUCCESS', {
            appName: this.translate.instant(this.getAppName()) as string,
            //Deployment time should be changed when the deploy endpoint returns the lastDeploymentDate
            //Currently the message shows the current time which is not correct
            //If the translation is not changed the translation service is not updating the deployment date
            timestamp: new Date().toLocaleString(),
          }) as string;
          this.snackbar.add({ severity: 'success', summary: title, detail: message });
          //If the appName (appId) contains - then split it and get the second part
          //Because this is an app related to another
          this.latestDeploymentsComponent.refresh(appId.includes('-') ? appId.split('-')[1] : appId);
          this.loading = false;
        },
        error: () => {
          const title = this.translate.instant('ADMINISTRATION_TASKS.DEPLOY_ERROR_TITLE') as string;
          const message = this.translate.instant('ADMINISTRATION_TASKS.DEPLOY_ERROR') as string;
          this.snackbar.add({ severity: 'error', summary: title, detail: message });
          this.loading = false;
        },
      });
  }
}
