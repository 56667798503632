<div>
  <div class="top-box flex">
    <geaui-icon-v2 class="icon" file="16px_c-info" color="black"></geaui-icon-v2>
    <span [innerHTML]="'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.TOP_INFO.LABEL' | translate"></span>
  </div>
  <div class="input-field">
    <geaui-search-bar-v2
      class="customer-selection-gea-ui-input"
      id="customerNumber"
      [label]="'ORGANIZATION.DETAIL.INFO.BASIC.CUSTOMER_NUMBER.TITLE.LABEL' | translate"
      [hasSuggestions]="false"
      [group]="form"
      [initialValue]="customerNumber ? customerNumber.toString() : ''"
      [sending]="loading"
      (searchButtonClicked)="this.form.controls.customerNumber.updateValueAndValidity({ emitEvent: true })"
      aria-expanded="false"
      suffixIcon="searchIcon">
    </geaui-search-bar-v2>
  </div>

  <div class="bottom-box">
    @if (loading && customerNumber) {
      <p-progressSpinner class="center" animationDuration="2s" strokeWidth="4"></p-progressSpinner>
    } @else if (customerNumber && form.hasError('noCustomerFound')) {
      <div class="center">
        <geaui-icon-v2 file="32px_empty"></geaui-icon-v2>
        <p class="fat-text">{{ 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.NO_RESULTS.TITLE' | translate }}</p>
        <div>{{ 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.NO_RESULTS.SUMMARY' | translate }}</div>
      </div>
    } @else if (customer && customerNumber) {
      <div class="text">
        <div class="success">
          @if (form.hasError('linkedOrganizationFound')) {
            <geaui-icon-v2 file="24px_c-info" color="orange">
              <div class="margin-y-10" suffix>
                {{ 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.LINKED_ORGA' | translate }}
              </div>
            </geaui-icon-v2>
          } @else {
            <geaui-icon-v2 file="24px_c-check" color="green">
              <div class="margin-y-10" suffix>
                {{ 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.SUCCESS' | translate }}
              </div>
            </geaui-icon-v2>
          }
        </div>
        <div class="address">
          <div class="fat-text">{{ customer.name }}</div>
          @if (customer.address; as address) {
            <div>{{ address.street }} {{ address.zipCode + ' ' + address.city }}</div>
          }
        </div>
      </div>
    }
  </div>
</div>
