import { Component } from '@angular/core';
import { ComplexDialogEmbeddedView, IconColor } from '@gea/digital-ui-lib';

export interface Feature {
  iconFile: string;
  iconColor?: IconColor;
  title: string;
  content: string;
}

@Component({
  selector: 'gea-id-workspace-dashboard',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
})
export class LearnMoreComponent implements ComplexDialogEmbeddedView {
  features: Feature[] = [
    {
      iconFile: '16px_multiple',
      title: 'DASHBOARD.KEY_FEATURES.USERS.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.USERS.DETAIL',
    },
    {
      iconFile: '16px_flag',
      title: 'DASHBOARD.KEY_FEATURES.FEATURE_FLAG.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.FEATURE_FLAG.DETAIL',
    },
    {
      iconFile: '16px_filter-organization',
      title: 'DASHBOARD.KEY_FEATURES.ORGANIZATIONS.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.ORGANIZATIONS.DETAIL',
    },
    {
      iconFile: '16px_bell',
      title: 'DASHBOARD.KEY_FEATURES.ADMIN_NOTIFICATIONS.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.ADMIN_NOTIFICATIONS.DETAIL',
    },
    {
      iconFile: '16px_group',
      title: 'DASHBOARD.KEY_FEATURES.MDG_CUSTOMER.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.MDG_CUSTOMER.DETAIL',
    },
    {
      iconFile: '16px_group',
      title: 'DASHBOARD.KEY_FEATURES.VERSIONS.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.VERSIONS.DETAIL',
    },
    {
      iconFile: '16px_enter',
      title: 'DASHBOARD.KEY_FEATURES.ACCESS_PORTAL.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.ACCESS_PORTAL.DETAIL',
    },
    {
      iconFile: '16px_list',
      title: 'DASHBOARD.KEY_FEATURES.ADMINISTRATION.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.ADMINISTRATION.DETAIL',
    },
    {
      iconFile: '16px_chat',
      title: 'DASHBOARD.KEY_FEATURES.CONTACT.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.CONTACT.DETAIL',
    },
    {
      iconFile: '16px_home',
      title: 'DASHBOARD.KEY_FEATURES.ABOUT.TITLE',
      content: 'DASHBOARD.KEY_FEATURES.ABOUT.DETAIL',
    },
  ];
  isAcceptDisabled = true;
}
