import { BaseMembership } from '@gea/digital-ui-lib';

export interface CreateUser {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  memberships: BaseMembership[];
  dashboardId?: string;
}

export interface InviteableResponse {
  inviteable: boolean;
  userId: string;
}
