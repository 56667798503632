import { Component } from '@angular/core';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { getNestedValue } from '../utils/utlis';
import { Author } from '../../../../services';

@Component({
  selector: 'gea-id-custom-trigger-by-renderer',
  templateUrl: './custom-trigger-by.component.html',
  styleUrls: ['./custom-trigger-by.scss'],
})
export class CustomTriggerByRendererComponent<
  T extends {
    [key: string]: unknown;
  },
> implements ColumnRendererComponent<T>
{
  rowData?: T;
  columnKey = '';

  protected readonly getNestedValue = getNestedValue<Author>;
}
