<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header [pageTitle]="'PORTAL-NOTIFICATION-SETTINGS.TITLE' | translate" [hasBackArrow]="false" [withSidebar]="true">
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="geaui-grid geaui-sidebar-grid portal-notification-settings-container">
      <div
        class="left-container geaui-col-desktop-sm-5 geaui-col-desktop-md-5 geaui-col-desktop-lg-5 geaui-col-desktop-xl-5 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4 geaui-col-desktop-xl-start-7">
        <div class="geaui-sub-grid">
          <div
            class="notification-channels-container geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
            <span class="notification-channels-title title">{{
              'PORTAL-NOTIFICATION-SETTINGS.NOTIFICATION-CHANNELS' | translate
            }}</span>
            <div class="notification-channels-radio-buttons geaui-sub-grid">
              <geaui-toggle-v2
                class="email-notification geaui-col-desktop-md-6 geaui-col-tablet-lg-6 geaui-col-tablet-sm-6 geaui-col-phone-lg-4 geaui-col-phone-sm-4"
                [initialValue]="settings.channelEmail"
                [loading]="loading"
                [isLabelLeft]="true"
                (toggled)="toggleChannelEmail($event)"
                [label]="'PORTAL-NOTIFICATION-SETTINGS.EMAIL-NOTIFICATION' | translate"></geaui-toggle-v2>
              <geaui-toggle-v2
                class="push-notification geaui-col-desktop-md-6 geaui-col-tablet-lg-6 geaui-col-tablet-sm-6 geaui-col-phone-lg-4 geaui-col-phone-sm-4 geaui-col-desktop-md-start-right-1"
                [initialValue]="settings.channelInApp"
                [loading]="loading"
                [isLabelLeft]="true"
                (toggled)="toggleChannelInApp($event)"
                [label]="'PORTAL-NOTIFICATION-SETTINGS.PUSH-NOTIFICATION' | translate"></geaui-toggle-v2>
            </div>
          </div>
          <div
            class="apps-setting-container geaui-sub-grid geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
            <span
              class="geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-8 title"
              >{{ 'PORTAL-NOTIFICATION-SETTINGS.APPS-SETTINGS' | translate }}</span
            >
            <span
              class="geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-8"
              >{{ 'PORTAL-NOTIFICATION-SETTINGS.APPS-SETTINGS.SUB-TITLE' | translate }}</span
            >
            <geaui-select-v2
              class="app-select geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-8"
              id="apps"
              [options]="apps"
              [group]="formGroup"
              [label]="'NOTIFICATION-SETTINGS.SELECT-APP' | translate"
              [loading]="loading"
              (selectChanged)="selectApp($event); setAllAppsToggleState()">
            </geaui-select-v2>
          </div>
          <div
            class="activate-all-container geaui-sub-grid geaui-sub-grid geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
            <span
              class="geaui-col-desktop-md-6 geaui-col-tablet-lg-6 geaui-col-tablet-sm-6 geaui-col-phone-lg-4 geaui-col-phone-sm-4"></span>
            <geaui-toggle-v2
              class="geaui-col-desktop-md-6 geaui-col-tablet-lg-6 geaui-col-tablet-sm-6 geaui-col-phone-lg-4 geaui-col-phone-sm-4 geaui-col-desktop-md-start-right-1"
              [loading]="loading"
              [isLabelLeft]="true"
              [label]="'PORTAL-NOTIFICATION-SETTINGS.ACTIVATE-ALL' | translate"
              (toggled)="allAppsToggleState = $event; toggleAllApps()"></geaui-toggle-v2>
          </div>
          <div
            class="radio-buttons-container geaui-sub-grid geaui-sub-grid geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
            <div
              class="generated-notification-container container-padding geaui-sub-grid geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
              @for (notificationType of selectedApp?.notificationTypes; track notificationType) {
                <div
                  class="generated-notification-description geaui-col-desktop-md-8 geaui-col-desktop-lg-8 geaui-col-desktop-xl-8 geaui-col-phone-lg-16">
                  <div class="generated-notification-title">
                    {{
                      'UI-LIB.NOTIFICATION-SETTINGS.APP.' + selectedApp?.name + '.SETTING.' + notificationType.name | translate
                    }}
                  </div>
                </div>
                <geaui-toggle-v2
                  class="generated-notification-radio-button geaui-col-desktop-xl-start-21 geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-4 geaui-col-phone-lg-8"
                  [initialValue]="notificationType.active"
                  [disabled]="notificationType.disabled ?? false"
                  (toggled)="notificationType.active = $event; setAllAppsToggleState()">
                </geaui-toggle-v2>
              }
            </div>
          </div>
        </div>
      </div>

      <div
        class="right-container geaui-col-desktop-sm-7 geaui-col-desktop-md-7 geaui-col-desktop-lg-7 geaui-col-desktop-xl-7 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4 geaui-col-desktop-xl-start-7">
        <span class="organizations-title title">{{
          'PORTAL-NOTIFICATION-SETTINGS.ORGANIZATIONS-NOTIFICATIONS' | translate
        }}</span>
        <div class="organizations-table-container">
          <geaui-table-v2
            class="organizations-table"
            uniquePropertyKey="id"
            [tableId]="TABLE_ID"
            [columnDefinitions]="columnDefinitions"
            [data]="tableData"
            [loading]="loadingOrganizations"
            [totalRecords]="totalRecords"
            [sortable]="true"
            [(selection)]="selectedOrganizations"
            [rowsClickable]="false">
          </geaui-table-v2>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 [type]="'primary'" data-cy="send-request" (click)="save()">
          <span>{{ 'X.BUTTON.SAVE' | translate }}</span>
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
