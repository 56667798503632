import { Component } from '@angular/core';

import { ColumnRendererComponent, IconModule, Membership } from '@gea/digital-ui-lib';
import { MembershipStateRendererConfig } from '../../../model';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';

type InheritedKey = Extract<keyof Membership, 'inherited'>;

@Component({
  selector: 'gea-id-membership-state-renderer',
  templateUrl: './membership-state-renderer.component.html',
  standalone: true,
  styleUrl: './membership-state-renderer.component.scss',
  imports: [IconModule, TranslateModule, TooltipModule, CommonModule],
})
export class MembershipStateRendererComponent<T extends { [key: string]: never }> implements ColumnRendererComponent<T> {
  rowData = {} as T;
  columnKey = '';
  config: MembershipStateRendererConfig = {} as MembershipStateRendererConfig;
  inheritedKey: InheritedKey = 'inherited';
}
