import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, first, map } from 'rxjs';

import { columnDefinitions } from './model/columns.config';
import {
  ApiErrorResponse,
  appendVisibilitySettings,
  ColumnDefinition,
  CustomerDto,
  ErrorHandlerV2Service,
  FilterTableSettings,
  KeymappingPartner,
  Keymappings,
  ListResponse,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { CustomerAdminApiService } from '../services/customer-admin-api.service';

@Component({
  selector: 'gea-id-workspace-mdg-customer-table',
  templateUrl: './mdg-customer-table.component.html',
  styleUrls: ['./mdg-customer-table.component.scss'],
})
export class MdgCustomerTableComponent implements OnInit, OnDestroy {
  public readonly TABLE_ID = 'admin-mdg-customer-table';

  public customerData: CustomerDto[] = [];
  public totalRecords = 0;
  public columnDefinitions: ColumnDefinition[] = [];
  public loading = true;

  private subs: Subscription[] = [];

  constructor(
    private customerService: CustomerAdminApiService,
    private tableService: TableServiceV2,
    private errorHandler: ErrorHandlerV2Service
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter: FilterTableSettings) => {
        this.fetchData(filter);
      })
    );
    this.columnDefinitions = appendVisibilitySettings(columnDefinitions(this.hoverHtmlGenerator));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private fetchData(tableFilter: FilterTableSettings) {
    this.loading = true;
    this.customerService
      .getAllCustomers(tableFilter)
      .pipe(first())
      .subscribe({
        next: (page: ListResponse<CustomerDto[]>) => {
          this.customerData = page.pageEntries;
          this.totalRecords = page.entryCount;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
  }

  private hoverHtmlGenerator = (rowData: CustomerDto): Observable<string> => {
    return this.customerService.getKeymappings(rowData.customerId).pipe(
      first(),
      map((keymappings: Keymappings[]) => {
        if (!keymappings.length) {
          return [`No keymappings for ${rowData.customerId} found`];
        }
        return keymappings.map((list: Keymappings) => {
          const tableData = list.partners
            .map(
              (partner: KeymappingPartner) => `<tr><td>${partner.partnerSystemId}</td><td>${partner.partnerSystemName}</td></tr>`
            )
            .reduce((row1, row2) => row1 + row2, '');
          return `<div style="min-width: 325px; margin: 10px;">
      <h4>Keymappings of ${rowData.customerId}:</h4>
    <table>
      <tr>
        <th>PartnerSystemId</th>
        <th>PartnerSystemName</th>
      </tr>
        ${tableData}
    </table>
    </div>`;
        });
      }),
      map((x) => x.join('<br>'))
    );
  };
}
