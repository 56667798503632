import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiErrorResponse, ErrorHandlerV2Service, FilterTableSettings, TableServiceV2 } from '@gea/digital-ui-lib';
import { finalize } from 'rxjs/operators';
import { columnDefinitions } from './models/columns.config';
import { EntityArrayResponseType, HistoryService, History } from '../../../services/';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserDetailService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-history-list',
  templateUrl: './user-history-list.component.html',
  styleUrls: ['./user-history-list.component.scss'],
})
export class UserHistoryListComponent implements OnInit, OnDestroy {
  public readonly TABLE_ID = 'user-audit-history-table';
  public userHistoryData: History[] = [];
  public totalRecords = 0;
  public columnDefinitions = columnDefinitions;
  public loading = false;
  @Input() private userId = '';
  public subs: Subscription[] = [];

  constructor(
    private userHistoryApiService: HistoryService,
    private errorHandlerV2Service: ErrorHandlerV2Service,
    private userDetailService: UserDetailService,
    private tableService: TableServiceV2,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.userDetailService.currentUserId) {
      this.userId = this.userDetailService.currentUserId;
    }

    if (!this.userId) {
      void this.router.navigate(['/user']);
      return;
    }
    this.subs.push(
      this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter: FilterTableSettings) => {
        this.fetchData(filter, this.userId);
      })
    );
  }

  fetchData(tableFilter: FilterTableSettings, id: string) {
    this.loading = true;
    this.userHistoryApiService
      .getAllUserHistoryByUser(tableFilter, id)
      .pipe(finalize(() => this.onLoadFinalize()))
      .subscribe({
        next: (result) => this.onLoadSuccess(result),
        error: (e: ApiErrorResponse) => this.onLoadError(e),
      });
  }

  private onLoadSuccess(pageResult: EntityArrayResponseType) {
    this.userHistoryData = pageResult.pageEntries;
    this.totalRecords = pageResult.entryCount;
  }

  private onLoadError(error: ApiErrorResponse) {
    this.loading = false;
    this.errorHandlerV2Service.handleError(error);
  }

  protected onLoadFinalize(): void {
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
