<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [hasBackArrow]="false"
      pageTitle="TEMP_MEMBERSHIP.PAGE_TITLE"
      help="HELP.TEMP_MEMBERSHIP.PAGE"></geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="padded-container-top"></div>
    <form [formGroup]="formGroup">
      <section class="geaui-grid geaui-sidebar-grid container-top">
        <div class="gea-font-heading-20 geaui-col-desktop-md-12">
          {{ 'TEMP_MEMBERSHIP.FORM_TITLE' | translate }}
        </div>
        <geaui-autocomplete-select-v2
          class="geaui-col-phone-sm-2 geaui-col-phone-sm-start-1 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-3"
          #orga
          [emptyMessageKey]="emptyMessageKey"
          [loading]="loading"
          [label]="'X.LABEL.ORGANISATION' | translate"
          [options]="orgas"
          [preFilter]="fetchOrgas"
          [required]="true"
          formControlName="organization">
        </geaui-autocomplete-select-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-phone-sm-2 geaui-col-phone-sm-start-3 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4 geaui-row-desktop-md-start-3"
          [loading]="loading"
          [label]="'X.LABEL.LANGUAGE' | translate"
          [options]="languageOptions"
          [required]="true"
          formControlName="language">
        </geaui-autocomplete-select-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-phone-sm-2 geaui-col-phone-sm-start-1 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-4"
          [loading]="loading"
          [label]="'X.LABEL.ROLE' | translate"
          [options]="roles$ | async"
          [required]="true"
          formControlName="role">
        </geaui-autocomplete-select-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-phone-sm-2 geaui-col-phone-sm-start-3 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4 geaui-row-desktop-md-start-4"
          [loading]="loading"
          [label]="'TEMP_MEMBERSHIP.START_APP' | translate"
          [options]="startingApps"
          [required]="true"
          formControlName="startingApp">
        </geaui-autocomplete-select-v2>
        <div
          class="save-button geaui-col-phone-sm-2 geaui-col-phone-sm-start-4 geaui-col-desktop-md-start-9 geaui-col-desktop-md-4 geaui-row-desktop-md-start-4">
          <geaui-button-v2 [disabled]="formGroup.invalid" [postIcon]="true" [loading]="loading" (click)="save()">
            {{ 'TEMP_MEMBERSHIP.GOTO_APP_BUTTON' | translate }}
          </geaui-button-v2>
        </div>
      </section>
    </form>
  </ng-container>

  <ng-container page-content>
    <div class="padded-container-bottom"></div>
    <form [formGroup]="formGroup">
      <section>
        <div class="top-gap">
          <div class="geaui-grid geaui-sidebar-grid">
            <div class="gea-font-heading-20 geaui-col-desktop-md-12">
              {{ 'TEMP_MEMBERSHIP.HISTORY_TITLE' | translate }}
            </div>
          </div>
        </div>
        <div class="list-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
          <geaui-table-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
            [tableId]="TABLE_ID"
            [columnDefinitions]="columnDefinitions"
            [data]="tempMemberships"
            [totalRecords]="totalRecords"
            [sortable]="true"
            [loading]="loading"
            [rowsClickable]="false">
          </geaui-table-v2>
        </div>
      </section>
    </form>
  </ng-container>
</geaui-page-content-layout>
