import { Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ErrorHandlerV2Service,
  PatchUser,
  TableServiceV2,
  ListResponse,
  Membership,
  MembershipState,
  User,
} from '@gea/digital-ui-lib';

import { BehaviorSubject, catchError, filter, first, Observable, Subject, switchMap, tap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { MembershipService } from './membership.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailService {
  private _userName$ = new Subject<string>();
  private _profileFormData$ = new BehaviorSubject<User | null>(null);
  private _memberships$ = new BehaviorSubject<ListResponse<Membership[]> | null>(null);
  private _currentUserId = '';
  public readonly TABLE_ID_MEMBERSHIPS = 'admin-memberships-table';

  constructor(
    private userService: UserService,
    private errorHandlerService: ErrorHandlerV2Service,
    private membershipService: MembershipService,
    private tableService: TableServiceV2
  ) {}

  get userName$(): Observable<string> {
    return this._userName$.asObservable();
  }

  get profileFormData$(): Observable<User | null> {
    return this._profileFormData$.asObservable();
  }

  set currentUserId(userId: string) {
    this._currentUserId = userId;
  }

  get currentUserId(): string {
    return this._currentUserId;
  }

  get memberships$(): Observable<ListResponse<Membership[]> | null> {
    return this._memberships$.asObservable();
  }

  get memberships(): Membership[] | null {
    const memberships = this._memberships$.getValue();
    return memberships ? memberships.pageEntries : null;
  }

  set memberships(memberships: Membership[] | null) {
    this._memberships$.next({
      pageEntries: [...(memberships ?? [])],
      entryCount: this._memberships$.getValue()?.entryCount ?? 0,
    });
  }

  get tableIdMemberships(): string {
    return this.TABLE_ID_MEMBERSHIPS;
  }

  resetMemberships() {
    this._memberships$.next(null);
  }

  set pageMemberShipList(page: number) {
    const settings = this.tableService.getFilterTableSettings(this.TABLE_ID_MEMBERSHIPS).value;
    this.tableService.updateFilterTableSettings(this.TABLE_ID_MEMBERSHIPS, { ...settings, page });
  }

  isMembershipsLoaded(): boolean {
    return this._memberships$.getValue() !== null;
  }

  resetProfileFormData() {
    this._profileFormData$.next(null);
  }

  deleteUser(email: string): Observable<unknown> {
    return this.userService.deleteUser(email).pipe(
      catchError((error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        return throwError(() => error);
      }),
      first(),
      tap(() => {
        this.currentUserId = '';
        this.resetMemberships();
        this.resetProfileFormData();
      })
    );
  }

  fetchProfileFormData(): void {
    this.userService
      .getUser(this._currentUserId)
      .pipe(
        tap((userData: User) => {
          if (!userData.firstName && !userData.lastName) this._userName$.next('');
          else this._userName$.next(userData.firstName + ' ' + userData.lastName);
        }),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          return throwError(() => error);
        })
      )
      .subscribe((userData: User) => this._profileFormData$.next(userData));
  }

  updateProfileData(userId: string, userData: PatchUser): Observable<unknown> {
    return this.userService.updateUser(userId, userData).pipe(
      catchError((error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        return throwError(() => error);
      }),
      first()
    );
  }

  fetchMemberships(skipInherited = false): void {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID_MEMBERSHIPS)
      .pipe(
        tap(() => this.resetMemberships()),
        filter(() => !!this.currentUserId),
        switchMap((filterSettings) =>
          this.membershipService.getMembershipsPaginated(this._currentUserId, filterSettings, skipInherited)
        ),
        map((membershipListResponse) => this.mapRoleNamesToUpperCase(membershipListResponse)),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          return throwError(() => error);
        })
      )
      .subscribe((membershipListResponse) => this._memberships$.next(membershipListResponse));
  }

  public resetExpirationOfMembership(id?: string) {
    const memberships = this.memberships ?? [];
    const oldMembership = memberships.find((m) => m.id === id);
    if (!oldMembership) {
      throw new Error('Membership not found');
    }
    memberships.splice(
      memberships.findIndex((m) => m.id === id),
      1
    );
    this.memberships = [{ ...oldMembership, state: MembershipState.PENDING }, ...memberships];
  }

  private mapRoleNamesToUpperCase(response: ListResponse<Membership[]>): ListResponse<Membership[]> {
    return {
      entryCount: response.entryCount,
      pageEntries: response.pageEntries.map((membership) => ({
        ...membership,
        roleName: membership.roleName?.toUpperCase(),
      })),
    };
  }
}
