<div class="main-container" *ngIf="!loading; else skeleton">
  <div class="title flex">
    <div class="label-container">
      <span>{{ 'APP_ENABLED.TITLE.LABEL' | translate }}</span>
      <p-progressSpinner
        *ngIf="updateLoading()"
        [style]="{ width: '24px', height: '24px', color: 'green' }"
        strokeWidth="8"
        animationDuration=".5s" />
    </div>
    <geaui-multi-select-v2
      #multiSelect
      [options]="options()"
      [selected]="selectedOptions()"
      [loading]="loading"
      [floatLabelType]="'never'"
      [label]="'APP_ENABLED.TITLE.LABEL' | translate"
      [configuration]="multiSelectConfig"
      (optionSelected)="onSelectionChanged($event)"></geaui-multi-select-v2>
  </div>
  <div class="chip-container">
    @for (option of selectedOptions(); track option.name) {
      <geaui-chip [loading]="loading" [readOnly]="true" [severity]="'information'" [size]="'small'">{{ option.name }}</geaui-chip>
    }
  </div>
</div>
<ng-template #skeleton>
  <div class="gea-form-container-margin">
    <ngx-skeleton-loader [theme]="{ height: '100px', width: '100%' }" count="1" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>
