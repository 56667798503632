@if (!rowData[inheritedKey]) {
  <span class="flex">
    <geaui-icon-v2
      class="padding-right-16"
      [file]="config.mapping[rowData[columnKey]] | translate"
      [color]="'black'"></geaui-icon-v2>
    {{ config.postfixMapping[rowData[columnKey]] | translate }}
  </span>
} @else {
  <span
    class="flex"
    [pTooltip]="'MEMBERSHIPS.LIST.STATE.TOOLTIP.INHERITED_MESSAGE' | translate"
    tooltipPosition="right"
    tooltipStyleClass="p-tooltip-light-blue">
    <geaui-icon-v2
      class="padding-right-16"
      [file]="config.mapping[rowData[columnKey]] | translate"
      [color]="'black'"></geaui-icon-v2>
    {{ config.postfixMapping[rowData[columnKey]] | translate }}
  </span>
}
