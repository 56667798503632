//FIXME: This function is already available in table-v2.utils
export function getNestedValue<T>(rowData: { [p: string]: unknown }, key: string): T {
  const nestedKeys = key.split('.');
  if (nestedKeys.length === 1) return rowData[key] as T;
  let data: { [key: string]: unknown } = rowData;
  for (const key of nestedKeys) {
    data = data[key] as { [key: string]: unknown };
    if (data === undefined) {
      return '' as T;
    }
  }
  return data as unknown as T;
}
