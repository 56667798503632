import { AddressModel } from '@gea/digital-ui-lib';

export interface SiteModel {
  address?: AddressModel;
  city?: string;
  country?: string;
  street?: string;
  zipCode?: string;
  id?: string;
  name: string;
  phoneNumber: string;
}
