import { Component, OnInit } from '@angular/core';

import { ComplexDialogV2Service, GridService, WidgetDefinitions, WidgetSetting } from '@gea/digital-ui-lib';

import { DefaultWidgetComponent } from './default-widget/default-widget.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';

@Component({
  selector: 'gea-id-workspace-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  applicationId = 'publicDashboard';
  baseWidgetSettings: WidgetSetting[] = [
    {
      h: 1,
      w: 2,
      type: 'Default',
      x: 0,
      y: 0,
    },
  ];
  storedWidgetSettings: WidgetSetting[] = [];
  widgetDefinitions: WidgetDefinitions = {
    Default: {
      component: DefaultWidgetComponent,
      h: 1,
      w: 2,
      widgetCallback: this.openLearnMore.bind(this),
    },
  };
  editModeActive = false;

  constructor(
    private complexDialog: ComplexDialogV2Service,
    public gridService: GridService
  ) {}

  openLearnMore() {
    this.complexDialog.open(
      {
        title: 'DASHBOARD.DEFAULT.TITLE',
        hideButtons: true,
        closable: true,
      },
      LearnMoreComponent
    );
  }

  ngOnInit(): void {
    this.storedWidgetSettings = this.baseWidgetSettings;
  }

  editDashboard() {
    this.gridService.editDashboard.next();
  }
}
