import { Observable } from 'rxjs';
import { TooltipRendererComponent } from '../';

import {
  ColumnDefinition,
  ColumnRendererComponent,
  CustomerDto,
  SelectFilterComponent,
  TextFilterComponent,
} from '@gea/digital-ui-lib';

export const columnDefinitions: (hoverHtmlGenerator: (rowData: CustomerDto) => Observable<string>) => ColumnDefinition[] = (
  hoverHtmlGenerator
) => [
  createTextColumn('ID', 'customerId'),
  createTextColumn('Name', 'customerName1', 300),
  createTextColumn('Name 2', 'customerName2'),
  createTextColumn('Name 3', 'customerName3'),
  createTextColumn('Name 4', 'customerName4'),
  {
    displayName: 'Keymappings',
    key: 'customerId',
    renderer: {
      component: TooltipRendererComponent as ColumnRendererComponent<CustomerDto>,
      config: {
        hoverHtmlGenerator: hoverHtmlGenerator,
      },
    },
  },
  { ...createTextColumn('Organisation', 'linkedOrganization.name'), sortable: false },
  createTextColumn('Country', 'customerCountry'),
  createTextColumn('ZIP', 'customerZip'),
  createTextColumn('City', 'customerCity'),
  createTextColumn('Street', 'customerStreet'),
  createTextColumn('Street Number', 'customerStreetNumber'),
  createTextColumn('Language', 'customerLanguage'),
  {
    displayName: 'Status',
    key: 'customerStatus',
    filter: {
      component: SelectFilterComponent,
      config: {
        options: [
          { name: 'true', value: 'true' },
          { name: 'false', value: 'false' },
        ],
      },
    },
  },
  createTextColumn('Delivery Blocking Reason Code', 'customerDeliveryBlockingReasonCode'),
  createTextColumn('Order Blocking Reason Code', 'customerOrderBlockingReasonCode'),
  createTextColumn('Posting Blocking Reason Code', 'customerPostingBlockingReasonCode'),
];

function createTextColumn(displayName: string, key: string, columnWidth = 150) {
  return {
    displayName,
    key,
    width: columnWidth,
    filter: {
      component: TextFilterComponent,
    },
  };
}
