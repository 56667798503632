import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailService } from '@gea-id/shared';
import { MenuItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { FormService } from '@gea-id/shared';
import { ComponentCanDeactivate } from '@gea-id/shared';
import { HelpPanel } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-id-workspace-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrl: './user-detail.component.scss',
})
export class UserDetailComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @Input({ required: true }) userId = '';

  userName = '';

  tabItems: MenuItem[] = [
    {
      id: '1',
      label: 'X.LABEL.PERSONAL_DATA',
      help: 'HELP.USER.PERSONAL_DATA.PAGE',
      helpTitle: 'HELP.USER.PERSONAL_DATA.TITLE',
    },
    {
      id: '2',
      label: 'X.LABEL.MEMBERSHIPS',
      help: 'HELP.USER.MEMBERSHIPS.PAGE',
      helpTitle: 'HELP.USER.MEMBERSHIPS.TITLE',
    },
    {
      id: '3',
      label: 'USERS.USER_DETAIL.TAB.USER_HISTORY',
      help: 'HELP.USER.HISTORY.PAGE',
      helpTitle: 'HELP.USER.HISTORY.TITLE',
    },
  ];
  activeItem: MenuItem & { help?: string; helpTitle?: string } = this.tabItems[0];

  private unsubscribe$ = new Subject<void>();

  private readonly USER_LIST_PATH = 'user';
  private readonly EDIT_USER_PATH = 'edit-user';
  private readonly MEMBERSHIPS_PATH = 'memberships';
  private readonly USER_HISTORY_PATH = 'user-history';

  get help(): HelpPanel | undefined {
    if (this.activeItem?.help) return { title: this.activeItem.helpTitle, body: this.activeItem.help };
    return undefined;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userDetailService: UserDetailService,
    private formService: FormService
  ) {}

  canDeactivate() {
    return !this.formService.areFormChanges;
  }

  ngOnInit() {
    this.initActiveTab();
    this.userDetailService.currentUserId = this.userId;
    this.userDetailService.fetchProfileFormData();
    this.userDetailService.fetchMemberships(true);
    this.userDetailService.userName$.pipe(takeUntil(this.unsubscribe$)).subscribe((userName) => (this.userName = userName));
  }

  ngOnDestroy(): void {
    this.formService.clearFormCache();
    this.formService.clearResourceCache();
    this.userDetailService.resetMemberships();
    this.userDetailService.resetProfileFormData();
    this.userDetailService.pageMemberShipList = 0;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeTab($event: MenuItem) {
    this.activeItem = $event;
    switch (this.activeItem.id) {
      case '2':
        this.routeMemberships();
        break;
      case '3':
        this.routeUserHistory();
        break;
      default:
        this.routeProfileUser();
        break;
    }
  }

  routeProfileUser() {
    void this.router.navigate([this.USER_LIST_PATH, this.userId, this.EDIT_USER_PATH]);
  }

  routeMemberships() {
    void this.router.navigate([this.USER_LIST_PATH, this.userId, this.MEMBERSHIPS_PATH]);
  }

  routeUserHistory() {
    void this.router.navigate([this.USER_LIST_PATH, this.userId, this.USER_HISTORY_PATH]);
  }

  routeUserListPage() {
    void this.router.navigate([this.USER_LIST_PATH]);
  }

  private initActiveTab() {
    const path = this.route.snapshot.firstChild?.routeConfig?.path;
    switch (path) {
      case this.MEMBERSHIPS_PATH:
        this.activeItem = this.tabItems[1];
        break;
      case this.USER_HISTORY_PATH:
        this.activeItem = this.tabItems[2];
        break;
      default:
        this.activeItem = this.tabItems[0];
        break;
    }
  }
}
