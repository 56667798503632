import { ChipRendererComponent, ChipRendererConfig, ColumnDefinition, ColumnRendererComponent } from '@gea/digital-ui-lib';

export const columnDefinitions: ColumnDefinition[] = appendSettings([
  createTextColumn('X.LABEL.NAME', 'name', 300),
  createTextColumn('X.LABEL.DISPLAY-NAME', 'displayName', 150),
  createTextColumn('X.LABEL.DESCRIPTION', 'description', 300),
]);

function createTextColumn(displayName: string, key: string, minWidth?: number) {
  return {
    displayName,
    minWidth,
    key,
    sortable: false,
  };
}

function appendSettings(columns: ColumnDefinition[]): ColumnDefinition[] {
  return [
    ...columns,
    {
      displayName: 'X.LABEL.STATUS',
      key: 'status',
      minWidth: 300,
      renderer: {
        component: ChipRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          mapping: {
            ACTIVE: {
              severity: 'success',
              text: 'USERS.LIST.STATUS.ACTIVE',
            },
            INACTIVE: {
              severity: 'default',
              text: 'USERS.LIST.STATUS.INACTIVE',
            },
          },
        } as ChipRendererConfig,
      },
    },
  ];
}
