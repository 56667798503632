import { Component, Input, OnInit } from '@angular/core';
import { ColumnDefinition, ComplexDialogV2Service, PermissionKey, PermissionsState, SiteResponse } from '@gea/digital-ui-lib';
import { organizationSitesColumnConfig } from '../../models/organization-sites-column.config';
import { OrganizationSiteEditModalComponent } from './modals/edit-site/organization-site-edit-modal.component';
import { map } from 'rxjs/operators';
import { Observable, startWith } from 'rxjs';
import { OrganizationDetailService } from '../services/organization-detail.service';
import { Store } from '@ngxs/store';
import { SiteModel } from '@gea-id/shared';

@Component({
  selector: 'gea-id-organization-sites',
  templateUrl: './organization-sites.component.html',
  styleUrl: './organization-sites.component.scss',
})
export class OrganizationSitesComponent implements OnInit {
  public readonly TABLE_ID = 'admin-organization-sites';
  public data: SiteResponse[] = [];
  public totalRecords = 0;
  public _columnDefinitions: ColumnDefinition[] = organizationSitesColumnConfig;
  public loading = true;
  @Input() private orgaId = '';

  get columnDefinitions(): Observable<ColumnDefinition[]> {
    return this.canEdit.pipe(map((canEdit) => this._columnDefinitions.filter((column) => column.key !== 'edit' || canEdit)));
  }

  constructor(
    private complexDialog: ComplexDialogV2Service,
    private organizationService: OrganizationDetailService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.organizationService.initSites(this.orgaId, {
      preTap: () => (this.loading = true),
      postTap: () => (this.loading = false),
    });
    this.organizationService.sites$.subscribe((result) => {
      this.data = result ?? [];
    });
  }

  openAddSitesModal() {
    this.complexDialog.open(
      {
        title: 'X.LABEL.ADD',
        yes: 'X.BUTTON.ADD',
        no: 'X.BUTTON.CANCEL',
        data: { orgaId: this.orgaId },
        confirmCallback: (data: unknown) => {
          this.addSite(data as SiteModel);
        },
      },
      OrganizationSiteEditModalComponent
    );
  }

  get canEdit(): Observable<boolean> {
    return this.organizationService.orgaType$.pipe(map((type) => !!type?.orgaFlags.includes('EDIT_SITES')));
  }

  get hasNoUpdatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return !permissions.includes(PermissionKey.UPDATE_ORGANIZATION);
      })
    );
  }

  addSite(data: SiteModel) {
    this.loading = true;
    this.organizationService.addSite(this.orgaId, data).subscribe(() => (this.loading = false));
  }

  openEditSitesModal(row: SiteModel) {
    this.complexDialog.open(
      {
        title: 'X.LABEL.EDIT',
        yes: 'X.BUTTON.EDIT',
        no: 'X.BUTTON.CANCEL',
        data: { orgaId: this.orgaId, site: row },
        confirmCallback: (data: unknown) => {
          this.editSite(data as SiteResponse);
        },
      },
      OrganizationSiteEditModalComponent
    );
  }

  private editSite(data: SiteResponse) {
    if (!data.id) {
      throw new Error('Site id is not set');
    }

    this.loading = true;
    this.organizationService.editSite(this.orgaId, data.id, data).subscribe(() => (this.loading = false));
  }
}
