import {
  ColumnDefinition,
  ColumnRendererComponent,
  IconRendererComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';

const prefix = 'X.LABEL.';
export const organizationSitesColumnConfig: ColumnDefinition[] = [
  {
    displayName: prefix + 'NAME',
    key: 'name',
    sortable: false,
    width: 200,
    minWidth: 150,
  },
  {
    displayName: prefix + 'PHONE',
    key: 'phoneNumber',
    sortable: false,
    width: 100,
    minWidth: 100,
  },
  {
    displayName: prefix + 'STREET',
    key: 'address.street',
    sortable: false,
    width: 200,
    minWidth: 200,
  },
  {
    displayName: prefix + 'ZIP',
    key: 'address.zipCode',
    sortable: false,
    width: 100,
    minWidth: 100,
  },
  {
    displayName: prefix + 'CITY',
    key: 'address.city',
    sortable: false,
    width: 150,
    minWidth: 150,
  },
  {
    displayName: prefix + 'COUNTRY',
    key: 'address.country',
    sortable: false,
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'COUNTRIES.',
      },
    },
    width: 150,
    minWidth: 150,
  },
  {
    displayName: '',
    key: 'edit',
    sortable: false,
    minWidth: 50,
    renderer: {
      component: IconRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        mapping: {
          '': '16px_edit',
        },
      },
    },
  },
];
