import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';

import { TranslationDeployment } from '../../services';

@Component({
  selector: 'gea-id-workspace-appname-renderer',
  templateUrl: './appname-renderer.component.html',
})
export class AppnameRendererComponent implements ColumnRendererComponent<TranslationDeployment> {
  rowData?: TranslationDeployment;

  getKey(): string {
    const projectName = this.rowData?.projectName ?? 'UNKNOWN';
    return 'ADMINISTRATION_TASKS.APP_OPTIONS.' + projectName.toUpperCase();
  }
}
