<form [formGroup]="form">
  <div class="geaui-grid">
    <geaui-input-v2
      class="geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12"
      [required]="true"
      [group]="form"
      formName="name"
      label="APPLICATION.MODAL.FORM.NAME">
    </geaui-input-v2>
      <p class="error-message gea-font-body-12 geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12"
        *ngIf="form.get('name')?.errors?.['startsWithAppName']"> {{ form.get('name')?.errors?.['startsWithAppName'] }} </p>
    <geaui-input-v2
      class="geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12"
      [required]="true"
      [group]="form"
      formName="displayName"
      label="X.LABEL.DISPLAY-NAME">
    </geaui-input-v2>
    <geaui-textfield-v2
      class="geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12"
      [required]="true"
      [group]="form"
      formName="description"
      label="X.LABEL.DESCRIPTION">
    </geaui-textfield-v2>
    <geaui-radio-group-v2
      class="geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12"
      [flexDirection]="'row'" [group]="form" [options]="statusOptions" label="X.LABEL.STATUS" formName="status">
    </geaui-radio-group-v2>
@if (isEditing) {
      <div class="divider geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12">
      </div>
      <geaui-link-v2
        class="geaui-col-desktop-sm-12 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12"
        (click)="delete()"
        preIcon="trash-can"
        type="cancel-red">
        {{ 'X.BUTTON.DELETE' | translate }}
      </geaui-link-v2>
    }
  </div>
</form>
