import { Component, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  ListResponse,
  ContactNotifcationRequest,
  ContactMessageApiService,
  SnackbarService,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { organizationColumnConfig } from './models/organizations-column.config';
import { FormBuilder, Validators } from '@angular/forms';
import { switchMap, tap } from 'rxjs';
import { OrgaData, OrganizationService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrl: './send-notification.component.scss',
})
export class SendNotificationComponent implements OnInit {
  protected TABLE_ID = 'admin-notification-select-list';
  protected data: OrgaData[] = [];
  protected columns: ColumnDefinition[] = organizationColumnConfig;
  protected totalRecords = 0;
  protected loading = true;
  protected sending = false;

  form = this.fb.group({
    subject: ['', [Validators.required]],
    message: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(1000)]],
    organizations: [[] as OrgaData[], [Validators.required, Validators.minLength(1)]],
  });

  constructor(
    private tableService: TableServiceV2,
    private organizationService: OrganizationService,
    private contactService: ContactMessageApiService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private errorHandler: ErrorHandlerV2Service
  ) {}

  ngOnInit(): void {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(
        tap(() => (this.loading = true)),
        switchMap((filter) => this.organizationService.getOrganizationsV2(filter))
      )
      .subscribe((response: ListResponse<OrgaData[]>) => {
        this.data = response.pageEntries;
        this.totalRecords = response.entryCount;
        this.loading = false;
      });
  }

  changeSelection(event: OrgaData[]) {
    this.form.patchValue({
      organizations: event,
    });
    this.form.updateValueAndValidity();
  }

  sendNotification() {
    if (this.form.invalid) {
      throw new Error('Trying to send invalid Form');
    }

    this.sending = true;
    const orgaIds = this.form.value.organizations?.map((orga) => orga.orgaId) ?? [];
    const notification: ContactNotifcationRequest = {
      to: orgaIds.map((orgaId) => ({
        orgaId: orgaId,
      })),
      subject: this.form.value.subject ?? '',
      message: this.form.value.message ?? '',
    };
    this.contactService.sendNotification(notification).subscribe({
      next: () => {
        this.snackbar.add({
          summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
          severity: 'success',
        });
        this.form.reset();
        this.sending = false;
      },
      error: (error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        this.sending = false;
      },
    });
  }
}
