import { debounceTime } from 'rxjs/operators';

import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { COMPLEX_DIALOG_INPUT_DATA, ComplexDialogEmbeddedView, ComplexDialogV2Service, Customer } from '@gea/digital-ui-lib';
import { FormBuilder } from '@angular/forms';
import { catchError, filter, of, startWith, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CustomerAdminApiService } from '../../../../../services/customer-admin-api.service';

@Component({
  selector: 'gea-id-customer-selection',
  templateUrl: './customer-selection.component.html',
  styleUrls: ['./customer-selection.component.scss'],
})
export class CustomerSelectionComponent implements OnInit, ComplexDialogEmbeddedView {
  customer?: Customer;
  loading = false;
  initialNumber: string;

  form = this.formBuilder.group({
    customerNumber: ['', []],
  });

  get customerNumber() {
    return this.form.value.customerNumber;
  }

  constructor(
    private destroyRef: DestroyRef,
    private customerAdminService: CustomerAdminApiService,
    private formBuilder: FormBuilder,
    private complexDialog: ComplexDialogV2Service,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) data?: string
  ) {
    this.initialNumber = data ?? '';
  }

  ngOnInit(): void {
    this.form.patchValue({ customerNumber: this.initialNumber });
    this.form.controls.customerNumber.valueChanges
      .pipe(
        startWith(this.initialNumber),
        tap((customerNumber) => {
          if (customerNumber) {
            this.loading = true;
          }
          this.customer = undefined;
        }),
        debounceTime(1000),
        filter((x): x is string => !!x),
        switchMap((value) => this.customerAdminService.getCustomer(value).pipe(catchError(() => of(undefined)))),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (result) => {
          this.loading = false;
          if (result?.id) {
            this.customer = result;
            if (this.customer.linkedOrganization) {
              this.form.setErrors({ linkedOrganizationFound: true });
            }
          } else {
            this.form.setErrors({ noCustomerFound: true });
          }
        },
      });
  }

  onAcceptClick() {
    if (this.form.valid) {
      this.complexDialog.emitDataOutputForComponent({
        customerNumber: this.form.value.customerNumber,
        customer: this.customer,
      });
    }
  }

  get isAcceptDisabled(): boolean {
    return this.form.invalid || this.loading;
  }

  protected readonly top = top;
}
