import { Membership, MembershipState } from '@gea/digital-ui-lib';

export function sortMemberships(membershipData: Membership[]): Membership[] {
  return membershipData.sort((a, b) => {
    if (a.state !== b.state) {
      return a.state === MembershipState.PENDING ? -1 : 1;
    }
    if (a.inherited !== b.inherited) {
      return a.inherited ? 1 : -1;
    }

    const orgA = a.organizationName || '';
    const orgB = b.organizationName || '';
    const orgComparison = orgA.localeCompare(orgB);
    if (orgComparison !== 0) {
      return orgComparison;
    }

    const roleA = a.roleName || '';
    const roleB = b.roleName || '';
    return roleA.localeCompare(roleB);
  });
}
