import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import {
  ButtonV2Module,
  ContentLayoutComponent,
  TableV2Module,
  SideModalV2Module,
  PageHeaderModule,
  TabMenuV2Module,
  AttachmentsV2Module,
  PageContentLayoutComponent,
  InputV2Module,
  SelectV2Module,
  ErrorHandlerV2Service,
  SnackbarService,
  TableServiceV2,
  ApiErrorResponse,
  FilterTableSettings,
  ListResponse,
  ComplexDialogV2Service,
  SnackbarMessage,
} from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { columnDefinitions } from '../../models/applications-feature-flags-column.config';
import { IFeatureFlag } from '../../../feature-flags/models/feature-flag.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap, switchMap, map, finalize, first } from 'rxjs';
import { FeatureFlagService } from '../../../feature-flags/services/feature-flag.service';
import { FeatureFlagModalComponent } from './feature-flag-modal/feature-flag-modal.component';
import { ApplicationService } from '../../services/application.service';
import { IApplication } from '../../models/application.model';

@Component({
  selector: 'gea-id-workspace-application-feature-flags',
  standalone: true,
  imports: [
    CommonModule,
    ButtonV2Module,
    ContentLayoutComponent,
    TableV2Module,
    SideModalV2Module,
    TranslateModule,
    PageHeaderModule,
    TabMenuV2Module,
    ContentLayoutComponent,
    AttachmentsV2Module,
    PageContentLayoutComponent,
    RouterOutlet,
    ReactiveFormsModule,
    InputV2Module,
    SelectV2Module,
  ],
  templateUrl: './application-feature-flags.component.html',
  styleUrl: './application-feature-flags.component.scss',
})
export class ApplicationFeatureFlagsComponent implements OnInit {
  @Input({ required: true }) appId = '';
  public data: IFeatureFlag[] = [];
  public readonly TABLE_ID = 'applications-feature-flags-table';
  public columnDefinitions = columnDefinitions;

  protected currentApp: IApplication = {
    id: '',
    displayName: '',
    name: '',
    appType: '',
    division: '',
    imagePath: '',
    createdAt: '',
    createdBy: '',
    verificationNeeded: false,
    appKey: '',
    hasTranslation: false,
  };
  loading = false;

  constructor(
    private tableService: TableServiceV2,
    private featureFlagService: FeatureFlagService,
    private dialogService: ComplexDialogV2Service,
    private snackbarService: SnackbarService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandlerV2Service,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.applicationService.getCurrentApplication().subscribe((application: IApplication | null) => {
      if (application) this.currentApp = application;
    });

    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(
        tap(() => (this.loading = true)),
        takeUntilDestroyed(this._destroyRef),
        switchMap((filter: FilterTableSettings) => {
          const updatedFilter: FilterTableSettings = {
            ...filter,
            columns: {
              name: {
                filter: [this.currentApp.name],
              },
            },
          };
          return this.featureFlagService
            .getAllFeatureFlags(updatedFilter)
            .pipe(map((response: ListResponse<IFeatureFlag[]>) => response.pageEntries));
        })
      )
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.data = response;
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.errorHandler.handleError(error);
        },
      });

    this.featureFlagService.featureFlags$.subscribe((result) => {
      this.data = result.pageEntries;
    });
  }
  openAdd() {
    this.dialogService.open(
      {
        title: 'FEATURE.FLAGS.ADD.MODAL.LABEL',
        yes: 'X.BUTTON.ADD',
        no: 'X.BUTTON.CANCEL',
        confirmCallback: (data: unknown) => {
          this.add(data as IFeatureFlag);
        },
      },
      FeatureFlagModalComponent
    );
  }

  add(data: IFeatureFlag) {
    this.loading = true;
    this.featureFlagService
      .addFeatureFlag(data)
      .pipe(
        first(),
        takeUntilDestroyed(this._destroyRef),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          const message: SnackbarMessage = {
            detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            severity: 'success',
          };
          this.snackbarService.add(message);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
        },
      });
  }

  openEdit(event: IFeatureFlag) {
    this.dialogService.open(
      {
        title: 'FEATURE.FLAGS.MODAL.DETAILS',
        yes: 'X.BUTTON.SAVE',
        no: 'X.BUTTON.CANCEL',
        confirmCallback: (data: unknown) => {
          this.edit(event.id, data as IFeatureFlag);
        },
        data: event,
      },
      FeatureFlagModalComponent
    );
  }

  edit(id: string, data: IFeatureFlag) {
    this.loading = true;
    this.featureFlagService
      .updateFeatureFlag(id, data)
      .pipe(
        first(),
        takeUntilDestroyed(this._destroyRef),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          const message: SnackbarMessage = {
            detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            severity: 'success',
          };
          this.snackbarService.add(message);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
        },
      });
  }
}
