import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '@gea/digital-ui-lib';
import { DashboardInfo } from '../model/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  appUrl = 'application/publicDashboard/dashboard';
  apiVersion = '1';

  constructor(private api: ApiService) {}

  getDefaultDashboardInfos(): Observable<DashboardInfo[]> {
    return this.api.get<DashboardInfo[]>(this.appUrl + `?type[equals]=DefaultDashboard`, {}, this.apiVersion);
  }
}
