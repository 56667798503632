import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ComplexDialogV2Service,
  ErrorHandlerV2Service,
  FilterTableSettings,
  PermissionKey,
  PermissionsState,
  SnackbarMessage,
  SnackbarService,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { Observable, startWith, switchMap, tap } from 'rxjs';
import { columnDefinitions } from '../models/columns.config';
import { IFeatureFlagAssignment, NewFeatureFlagAssignment } from '../models/feature-flag-assignment.model';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeatureFlagModalComponent } from '../modal/feature-flag-modal.component';
import { FeatureFlagService } from '../services/feature-flag.service';
import { AssignmentTypeModel } from '../models/assignment-type.model';

@Component({
  selector: 'gea-id-feature-flags',
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss'],
})
export class FeatureFlagsComponent implements OnInit {
  public readonly TABLE_ID = 'feature-flags-table';
  public featureFlagsData$: Observable<IFeatureFlagAssignment[]> = this.featureFlagService.featureFlagsAssignements$.pipe(
    map((data) => data.pageEntries)
  );
  public totalRecords$: Observable<number> = this.featureFlagService.featureFlagsAssignements$.pipe(
    map((data) => data.entryCount)
  );
  public columnDefinitions = this.hasUpdatePermission.pipe(
    map((canEdit) => columnDefinitions.filter((column) => column.key !== 'actions' || canEdit))
  );
  public loading = false;
  public userPermissions: string[] = [];

  constructor(
    private featureFlagService: FeatureFlagService,
    private tableService: TableServiceV2,
    private dialogService: ComplexDialogV2Service,
    private snackbarService: SnackbarService,
    private errorHandlerV2Service: ErrorHandlerV2Service,
    private store: Store,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store.select(PermissionsState.userPermissions).subscribe((result: PermissionKey[]) => {
      this.userPermissions = result;
    });

    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(
        tap(() => (this.loading = true)),
        takeUntilDestroyed(this._destroyRef),
        switchMap((filter: FilterTableSettings) => this.featureFlagService.init(filter))
      )
      .subscribe(() => (this.loading = false));
  }

  openAdd() {
    this.dialogService.open(
      {
        title: 'X.LABEL.ADD',
        yes: 'X.BUTTON.ADD',
        no: 'X.BUTTON.CANCEL',
        confirmCallback: (data: unknown) => {
          this.add(data as NewFeatureFlagAssignment);
        },
      },
      FeatureFlagModalComponent
    );
  }

  add(data: NewFeatureFlagAssignment) {
    this.featureFlagService.add(data).subscribe({
      next: () => {
        const message: SnackbarMessage = {
          detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
          summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          severity: 'success',
        };
        this.snackbarService.add(message);
      },
      error: (error: ApiErrorResponse) => {
        this.errorHandlerV2Service.handleError(error);
      },
    });
  }

  openEdit(assignment: IFeatureFlagAssignment) {
    if (assignment.assignmentType === AssignmentTypeModel.ORGANIZATION_TYPE) return;

    this.dialogService.open(
      {
        title: 'X.LABEL.EDIT',
        yes: 'X.BUTTON.EDIT',
        no: 'X.BUTTON.CANCEL',
        data: assignment,
        confirmCallback: (data: unknown) => {
          this.edit(assignment.id, data as NewFeatureFlagAssignment);
        },
      },
      FeatureFlagModalComponent
    );
  }

  edit(id: string, data: NewFeatureFlagAssignment) {
    this.featureFlagService.update(id, data).subscribe({
      next: () => {
        this.snackbarService.add({
          detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
          summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          severity: 'success',
        });
      },
      error: (error: ApiErrorResponse) => {
        this.errorHandlerV2Service.handleError(error);
      },
    });
  }

  get hasUpdatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return permissions.includes(PermissionKey.MANAGE_FEATURE_FLAG_ASSIGNMENTS);
      })
    );
  }
}
