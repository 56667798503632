import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { OrganizationDetailService } from './services/organization-detail.service';
import { map } from 'rxjs/operators';
import { HelpPanel } from '@gea/digital-ui-lib';
import { NEW_ORGA_ID } from '../models/organizations.routing';

@Component({
  selector: 'gea-id-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrl: './organization-detail.component.scss',
})
export class OrganizationDetailComponent implements OnInit, OnDestroy {
  @Input({ required: true }) orgaId = '';

  tabItems: Observable<MenuItem[]> = this.organizationDetailService.tabMenu$.pipe(
    tap((tabs) => {
      this.help = undefined;
      if (this.orgaId === NEW_ORGA_ID) {
        this.help = {
          title: 'HELP.ORGANIZATION.ADD.TITLE',
          body: 'HELP.ORGANIZATION.ADD.PAGE',
        };
      } else {
        const tab = tabs.find((tab) => this.router.url.includes(tab.routerLink));
        if (tab?.help)
          this.help = {
            title: tab?.helpTitle,
            body: tab?.help,
          };
      }
    })
  );

  orgaName = 'X.LABEL.ORGANISATION';
  help?: HelpPanel = { body: '' };

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private organizationDetailService: OrganizationDetailService
  ) {}

  ngOnInit() {
    this.organizationDetailService.init(this.orgaId);
    this.organizationDetailService.update({ orgaId: this.orgaId });
    this.organizationDetailService.initialOrgaData$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((data) => !!data),
        map((data) => data.name)
      )
      .subscribe((name: string) => {
        this.orgaName = name;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  changeTab($event?: MenuItem) {
    if (!$event) return;
    if ($event['help'])
      this.help = {
        title: $event['helpTitle'] as string,
        body: $event['help'] as string,
      };
    else this.help = undefined;
    void this.router.navigate(['organization', this.orgaId, $event.routerLink]);
  }

  routeOrgaList() {
    void this.router.navigate(['organization']);
  }
}
