@if (!loading) {
  <div class="main-container">
    <div class="title flex">
      <span suffix>{{ 'ORGANIZATION.DETAIL.INFO.BASIC.CUSTOMER_NUMBER.TITLE.LABEL' | translate }}</span>
      <geaui-button-v2 [disabled]="disabled || internalLoading" (click)="openCustomerSelection()" type="secondary-transparent">
        @if (customerNumber) {
          <span class="margin-y-8" prefix>{{ 'X.LABEL.EDIT' | translate }}</span>
        } @else {
          <span class="margin-y-8" prefix>{{ 'ORGANIZATION.DETAIL.INFO.BASIC.CUSTOMER_NUMBER.CONNECT' | translate }}</span>
        }
      </geaui-button-v2>
    </div>
    <div class="content">
      <div>
        @if (internalLoading) {
          <p-progressSpinner class="center" animationDuration="2s" strokeWidth="4"></p-progressSpinner>
        } @else if (customer) {
          <div class="font-bold">{{ customerNumber }}</div>
          <div class="font-bold">{{ customer.name }}</div>
          <div>{{ customer.address.street }} {{ customer.address.zipCode }} {{ customer.address.city }}</div>
        } @else {
          <geaui-icon-v2 file="16px_c-info">
            <div class="margin-y-8" suffix>
              {{ 'ORGANIZATION.DETAIL.INFO.BASIC.CUSTOMER_NUMBER.NO_CUSTOMER.LABEL' | translate }}
            </div>
          </geaui-icon-v2>
        }
      </div>
    </div>
  </div>
} @else {
  <ngx-skeleton-loader [theme]="{ height: '100%', width: '100%' }" count="1" appearance="line"></ngx-skeleton-loader>
}
