import { NgModule } from '@angular/core';
import { OrganizationsListPageComponent } from './organizations-list-page/organizations-list-page.component';
import {
  AutocompleteSelectV2Module,
  ButtonV2Module,
  CheckboxV2Module,
  ContentLayoutComponent,
  IconModule,
  InputV2Module,
  LinkV2Module,
  MultiSelectV2Module,
  PageContentLayoutComponent,
  PageFooterComponent,
  PageHeaderModule,
  SearchBarV2Module,
  SelectV2Module,
  TableV2Module,
  TabMenuV2Module,
  ChipModule,
} from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationDetailComponent } from './organization-detail/organization-detail.component';
import { RouterOutlet } from '@angular/router';
import { OrganizationInfoComponent } from './organization-detail/organization-info/organization-info.component';
import { OrganizationUsersComponent } from './organization-detail/organization-users/organization-users.component';
import { OrganizationHistoryComponent } from './organization-detail/organization-history/organization-history.component';
import { OrganizationBusinessRelationsComponent } from './organization-detail/organization-business-relations/organization-business-relations.component';
import { OrganizationSitesComponent } from './organization-detail/organization-business-sites/organization-sites.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { AsyncPipe, NgIf } from '@angular/common';
import { CustomerLinkComponent, CustomerSelectionComponent } from './organization-detail/organization-info/customer-link';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BusinessRelationsEditModalComponent } from './organization-detail/organization-business-relations/modals/edit-relations/business-relations-edit-modal.component';
import { OrganizationSiteEditModalComponent } from './organization-detail/organization-business-sites/modals/edit-site/organization-site-edit-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppEnabledComponent } from './organization-detail/organization-info/app-enabled/app-enabled.component';

@NgModule({
  declarations: [
    OrganizationsListPageComponent,
    OrganizationDetailComponent,
    OrganizationInfoComponent,
    OrganizationUsersComponent,
    OrganizationHistoryComponent,
    OrganizationBusinessRelationsComponent,
    OrganizationSitesComponent,
    CustomerLinkComponent,
    CustomerSelectionComponent,
    BusinessRelationsEditModalComponent,
    OrganizationSiteEditModalComponent,
    AppEnabledComponent,
  ],
  imports: [
    ButtonV2Module,
    ContentLayoutComponent,
    TableV2Module,
    TranslateModule,
    PageHeaderModule,
    TabMenuV2Module,
    RouterOutlet,
    ReactiveFormsModule,
    InputV2Module,
    SelectV2Module,
    AutocompleteSelectV2Module,
    DividerModule,
    CheckboxV2Module,
    AsyncPipe,
    LinkV2Module,
    SearchBarV2Module,
    IconModule,
    NgIf,
    ProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    PageContentLayoutComponent,
    PageFooterComponent,
    MultiSelectV2Module,
    NgxSkeletonLoaderModule,
    NgxSkeletonLoaderModule,
    NgIf,
    ChipModule,
  ],
  providers: [],
})
export class OrganizationsModule {}
