import {
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  HeaderRendererComponent,
  IconRendererComponent,
  TextFilterComponent,
  TranslationRendererComponent,
  VisibilitySettingsRendererComponent,
} from '@gea/digital-ui-lib';

export const columnDefinitions: ColumnDefinition[] = appendSettings([
  createTextColumn('FEATURE.FLAG.COLUMN.NAME', 'featureFlagDisplayName', 400),
  {
    displayName: 'FEATURE.FLAG.ASSIGNMENT.TYPE.COLUMN.NAME',
    minWidth: 200,
    key: 'assignmentType',
    filter: {
      component: TextFilterComponent,
    },
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.ASSIGNMENT_TYPE.',
      },
    },
  },
  createTextColumn('FEATURE.FLAG.ASSIGNED.TO.COLUMN.NAME', 'assignedToLabel', 300),
  createDateColumn('X.LABEL.CREATED_DATE', 'createdAt'),
  createTextColumn('FEATURE.FLAG.CREATED.BY.COLUMN.NAME', 'createdBy', 300),
]);

function createTextColumn(displayName: string, key: string, minWidth?: number) {
  return {
    displayName,
    minWidth,
    key,
    filter: {
      component: TextFilterComponent,
    },
  };
}

function createDateColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    minWidth: 250,
    filter: {
      component: TextFilterComponent,
    },
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'short',
        locale: 'en-US',
      },
    },
  };
}

function appendSettings(columns: ColumnDefinition[]): ColumnDefinition[] {
  return [
    ...columns,
    {
      displayName: '',
      key: 'actions',
      resizeable: false,
      frozen: 'right',
      sortable: false,
      width: 50,
      renderer: {
        component: IconRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          mapping: {
            '': '16px_edit',
          },
        },
      },
      headerRenderer: {
        component: VisibilitySettingsRendererComponent as HeaderRendererComponent<unknown>,
        config: {
          columns: columns.map(
            (column): ColumnDefinition => ({
              displayName: column.displayName,
              key: column.key,
            })
          ),
        },
      },
    },
  ];
}
