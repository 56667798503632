import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderRendererComponent } from '@gea/digital-ui-lib';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-id-workspace-checkbox-header-renderer',
  standalone: true,
  imports: [CommonModule, TableModule, TranslateModule],
  template: `
    <div class="tableHeaderDiv">
      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      <span class="tableHeaderLabel" *ngIf="displayName">{{ displayName | translate }}</span>
    </div>
  `,
  styles: `
    .tableHeaderLabel {
      margin-left: 8px;
      font-weight: 500;
    }
    .tableHeaderDiv {
      display: flex;
      align-items: center;
    }
  `,
})
export class CheckboxHeaderRendererComponent<T> implements HeaderRendererComponent<T> {
  displayName = '';
}
