<geaui-page-content-layout [useDefaultPadding]="true">
  <ng-container page-content>
    <div class="title gea-font-heading-20">
      {{ 'APPLICATION.DETAIL.PATHS.SECRETS' | translate }}
    </div>
    <div class="flex">
      @if (secrets.length > 0) {
        <div *ngFor="let secret of secrets">
          <gea-id-secret-card
            [secret]="secret"
            [loading]="loading"
            [isSending]="isSending"
            (renew)="renewSecret(secret.displayName)"></gea-id-secret-card>
        </div>
      } @else {
        <div class="gea-font-subline-16">{{ 'APPLICATIONS.NO-SECTRS.MESSAGE' | translate }}</div>
      }
    </div>
  </ng-container>
</geaui-page-content-layout>
