import { Injectable } from '@angular/core';

import { ApiService, ApiServiceInterface } from '@gea/digital-ui-lib';

import { ProjectName, TranslationDeployment } from './models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationApiService implements ApiServiceInterface {
  url = 'translation-service/v1/translations'; // e.g. https://api.apps.dev.gea.com/portal-admin/translation
  apiVersion?: string;

  constructor(private api: ApiService) {
    this.setUrl(this.url);
  }

  deploy(projectName: ProjectName) {
    return this.api.put(this.url + '/deploy', { projectName }); // e.g. https://api.apps.dev.gea.com/portal-admin/translation/deploy
  }

  setUrl(url?: string) {
    if (url) {
      this.url = url ?? this.url;
    }
    return this;
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }

  fetchDeployments(): Observable<TranslationDeployment[]> {
    return this.api.get<TranslationDeployment[]>(this.url + '/deployments/byProject', {}, this.apiVersion).pipe(
      map((response) => response ?? []),
      map((deployments) => deployments.sort(this.byDate.bind(this)))
    );
  }

  private byDate(a: TranslationDeployment, b: TranslationDeployment): number {
    return a.lastDeploymentDate > b.lastDeploymentDate ? -1 : 1;
  }
}
