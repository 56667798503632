import { Membership } from '@gea/digital-ui-lib';
import { Timezone } from './timezone';

export interface InvitationUpdate {
  email?: string;
  firstName: string;
  lastName: string;
  language: string;
  phoneNumber: string;
  title: string;
  timezone: Timezone;
  memberships: Membership[];
}
