import { Injectable } from '@angular/core';
import { ErrorHandlerV2Service, InviteableResponse, Membership } from '@gea/digital-ui-lib';
import { catchError, first, Observable, of, Subject, tap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { InviteMembership, UserInviteData } from '../model';
import { UserService } from './user.service';
import { CustomerUserTypeDto } from '../../generated/api/v1';

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {
  private _userData: UserInviteData = {
    firstName: '',
    lastName: '',
    email: '',
    language: '',
    dashboardId: '',
    customerUserType: CustomerUserTypeDto.BASIC,
  };
  private _membershipsData: InviteMembership[] = [];
  private _deleteMembershipAction = new Subject<string>();

  constructor(
    private errorHandlerService: ErrorHandlerV2Service,
    private userService: UserService
  ) {}

  get userData() {
    return this._userData;
  }

  set userData(user: UserInviteData) {
    this._userData = user;
  }

  set membershipsData(memberships: InviteMembership[]) {
    this._membershipsData = memberships;
  }

  get membershipsData() {
    return this._membershipsData;
  }

  emitDeleteMembership(inviteMembershipId: string) {
    this._deleteMembershipAction.next(inviteMembershipId);
  }

  deleteMembershipAction$() {
    return this._deleteMembershipAction.asObservable();
  }

  createUser(): Observable<unknown> {
    return this.userService
      .createUser({
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        customerUserType: this.userData.customerUserType,
        email: this.userData.email,
        language: this.userData.language,
        dashboardId: this.userData.dashboardId,
        memberships: this.membershipsData as unknown as Membership[],
      })
      .pipe(
        tap(() => this.clearUserData()),
        catchError((err: HttpErrorResponse) => {
          this.errorHandlerService.handleError(err);
          return throwError(() => err);
        }),
        first()
      );
  }

  isInvitable(): Observable<boolean> {
    return this.userService.isUserInvitable(this.userData.email).pipe(
      map((result: InviteableResponse) => result.inviteable),
      catchError((err: HttpErrorResponse) => {
        this.errorHandlerService.handleError(err);
        return of(false);
      }),
      first()
    );
  }

  private clearUserData() {
    this._userData = {
      firstName: '',
      lastName: '',
      email: '',
      language: '',
      dashboardId: '',
      customerUserType: CustomerUserTypeDto.BASIC,
    };
    this._membershipsData = [];
  }
}
