<geaui-page-layout [headerTemplate]="header" [contentTemplate]="content" [sidebarTemplate]="sidebar">
  <ng-template #header>
    <geaui-header
      *ngIf="noAuth !== undefined"
      [isAdmin]="true"
      [noAuth]="noAuth || isAboutGEARoute()"
      [showLangDropdown]="true"
      [canOpenBurgerMenu]="false"
      [showBanner]="false"
      headerTitle="Admin App"></geaui-header>
  </ng-template>

  <ng-template #content>
    <!--
        Loading Spinner will be shown in three cases:
        Case 1: Auth Page and Token & User are not ready
        Case 2: No Auth Page and User is logged in, but Token & User are not ready yet
        Case 3: No Auth Page and User is not logged in,

        Case 2 & 3 deal with views that can be accessed without authentication (noAuth).
        For those views the user either could be logged in (Case 2) or logged out (Case 3)
        for the logged in user we display the spinner until the token % user are ready.
        For the logged out user we wait until MSAL confirms that the user is logged out.
    -->
    <div
      class="spinner"
      *ngIf="(!tokenReady || !userReady) && !loggedOutPageReady">
      <p-progressSpinner animationDuration="2s" strokeWidth="4"> </p-progressSpinner>
    </div>
    <div class="content-body" [ngClass]="layout.screenClasses | async">
      <router-outlet></router-outlet>
    </div>
  </ng-template>

  <ng-template #sidebar>
    @if (!isAboutGEARoute() && !noAuth && tokenReady) {
      <geaui-sidebar-v2
        [navigationItems]="navItems"
        [slimView]="false"
        [bottomNavigation]="botItems"
        [devisionIconPath]="environment.appContentStorageURL + 'core/portal/icons/24px_portal.svg'"
        devisionIconBackgroundColor="var(--geaui-night-blue)"
        devisionName="Admin App"></geaui-sidebar-v2>
    }
  </ng-template>
</geaui-page-layout>
