import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  PermissionKey,
  PermissionsState,
  TableServiceV2,
  User,
} from '@gea/digital-ui-lib';
import { UserService } from '@gea-id/shared';
import { first, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UsersListColumnDefinitionsService } from './services/users-list-column-definitions.service';

@Component({
  selector: 'gea-id-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrl: './users-list-page.component.scss',
})
export class UsersListPageComponent implements OnInit, OnDestroy {
  public TABLE_ID = 'admin-users-table';
  public users: User[] = [];
  public columnDefinitions: ColumnDefinition[] = [];
  public totalRecords = 0;

  public loading = true;
  public subs: Subscription[] = [];

  constructor(
    private usersService: UserService,
    private tableService: TableServiceV2,
    private router: Router,
    private store: Store,
    private errorHandler: ErrorHandlerV2Service,
    private usersListColumnDefinitionsService: UsersListColumnDefinitionsService
  ) {}

  ngOnInit(): void {
    this.columnDefinitions = this.usersListColumnDefinitionsService.usersColumnDefinitions;
    this.subs.push(
      this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter: FilterTableSettings) => {
        this.fetchData(filter);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  fetchData(filter: FilterTableSettings) {
    this.loading = true;
    this.usersService
      .getAllUsersV2(filter)
      .pipe(first())
      .subscribe({
        next: (page) => {
          this.users = page.pageEntries as User[];
          this.totalRecords = page.entryCount;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
  }

  editUser(event: User) {
    void this.router.navigate([`user/${event.id}/edit-user`]);
  }

  addUser() {
    void this.router.navigate([`user/user-invite`]);
  }

  get hasNoCreatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      map((permissions) => {
        return !permissions.includes(PermissionKey.CREATE_USER_INVITATION);
      })
    );
  }
}
