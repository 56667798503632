import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  ErrorHandlerV2Service,
  Membership,
  MembershipState,
  PermissionKey,
  PermissionsState,
  SelectOption,
  SnackbarService,
  SortDirection,
} from '@gea/digital-ui-lib';
import { FormBuilder, Validators } from '@angular/forms';
import { MembershipService, OrgaData, OrganizationService, OWNER_ROLE_ID, UserDetailService } from '@gea-id/shared';
import { filter, first, Observable, of, startWith, tap } from 'rxjs';
import { CultureService } from '@gea-id/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-id-workspace-membership-dialog',
  templateUrl: './membership-dialog.component.html',
  styleUrl: './membership-dialog.component.scss',
})
export class MembershipDialogComponent implements ComplexDialogEmbeddedView, OnInit {
  protected readonly MembershipState = MembershipState;
  isAcceptDisabled = true;
  hasNoOwnerRole = false;
  organisations: OrgaData[] = [];
  organisationOptions: SelectOption<string>[] = [];

  get ownerRoleReassigned(): boolean {
    return this.form.controls.role.value === OWNER_ROLE_ID && !this.hasNoOwnerRole;
  }

  form = this.formBuilder.group({
    organisation: ['', [Validators.required]],
    role: ['', [Validators.required]],
  });
  updatePermission = false;
  loading = true;
  invitationUpdating = false;
  public emptyMessageKey?: string;
  roleOptions: SelectOption<string>[] = [];

  constructor(
    protected translateService: TranslateService,
    private complexDialogService: ComplexDialogV2Service,
    private formBuilder: FormBuilder,
    private userDetailService: UserDetailService,
    private membershipService: MembershipService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackBarService: SnackbarService,
    private cultureService: CultureService,
    private dialogService: DialogV2Service,
    private destroyRef: DestroyRef,
    private store: Store,
    private orgaService: OrganizationService,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) public membershipData?: Membership
  ) {}

  ngOnInit(): void {
    this.isAcceptDisabled = !this.membershipData;

    this.fillFormFields();

    this.form.valueChanges
      .pipe(
        startWith(null), //Run once to trigger subscribe
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.isAcceptDisabled = this.form.invalid || !!this.membershipData;
        this.setRoleOptions();
      });

    this.hasUpdateMembershipPermission()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((updatePermission) => (this.updatePermission = updatePermission));
  }

  private hasUpdateMembershipPermission(): Observable<boolean> {
    return this.store
      .select(PermissionsState.userPermissions)
      .pipe(map((permissions) => permissions.includes(PermissionKey.UPDATE_MEMBERSHIP)));
  }

  onResendInvitation() {
    if (!this.updatePermission) {
      return;
    }
    this.invitationUpdating = true;
    this.membershipService.resendMembership(this.membershipData?.userId ?? '', this.membershipData?.id ?? '').subscribe({
      next: () => this.handleResendInvitationSuccess(),
      error: (error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        this.complexDialogService.close();
      },
    });
  }

  onCancelInvitation() {
    if (!this.updatePermission) {
      return;
    }
    this.invitationUpdating = true;
    this.membershipService.revokeMembership(this.membershipData?.userId ?? '', this.membershipData?.id ?? '').subscribe({
      next: () => this.handleCancelInvitationSuccess(),
      error: (error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        this.invitationUpdating = false;
      },
    });
  }

  showRemoveMembershipDialog() {
    if (this.membershipData?.roleId === OWNER_ROLE_ID) {
      this.dialogService.open({
        title: 'MEMBERSHIPS.LIST.DIALOG.OWNER_ROLE.DELETE_FORBIDDEN.HEADER',
        message: 'MEMBERSHIPS.LIST.DIALOG.OWNER_ROLE.DELETE_FORBIDDEN.CONTENT',
        yes: 'X.BUTTON.CANCEL',
        showRejectButton: false,
      });
    } else {
      this.dialogService.open({
        title: 'X.LABEL.DELETE',
        message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
        yes: 'X.BUTTON.CONFIRM',
        no: 'X.BUTTON.CANCEL',
        buttonTypeYes: 'cancel-red',
        confirmCallback: () => this.removeMembership(),
      });
    }
  }

  onAcceptClick() {
    this.complexDialogService.emitDataOutputForComponent({
      membershipID: this.membershipData?.id ?? '',
      organisation: this.form.getRawValue().organisation,
      role: this.form.getRawValue().role,
      orga: this.organisations.find((orga) => orga.orgaId === this.form.value.organisation),
    });
    this.complexDialogService.close();
  }

  get membershipStateTranslateKey(): string {
    return 'MEMBERSHIPS.LIST.STATE.' + this.membershipData?.state?.toString() ?? '';
  }

  setRoleOptions(): void {
    const orga = this.organisations.find((o) => o.orgaId === this.form.value.organisation);

    this.userDetailService.memberships$
      .pipe(
        tap(() => (this.loading = true)),
        map((membershipsListResponse) => membershipsListResponse?.pageEntries ?? []),
        map((memberships) => {
          return this.cultureService.roles
            .filter((role) => role.enabledOrgaTypes.includes(orga?.type ?? ''))
            .filter(
              (role) =>
                !memberships
                  .filter((membership) => orga?.orgaId === membership.organizationId)
                  .map((membership) => membership.roleId)
                  .includes(role.id) || role.id === this.membershipData?.roleId
            );
        }),
        map((roles) =>
          roles.map((role) => {
            return {
              nameKey: 'X.ROLE.' + (role.name || '').toUpperCase(),
              value: role?.id,
            } as SelectOption<string>;
          })
        ),
        filter((x) => !!x.length || !this.membershipData),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((roles) => {
        this.roleOptions = roles;
        this.loading = false;
      });
  }

  private fillFormFields() {
    if (this.membershipData) {
      this.form.controls.role.disable();
      this.form.controls.organisation.disable();
      this.fetchOrgas(this.membershipData.organizationName ?? '', this.organisationOptions)
        .pipe(first())
        .subscribe(() => {
          this.form.patchValue({
            organisation: this.membershipData?.organizationId,
            role: this.membershipData?.roleId,
          });
        });
    }
  }

  private removeMembership() {
    this.membershipService.deleteMembership(this.membershipData?.userId ?? '', this.membershipData?.id ?? '').subscribe({
      next: () => this.handleMembershipDeleteSuccess(),
      error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
    });
  }

  private handleResendInvitationSuccess() {
    this.userDetailService.resetExpirationOfMembership(this.membershipData?.id);
    this.complexDialogService.close();
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private handleCancelInvitationSuccess() {
    this.complexDialogService.close();
    this.userDetailService.fetchMemberships();
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private handleMembershipDeleteSuccess() {
    this.complexDialogService.close();
    this.userDetailService.fetchMemberships();
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
      severity: 'success',
    });
  }

  getIconFile(): string {
    switch (this.membershipData?.state) {
      case MembershipState.ACCEPTED || MembershipState.INHERITED: {
        return '16px_check';
      }
      case MembershipState.EXPIRED: {
        return '16px_stop';
      }
      default: {
        return '16px_c-info';
      }
    }
  }

  public fetchOrgas = (query: string, options: SelectOption<string>[]): Observable<SelectOption<string>[]> => {
    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return this.orgaService
      .getOrganizationsV2({
        page: 0,
        pageSize: 20,
        columns: {
          name: {
            filter: [query],
            sort: SortDirection.ASCENDING,
          },
        },
      })
      .pipe(
        map((response) => response.pageEntries),
        tap((orgas) => {
          this.organisations = orgas;
          this.emptyMessageKey = undefined;
        }),
        map((orgas) =>
          orgas.map(
            (orga) =>
              ({
                name: orga.name,
                value: orga.orgaId,
              }) as SelectOption<string>
          )
        ),
        tap((options) => (this.organisationOptions = options))
      );
  };

  onSelectOrga($event: string) {
    this.form.controls.role.enable();
    this.hasNoOwnerRole = false;
    const selectedOrga = this.organisations.find((o) => o.orgaId === $event);
    const ownerRoleNull = selectedOrga?.owner === null;
    if (ownerRoleNull) {
      this.hasNoOwnerRole = true;
      this.form.controls.role.patchValue(OWNER_ROLE_ID as string);
      this.form.controls.role.disable();
    }
  }
}
