/**
 * Digital Platform - GEA ID
 * The GEA ID API manages users, memberships, organizations, roles, and more within the Digital Platform.  It provides endpoints for user authentication, role assignments, organization management, and system health checks. 
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FtRegistrationRelation } from './ftRegistrationRelation';
import { Address } from './address';
import { FtRegistrationOrganizationType } from './ftRegistrationOrganizationType';
import { SiteRequest } from './siteRequest';


export interface FtRegistrationOrganization { 
    /**
     * name of the new organization
     */
    name: string;
    shippingAddress: Address;
    billingAddress?: Address;
    type: FtRegistrationOrganizationType;
    sites?: Array<SiteRequest>;
    relations?: Array<FtRegistrationRelation>;
}



