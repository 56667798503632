/**
 * Digital Platform - GEA ID
 * The GEA ID API manages users, memberships, organizations, roles, and more within the Digital Platform.  It provides endpoints for user authentication, role assignments, organization management, and system health checks. 
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationAdminListItemResponse } from './organizationAdminListItemResponse';
import { OrganizationsList200ResponseOneOf1 } from './organizationsList200ResponseOneOf1';
import { OrganizationsList200ResponseOneOf } from './organizationsList200ResponseOneOf';


/**
 * @type OrganizationsList200Response
 * @export
 */
export type OrganizationsList200Response = OrganizationsList200ResponseOneOf | OrganizationsList200ResponseOneOf1;

