import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ButtonV2Module,
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  IconModule,
  InputV2Module,
  LinkV2Module,
  RadioGroupV2Module,
  SnackbarService,
  TextfieldV2Module,
} from '@gea/digital-ui-lib';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IFeatureFlag } from '../../../../feature-flags/models/feature-flag.model';
import { finalize, map, Observable, tap } from 'rxjs';
import { FeatureFlagService } from '../../../../feature-flags/services/feature-flag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationService } from '../../../services/application.service';

@Component({
  selector: 'gea-id-workspace-feature-flag-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonV2Module,
    TranslateModule,
    TextfieldV2Module,
    InputV2Module,
    LinkV2Module,
    AsyncPipe,
    IconModule,
    RadioGroupV2Module,
  ],
  templateUrl: './feature-flag-modal.component.html',
  styleUrl: './feature-flag-modal.component.scss',
})
export class FeatureFlagModalComponent implements OnInit, ComplexDialogEmbeddedView {
  protected statusOptions = [
    { value: 'ACTIVE', name: 'Active' },
    { value: 'INACTIVE', name: 'Inactive' },
  ];
  protected form = this.fb.group({
    name: ['', Validators.required],
    displayName: ['', Validators.required],
    description: ['', Validators.required],
    status: ['',Validators.required],
  });
  constructor(
    private featureFlagService: FeatureFlagService,
    private confirmDialog: DialogV2Service,
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private complexDialogService: ComplexDialogV2Service,
    private applicationService: ApplicationService,
    private _destroyRef: DestroyRef,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) private featureFlag?: IFeatureFlag
  ) {}

  protected helperText = ' ';
  protected isEditing = !!this.featureFlag;

  ngOnInit(): void {
    this.applicationService.getCurrentApplication().subscribe((app) => {
      if (app) {
        const nameControl = this.form.get('name');
        nameControl?.setValidators([
          Validators.required,
          (control: AbstractControl): ValidationErrors | null => {
            const name = control.value as string;
            return !name.startsWith(app.name) ? { startsWithAppName: `Name must start with "${app.name}."`  } : null;
          },
        ]);

        nameControl?.updateValueAndValidity();
      }
    });

    if (this.featureFlag) {
      this.form.patchValue({
        name: this.featureFlag?.name,
        displayName: this.featureFlag?.displayName,
        description: this.featureFlag?.description,
        status: this.featureFlag?.status,
      });
    }
  }

  get isAcceptDisabled() {
    return this.form.invalid || !this.form.dirty;
  }

  onAcceptClick() {
    const request: IFeatureFlag = {
      id: this.featureFlag?.id ?? '',
      name: this.form.value.name ?? '',
      displayName: this.form.value.displayName ?? '',
      description: this.form.value.description ?? '',
      status: this.form.value.status ?? '',
    };
    this.complexDialogService.emitDataOutputForComponent(request);
  }

  delete() {
    this.confirmDialog.open({
      title: 'X.BUTTON.DELETE',
      message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => {
        return this.remove();
      },
    });
  }

  private remove(): Observable<void> {
    if (!this.featureFlag) {
      throw new Error('No feature flag provided');
    }

    return this.featureFlagService.deleteFeatureFlag(this.featureFlag.id).pipe(
      takeUntilDestroyed(this._destroyRef),
      finalize(() => this.confirmDialog.close()),
      tap({
        next: () => {
          this.snackbarService.add({
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            severity: 'success',
          });
        },
      }),
      map(() => void 0)
    ); // Expects a void
  }
}
