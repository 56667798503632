import { AddressModel } from '@gea/digital-ui-lib';
import { SiteModel } from './site.model';
import { UpdateCreateBusinessRelationRequest } from '@gea/digital-ui-lib';
import { OrganizationType } from '../../generated/api/v1';

export interface LastVerificationRequester {
  email: string;
  firstname: string;
  lastname: string;
  userId: string; // UUID
}

export enum NumberedVerificationStatusEnum {
  PENDING,
  VERIFIED,
  NOT_REQUESTED,
}

export type NumberedVerificationStatus = keyof typeof NumberedVerificationStatusEnum;
export enum VerificationStatusEnum {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  NOT_REQUESTED = 'NOT_REQUESTED',
}
type VerificationStatus = keyof typeof VerificationStatusEnum;

export interface OrganizationDetailResponse {
  id: string;
  name: string;
  isBillingAddressSame: boolean;
  billingAddressSame?: boolean;
  shippingAddress: AddressModel;
  billingAddress?: AddressModel;
  type: OrganizationType;
  sites: SiteModel[];
  businessRelations?: UpdateCreateBusinessRelationRequest[];
  customerNumber: string;
  verificationStatus?: VerificationStatus;
  lastVerificationRequest?: Date; // "format": "date-time"
  lastVerificationRequester?: LastVerificationRequester;
}

export interface OrganizationDetail {
  type: OrganizationType;
  name: string;
  isBillingAddressSame: boolean;
  shippingAddress: AddressModel;
  billingAddress?: AddressModel;
  sites?: SiteModel[];
  businessRelations?: UpdateCreateBusinessRelationRequest[];
  customerNumber?: string;
  verificationStatus?: string;
  lastVerificationRequest?: Date; // "format": "date-time"
}

export interface OrganizationFormData {
  orgaName: string;
  orgaType: OrganizationType;
  orgaZipShipping: string;
  orgaCityShipping: string;
  orgaStreetShipping: string;
  orgaCountryShipping: { name: string; value: string };
  orgaZipBilling: string;
  orgaCityBilling: string;
  orgaStreetBilling: string;
  orgaCountryBilling: { name: string; value: string };
  orgaAddressSame: boolean;
}
