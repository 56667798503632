import { TooltipRendererConfig } from '../../model/tooltip-renderer-config.model';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'gea-id-tooltip-renderer',
  templateUrl: './tooltip-renderer.component.html',
})
export class TooltipRendererComponent<T> implements ColumnRendererComponent<T>, OnInit {
  rowData?: T;
  columnKey?: string;
  config?: TooltipRendererConfig<T>;
  hoverHtmlGenerator?: (rowData: T) => Observable<string>;
  public hoverHTML = '';

  ngOnInit() {
    this.hoverHtmlGenerator = this.config?.hoverHtmlGenerator ?? (() => of(''));
  }

  public generateHoverHTML(rowData?: T) {
    // INFO:
    // PrimeNG has a bug where Template References for Tooltips are not parsed
    // But PrimeNG is able to interprete html as string, so the hacky solution
    // is to write and pass html manually.
    // To fetch data on demand, we fetch and update when the mouse is over a row.

    if (this.hoverHtmlGenerator && rowData && !this.hoverHTML) {
      this.hoverHTML = 'loading...';
      this.hoverHtmlGenerator(rowData).subscribe((html) => {
        this.hoverHTML = html;
      });
    }
  }
}
