import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@gea/digital-ui-lib';
import { MsalBroadcastService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import {
  DOCUMENT_SERVICE_BASE_URL,
  SharepointSite,
  SharepointSiteDto,
  SharepointSites,
  SharepointSitesDto,
  SharepointSiteType,
  SharepointWrapperDto,
} from './sharepoint-site.model';

@Injectable({
  providedIn: 'root',
})
export class SharepointSiteApiService extends ApiService {
  private contextUrl = '/sharepoint-sites';

  constructor(
    @Inject(DOCUMENT_SERVICE_BASE_URL) protected override baseUrl: string,
    protected override msalBroadcast: MsalBroadcastService,
    protected override http: HttpClient,
    protected override store: Store
  ) {
    super(msalBroadcast, http, store, baseUrl);
  }

  /**
   * Gets all Sites visible to the user, grouped by Organization
   */
  public getSites(): Observable<SharepointSites> {
    return this.get<SharepointWrapperDto<SharepointSitesDto>>(this.contextUrl).pipe(
      map((dto) =>
        dto.sites.reduce((sites, site) => {
          sites[site.organizationId] = this.mapDtoToSites(site.sites);
          return sites;
        }, {} as SharepointSites)
      )
    );
  }

  /**
   * Gets all Sites for a specific Organization
   *
   * @param organisationId
   */
  public getSite(organisationId: string): Observable<SharepointSite> {
    return this.get<SharepointWrapperDto<SharepointSiteDto>>(`${this.contextUrl}/${organisationId}`, {}).pipe(
      map((dto) => this.mapDtoToSites(dto.sites))
    );
  }

  /**
   * Sets the Sites for a specific Organization
   *
   * @param organisationId
   * @param customerPortalIds
   * @param projectSitesIds
   */
  public setSites(
    organisationId: string,
    customerPortalIds: string[],
    projectSitesIds: string[],
    mdgCustomerNumber?: string
  ): Observable<string> {
    const dto: SharepointWrapperDto<SharepointSiteDto> = {
      mdgCustomerNumber,
      sites: [
        ...customerPortalIds.map((id) => ({ siteId: id, siteType: 'customer' as SharepointSiteType })),
        ...projectSitesIds.map((id) => ({ siteId: id, siteType: 'project' as SharepointSiteType })),
      ],
    };
    return this.post<{
      message: string;
    }>(`${this.contextUrl}/${organisationId}`, dto).pipe(map((dto) => dto.message));
  }

  private mapDtoToSites(sites: SharepointSiteDto[]): SharepointSite {
    const projectSitesId = sites.filter((site) => site.siteType === 'project').map((site) => site.siteId);
    const customerPortalIds = sites.filter((site) => site.siteType === 'customer').map((site) => site.siteId);
    return { projectSitesId, customerPortalIds };
  }
}
