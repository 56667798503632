<geaui-icon-v2
  [file]="'16px_c-info'"
  [pTooltip]="hoverHTML"
  [showDelay]="500"
  [escape]="false"
  (mouseenter)="generateHoverHTML(rowData)"
  color="blue"
  tooltipPosition="right"
  tooltipStyleClass="fit-content-tooltip">
  >
</geaui-icon-v2>
