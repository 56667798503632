import { NgModule } from '@angular/core';

import { AsyncPipe, NgForOf } from '@angular/common';

import { MdgCustomerTableComponent } from './mdg-customer-table.component';
import { TooltipModule } from 'primeng/tooltip';
import { TooltipRendererComponent } from './renderer/tooltip-renderer/tooltip-renderer.component';
import { ButtonV2Module, IconModule, PageContentLayoutComponent, PageHeaderModule, TableV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MdgCustomerTableComponent, TooltipRendererComponent],
  imports: [
    NgForOf,
    TableV2Module,
    IconModule,
    TooltipModule,
    PageContentLayoutComponent,
    AsyncPipe,
    ButtonV2Module,
    PageHeaderModule,
    TranslateModule,
  ],
  exports: [MdgCustomerTableComponent],
  providers: [],
})
export class MdgCustomerTableModule {}
