import {
  ColumnDefinition,
  ColumnRendererComponent,
  Membership,
  TranslationRendererComponent,
  DateRendererComponent,
  DateRendererConfig,
} from '@gea/digital-ui-lib';
import { EditRendererComponent } from '@gea-id/shared';
import { MembershipStateRendererComponent } from '@gea-id/shared';
import { MembershipStateRendererConfig } from '@gea-id/shared';

export const MEMBERSHIP_EDIT_ACTION = 'membership-list-edit';

export function membershipsColumnDefinitions(editable: boolean): ColumnDefinition[] {
  const cols = [
    {
      displayName: 'X.LABEL.ORGANISATION',
      key: 'organizationName',
      sortable: false,
      width: 500,
    },
    {
      displayName: 'X.LABEL.ROLE',
      key: 'roleName',
      width: 500,
      sortable: false,
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<Membership>,
        config: {
          prefix: 'X.ROLE.',
        },
      },
    },
    {
      displayName: 'MEMBERSHIPS.LIST.STATE.HEADER',
      key: 'state',
      sortable: false,
      width: 500,
      renderer: {
        component: MembershipStateRendererComponent as ColumnRendererComponent<Membership>,
        config: {
          mapping: {
            PENDING: '16px_c-info',
            ACCEPTED: '16px_check',
            EXPIRED: '16px_warning-sign',
            DECLINED: '16px_declined',
            INHERITED: '16px_pin-4',
          },
          postfixMapping: {
            PENDING: 'MEMBERSHIPS.LIST.STATE.PENDING',
            ACCEPTED: 'MEMBERSHIPS.LIST.STATE.ACCEPTED',
            EXPIRED: 'MEMBERSHIPS.LIST.STATE.EXPIRED',
            DECLINED: 'MEMBERSHIPS.LIST.STATE.DECLINED',
            INHERITED: 'MEMBERSHIPS.LIST.STATE.INHERITED',
          },
        } as MembershipStateRendererConfig,
      },
    },
    createDateTimeColumn('X.LABEL.CREATED_DATE', 'createdAt'),
    createDateTimeColumn('X.LABEL.LAST_MODIFIED_DATE', 'modifiedAt'),
  ];

  if (editable) {
    cols.push({
      displayName: '',
      key: 'edit',
      sortable: false,
      renderer: {
        component: EditRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          icon: '16px_edit',
          action: MEMBERSHIP_EDIT_ACTION,
        },
      },
    });
  }

  return cols;
}

function createDateTimeColumn(displayName: string, key: string): ColumnDefinition {
  return {
    displayName,
    key,
    visible: true,
    sortable: false,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'dd.MM.yyyy HH:mm:ss',
      } as DateRendererConfig,
    },
  };
}
