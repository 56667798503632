import { Component, DestroyRef, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { DividerModule } from 'primeng/divider';
import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import {
  AutocompleteSelectV2Module,
  ButtonV2Module,
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  IconModule,
  InputV2Module,
  LinkV2Module,
  RadioGroupV2Module,
  SelectOption,
  SelectV2Module,
  TextfieldV2Module,
} from '@gea/digital-ui-lib';
import { ApplicationApiService } from '../services/application-api-service';
import { IApplication, NewApplication } from '../models/application.model';
import { Division } from '../models/division-type.model';
import { AppType } from '../models/division-type.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  standalone: true,
  selector: 'gea-id-application-modal',
  templateUrl: './application-modal.component.html',
  styleUrls: ['./application-modal.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    DividerModule,
    NgIf,
    NgClass,
    JsonPipe,
    ButtonV2Module,
    TranslateModule,
    TextfieldV2Module,
    InputV2Module,
    RadioGroupV2Module,
    SelectV2Module,
    LinkV2Module,
    AsyncPipe,
    AutocompleteSelectV2Module,
    IconModule,
    TooltipModule,
  ],
})
export class ApplicationModalComponent implements OnInit, ComplexDialogEmbeddedView {
  protected emptyMessageKey?: string;
  @ViewChild('fileInput') fileInput!: ElementRef;
  protected divisionTypes: SelectOption<string>[] = Object.keys(Division).map((key) => ({ value: key, name: key }));
  protected appTypesOptions: SelectOption<string>[] = Object.keys(AppType).map((key) => ({ value: key, name: key }));
  protected verificationOptions = [
    { value: true, name: 'true' },
    { value: false, name: 'false' },
  ];
  protected helperText = '';
  protected hasTypeAndDivision = false;
  protected form = this.fb.group({
    name: ['', Validators.required],
    division: ['', Validators.required],
    appType: ['', Validators.required],
    url: ['', Validators.required, Validators.pattern('https?://.+')],
    imagePath: ['', Validators.required, Validators.pattern('https?://.+')],
    verificationNeeded: [false, Validators.required],
    hasTranslation: [false, Validators.required],
  });

  constructor(
    protected applicationApiService: ApplicationApiService,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private complexDialogService: ComplexDialogV2Service,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) private application?: IApplication
  ) {}

  ngOnInit(): void {
    if (this.application) {
      this.form.patchValue({
        appType: this.application.appType,
        division: this.application.division,
        name: this.application.name,
        url: this.application.url,
        imagePath: this.application.imagePath,
        verificationNeeded: this.application.verificationNeeded,
        hasTranslation: this.application.hasTranslation,
      });
    }

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.hasTypeAndDivision = (this.form.get('appType')?.valid as boolean) && (this.form.get('division')?.valid as boolean);
      const division = this.form.value.division || '';
      const currentNameValue = this.form.get('name')?.value || '';
      const prefix = division === 'HUB' ? 'CORE' : division;
      this.helperText = `App Key: ${prefix}_${currentNameValue.replace(/[\s_]+/g, '').toUpperCase()}`;
    });
  }

  get isAcceptDisabled() {
    return this.form.invalid;
  }
  onAcceptClick() {
    const division = this.form.value.division || '';
    const prefix = division === 'HUB' ? 'core' : division;
    const request: NewApplication = {
      appType: this.form.value.appType ?? '',
      division: this.form.value.division ?? '',
      name: `${prefix}_${this.form.value.name}`,
      url: this.form.value.url ?? '',
      imagePath: this.form.value.imagePath ?? '',
      displayName: this.form.value.name ?? '',
      appKey: `${prefix}_${this.form.value.name?.replace(/[\s_]+/g, '').toUpperCase()}`,
      verificationNeeded: this.form.value.verificationNeeded ?? true,
      hasTranslation: this.form.value.hasTranslation ?? false,
    };
    this.complexDialogService.emitDataOutputForComponent(request);
  }
}
