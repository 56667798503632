<gea-id-workspace-base-widget [editModeActive]="editModeActive">
  <div class="widget-container">
    <geaui-icon-v2 class="default-icon" file="32px_book" color="blue"></geaui-icon-v2>
    <div class="text-container">
      <span class="heading">
        {{ 'DASHBOARD.DEFAULT.TITLE' | translate }}
      </span>
      <p class="description">{{ 'DASHBOARD.DEFAULT.DESCRIPTION' | translate }}</p>
    </div>
    <geaui-button-v2 [postIcon]="true" (click)="openLearnMore()" type="primary">
      {{ 'DASHBOARD.DEFAULT.OPEN-LEARN-MORE' | translate }}
    </geaui-button-v2>
  </div>
</gea-id-workspace-base-widget>
