import { Component, Input, OnInit } from '@angular/core';
import {
  ColumnDefinition,
  ComplexDialogV2Service,
  PermissionKey,
  PermissionsState,
  TableServiceV2,
  TableV2Functions,
} from '@gea/digital-ui-lib';
import { getOrganizationRelationsColumnConfig } from '../../models/organization-relations-column.config';
import { BusinessRelationsEditModalComponent } from './modals/edit-relations/business-relations-edit-modal.component';
import { map } from 'rxjs/operators';
import { Observable, startWith, tap } from 'rxjs';
import { OrganizationDetailService } from '../services/organization-detail.service';
import { Store } from '@ngxs/store';
import {
  BusinessRelationResponseV1,
  OrganizationsCreateRelationRequestParams,
  OrganizationsUpdateRelationRequestParams,
} from '@gea-id/shared';

export type TableData = BusinessRelationResponseV1 & { displayedOrga: string };

@Component({
  selector: 'gea-id--organization-business-relations',
  templateUrl: './organization-business-relations.component.html',
  styleUrl: './organization-business-relations.component.scss',
})
export class OrganizationBusinessRelationsComponent implements OnInit {
  public readonly TABLE_ID = 'admin-organization-business-relations';
  public totalRecords = 0;
  public data$: Observable<TableData[]> = this.organizationService.relations$.pipe(
    tap((data) => (this.totalRecords = data.entryCount)),
    map(
      (result) =>
        result.pageEntries.map((data) => ({
          ...data,
          label: data.name.toUpperCase(),
        })) ?? []
    ),
    map((data) =>
      data.map((row) => {
        return {
          ...row,
          displayedOrga: row.ownerOrganizationId === this.orgaId ? row.relatedName : row.ownerName,
        } as TableData;
      })
    )
  );
  public _columnDefinitions: ColumnDefinition[] = [];
  public loading = true;
  public hasNoUpdatePermission = true;
  public tableFunctions: TableV2Functions<TableData> = {};

  @Input() private orgaId = '';

  get columnDefinitions(): Observable<ColumnDefinition[]> {
    return this.canEdit.pipe(map((canEdit) => this._columnDefinitions.filter((column) => column.key !== 'edit' || canEdit)));
  }

  constructor(
    private complexDialog: ComplexDialogV2Service,
    private organizationService: OrganizationDetailService,
    private tableService: TableServiceV2,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this._columnDefinitions = getOrganizationRelationsColumnConfig(this.orgaId);

    this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter) => {
      this.organizationService.initRelations(filter, this.orgaId, {
        preTap: () => (this.loading = true),
        postTap: () => (this.loading = false),
      });
      this.initUpdatePermission();
    });

    this.tableFunctions = {
      rowsClickableFunction: (row: TableData) => {
        return row.ownerOrganizationId === this.orgaId;
      },
    };
  }

  get canEdit(): Observable<boolean> {
    return this.organizationService.orgaType$.pipe(map((type) => !!type?.orgaFlags.includes('EDIT_RELATIONS')));
  }

  initUpdatePermission(): void {
    this.store
      .select(PermissionsState.userPermissions)
      .pipe(
        startWith([] as PermissionKey[]),
        map((permissions) => {
          return !permissions.includes(PermissionKey.UPDATE_ORGANIZATION);
        })
      )
      .subscribe((hasNoUpdatePermission) => {
        this.hasNoUpdatePermission = hasNoUpdatePermission;
      });
  }

  protected openAddRelationsModal() {
    this.complexDialog.open(
      {
        title: 'Add Relation',
        yes: 'Add',
        no: 'Cancel',
        data: { orgaId: this.orgaId },
        confirmCallback: (data: unknown) => this.addRelation(data as OrganizationsUpdateRelationRequestParams),
      },
      BusinessRelationsEditModalComponent
    );
  }

  private addRelation(request: OrganizationsCreateRelationRequestParams) {
    this.loading = true;
    this.organizationService.addRelation(request).subscribe(() => (this.loading = false));
  }

  protected openEditRelationsModal(relation: BusinessRelationResponseV1) {
    this.complexDialog.open(
      {
        title: 'Edit Relation',
        yes: 'Edit',
        no: 'Cancel',
        data: { orgaId: this.orgaId, relation: relation },
        confirmCallback: (data: unknown) => {
          this.editRelation(data as OrganizationsUpdateRelationRequestParams);
        },
      },
      BusinessRelationsEditModalComponent
    );
  }

  private editRelation(request: OrganizationsUpdateRelationRequestParams) {
    this.loading = true;
    this.organizationService.editRelation(request).subscribe(() =>
      this.organizationService.initRelations(this.tableService.getFilterTableSettings(this.TABLE_ID).getValue(), this.orgaId, {
        preTap: () => (this.loading = true),
        postTap: () => (this.loading = false),
      })
    );
  }
}
