import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, tap } from 'rxjs';
import { Router, RouterOutlet } from '@angular/router';
import { ApplicationService } from '../services/application.service';
import {
  ButtonV2Module,
  ContentLayoutComponent,
  InputV2Module,
  PageContentLayoutComponent,
  PageHeaderModule,
  SelectV2Module,
  TableV2Module,
  TabMenuV2Module,
} from '@gea/digital-ui-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IApplication } from '../models/application.model';

@Component({
  selector: 'gea-id-workspace-application-detail',
  standalone: true,
  imports: [
    CommonModule,
    ButtonV2Module,
    ContentLayoutComponent,
    TableV2Module,
    TranslateModule,
    PageHeaderModule,
    TabMenuV2Module,
    ContentLayoutComponent,
    PageContentLayoutComponent,
    RouterOutlet,
    ReactiveFormsModule,
    InputV2Module,
    SelectV2Module,
  ],
  templateUrl: './application-detail.component.html',
  styleUrl: './application-detail.component.scss',
})
export class ApplicationDetailComponent implements OnInit {
  @Input({ required: true }) appId = '';
  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private destroyRef: DestroyRef
  ) {}
  appPageTitle = 'APPLICATION.DETAILS.LABEL';
  tabItems: Observable<MenuItem[]> = this.applicationService.tabMenu$.pipe(
    takeUntilDestroyed(this.destroyRef),
    tap((tabs) => {
      tabs.find((tab) => this.router.url.includes(tab.routerLink));
    })
  );
  ngOnInit() {
    this.applicationService.find(this.appId).subscribe((application: IApplication) => {
      this.applicationService.setCurrentApplication(application);
    });
  }

  changeTab($event?: MenuItem) {
    if (!$event) return;
    void this.router.navigate(['applications', this.appId, $event.routerLink]);
  }

  routeAppList() {
    void this.router.navigate(['applications']);
  }
}
